import {BaseResource} from 'src/entities/BaseResource'
import Contact from './Contact'

export default interface Person extends BaseResource {
  firstName: string
  lastName: string
  city: string
  address: string
  address2: string
  zipCode: string
  state: string
  email: string
  phone: string
  title: string
  order: number
  userId: number
  notifyOther: boolean
  notifyOtherEmail: string
  isMain: boolean
  hasAccessForCP: boolean
}

export function getNextOrderNum<P extends Person>(details: P[]) {
  return (
    (details?.length > 0
      ? details.reduce((max, b) => Math.max(max, b.order), details[0].order)
      : 0) + 1
  )
}

export function getFullName(person: Person | Contact) {
  return (
    (person?.firstName ? person?.firstName + ' ' : '') +
    (person?.lastName || '')
  )
}

export function validatePerson(person: Person) {
  return !!(
    person.firstName &&
    person.lastName &&
    validateEmail(person.email) &&
    (!person.notifyOther ||
      (person.notifyOther &&
        !!person.notifyOtherEmail &&
        validateEmail(person.notifyOtherEmail)))
  )
}

export function validateEmail(email: string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
}

export const getPersonValidation = (key: string, data: any) => {
  switch (key) {
    case 'email':
      return (
        (!data && 'Required!!') ||
        (!validateEmail(data) && 'Email is not valid') ||
        undefined
      )
    case 'firstName':
    case 'lastName':
      return !data ? 'Required!!' : undefined
    case 'notifyOtherEmail':
      return data.notifyOther && !data.notifyOtherEmail
        ? 'Required!!'
        : !validateEmail(data.notifyOtherEmail)
        ? 'Email is not valid'
        : undefined
    default:
      return undefined
  }
}
