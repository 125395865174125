import React, {useState, useEffect} from 'react'
import {getTimerFormat} from 'src/utils/formatDate'
import {ReactComponent as StartIcon} from 'src/images/items/Start.svg'
import {ReactComponent as StopIcon} from 'src/images/items/Stop.svg'
import {IconButton, Typography, Button, Tooltip} from '@mui/material'
import BlockIcon from '@mui/icons-material/Block'
import CuiProgressIconButton from 'src/components/custom/CuiProgressIconButton'

interface CuiTimerProps {
  disabled?: boolean
  hideTimer: boolean
  hideTimerText?: string
  initializedValue?: number
  running?: boolean
  startLabel?: string
  stopLabel?: string
  disabledText?: string
  isStopping?: boolean
  onStart?: () => void
  onStop?: () => void
  onTimeClick?: () => void
}

export default function CuiTimer({
  disabled,
  hideTimer,
  hideTimerText = '',
  disabledText = '',
  initializedValue,
  running,
  startLabel = 'Start',
  stopLabel = 'Stop',
  isStopping = false,
  onStart,
  onStop,
  onTimeClick
}: CuiTimerProps) {
  const [timer, setTimer] = useState(initializedValue || 0)

  useEffect(() => {
    if (initializedValue) {
      setTimer(initializedValue)
    }
  }, [initializedValue])

  useEffect(() => {
    let interval: NodeJS.Timeout = {} as NodeJS.Timeout

    if (running) {
      interval = setInterval(() => {
        setTimer(timer => timer + 1)
      }, 1000)
    } else {
      clearInterval(interval)
    }
    return () => {
      clearInterval(interval)
      setTimer(0)
    }
  }, [running])

  const start = () => {
    if (!disabled) onStart?.()
  }

  const stop = () => {
    onStop?.()
  }

  const timeClick = () => {
    onTimeClick?.()
  }

  return (
    <>
      {!hideTimer ? (
        running ? (
          <Tooltip title={stopLabel}>
            <CuiProgressIconButton loading={isStopping} onClick={stop}>
              <StopIcon />
            </CuiProgressIconButton>
          </Tooltip>
        ) : (
          <Tooltip title={disabled ? disabledText : startLabel}>
            <IconButton onClick={start} size="large">
              <StartIcon />
            </IconButton>
          </Tooltip>
        )
      ) : (
        <Tooltip title={hideTimerText}>
          <IconButton size="large">
            <BlockIcon />
          </IconButton>
        </Tooltip>
      )}
      <Button onClick={timeClick}>
        <Typography
          component="p"
          variant="body2"
          color="textSecondary"
          display="inline"
        >
          {getTimerFormat(timer)}
        </Typography>
      </Button>
    </>
  )
}
