import React, {useCallback, useEffect, useState} from 'react'
import {
  Grid,
  Button,
  Tooltip,
  CircularProgress,
  FormControlLabel,
  Switch
} from '@mui/material'
import CuiMainContainer from 'src/components/custom/CuiMainContainer'
import {useAuth} from 'src/context/Auth'
import config from 'src/config'
import Client from 'src/entities/Client'
import Autocomplete from '@mui/material/Autocomplete'
import Project, {BaseProjectResource} from 'src/entities/Project'
import Item, {getStatusText, ItemType} from 'src/entities/Item'
import Box from '@mui/material/Box/Box'
import {DataPoint} from 'src/entities/Scope'
import {ReactComponent as ExportIcon} from 'src/images/files/Export.svg'
import {downloadFile} from 'src/utils/DownloadFile'
import CuiMultiSelect from 'src/components/custom/CuiMultiSelect'
import {ItemDataPoint, SectionItemDataPoint} from 'src/entities/ItemDataPoint'
import {CuiColumn} from 'src/components/custom/table/CuiTable'
import User from 'src/entities/User'
import PropertyReportItemsTable from 'src/components/report/PropertyReportItemsTable'
import {uniqueArray} from 'src/utils/arrayHelpers'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import CuiTextField from 'src/components/custom/CuiTextField'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SelectedDataPointsModal from 'src/components/report/SelectedDataPointsModal'
import {fDate} from 'src/utils/formatDate'

const columns: CuiColumn[] = [
  {id: 'lpid', label: 'Title', minWidth: 100},
  {id: 'status', label: 'Status', minWidth: 100, format: getStatusText},
  {
    id: 'type',
    label: 'Type',
    minWidth: 100,
    format: type => (type?.group === null ? '' : type?.name)
  },
  {
    id: 'abstractor',
    label: 'Abstractor',
    minWidth: 150,
    format: (user: User) => user?.name
  },
  {id: 'scope.name', label: 'Scope', minWidth: 100},
  {
    id: 'tenant',
    label: 'File Name',
    minWidth: 150
  }
]
export enum ReportType {
  PropertyReport,
  PropertyDataPointsReport,
  PropertyRentRollReport
}

interface ReportPageProps {
  reportType: ReportType
}

export default function PropertyReportPage({reportType}: ReportPageProps) {
  const [includeSource, setIncludeSource] = useState(true)
  const [clients, setClients] = useState<Client[]>([])
  const [isClientLoading, setIsClientLoading] = useState(false)
  const [isProjectLoading, setIsProjectLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedClient, setSelectedClient] = useState<Client | null>()
  const [projects, setProjects] = useState<BaseProjectResource[]>([])
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([])
  const [selectedItems, setSelectedItems] = useState<Item[]>([])
  const [itemTypes, setItemTypes] = useState<ItemType[]>([])
  const [sections, setSections] = useState<SectionItemDataPoint[]>([])
  const [selectedDataPoints, setSelectedDatapoints] = useState<ItemDataPoint[]>(
    []
  )
  const [openSelectedDataPointsModal, setOpenSelectedDataPointsModal] =
    useState(false)

  const {fetchWithUser} = useAuth()

  const items = selectedProjects?.reduce(
    (prev, current) => prev.concat(current?.items),
    [] as Item[]
  )

  const selectedScopesCount = uniqueArray(
    selectedItems.map(i => i.scope?.name)
  ).length

  const onSelectProject = (event: any, projects: Project[]) => {
    setSelectedProjects(projects)
    const currentProjectItems =
      projects.find(
        x => x.code === event.currentTarget.innerText.split(' - ')[0]
      )?.items || []
    setSelectedItems(prevSelectedItems => [
      ...prevSelectedItems.filter(
        i => projects.findIndex(x => x.id === i.projectId) !== -1
      ),
      ...currentProjectItems
    ])
  }

  const onSelecteItems = useCallback(
    (selectedItems: Item[] | ((prevSelectedItems: Item[]) => Item[])) => {
      if (Array.isArray(selectedItems)) setSelectedItems(selectedItems)
      else {
        setSelectedItems(prev => selectedItems(prev))
      }
    },
    []
  )

  const onExportToExcel = () => {
    const functin =
      reportType === ReportType.PropertyReport
        ? 'property-report'
        : reportType === ReportType.PropertyDataPointsReport
        ? 'propertyDatapoint'
        : 'propertyRentRoll'
    const fileName =
      reportType === ReportType.PropertyReport
        ? undefined
        : `${
            reportType === ReportType.PropertyDataPointsReport
              ? 'Property Data Points'
              : reportType === ReportType.PropertyRentRollReport
              ? 'Rent'
              : ''
          } Report-${fDate(new Date(), 'MM.dd.yyyy')}.xlsx`
    setIsLoading(true)
    const itemsIds = selectedItems.map(x => x.id)

    const dataPointsIds = sections
      .filter(x => x.itemDataPoints)
      .reduce(
        (list, p) => list.concat(p.itemDataPoints),
        new Array<ItemDataPoint>()
      )
      .filter(
        x =>
          selectedDataPoints.findIndex(
            v =>
              v.dataPoint.id === x.dataPoint.id &&
              v.dataPoint.name === x.dataPoint.name
          ) !== -1
      )
      .map(x => x.dataPoint.id)

    const dataPointsNames = sections
      .filter(x => x.itemDataPoints)
      .reduce(
        (list, p) => list.concat(p.itemDataPoints),
        new Array<ItemDataPoint>()
      )
      .filter(
        x =>
          selectedDataPoints.findIndex(
            v =>
              v.dataPoint.id === x.dataPoint.id &&
              v.dataPoint.name === x.dataPoint.name
          ) !== -1
      )
      .map(x => x.dataPoint.name)
    const options = {
      method: 'POST',
      body: JSON.stringify({
        itemsIds,
        dataPointsIds,
        dataPointsNames,
        includeSource
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    fetchWithUser(`${config.apiUrl}/reports/${functin}`, options)
      .then(response => {
        downloadFile(response, fileName).then(() => {})
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getDataPoints = useCallback(() => {
    const uniqueScopeIds = uniqueArray(
      selectedItems.map(i => {
        return i.scopeId ? i.scopeId : 0
      })
    )
    fetchWithUser(
      `${config.apiUrl}/scopeSections/scopes?scopesIds=${uniqueScopeIds.join(
        '&scopesIds='
      )}&includeSpecial=${reportType === ReportType.PropertyReport}`
    )
      .then(response => response.json())
      .then((data: SectionItemDataPoint[]) => {
        let list = data.map((s: SectionItemDataPoint) => {
          let dpList: ItemDataPoint[] | null = null
          dpList = s.itemDataPoints.map((x: any) => {
            x.sectionName = s.name
            x.name = x.dataPoint.name
            return x
          }, [] as DataPoint[])
          return {
            ...s,
            itemDataPoints: dpList.filter(
              (itemDataPoint, index, arr) =>
                index ===
                arr.findIndex(
                  t => t.dataPoint.name === itemDataPoint.dataPoint.name
                )
            )
          }
        })
        setSections(list)

        let dataPoints: ItemDataPoint[] = list
          .reduce(
            (list, p) => list.concat(p.itemDataPoints),
            new Array<ItemDataPoint>()
          )
          .filter(
            (itemDataPoint, index, arr) =>
              index ===
              arr.findIndex(
                t => t.dataPoint.name === itemDataPoint.dataPoint.name
              )
          )
        setSelectedDatapoints(dataPoints)
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.')
      })
  }, [fetchWithUser, selectedItems, reportType])

  useEffect(() => {
    setIsClientLoading(true)
    fetchWithUser(`${config.apiUrl}/clients`)
      .then((response: any) => response.json())
      .then((data: Client[]) => {
        setClients(data)
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.')
      })
      .finally(() => setIsClientLoading(false))
  }, [fetchWithUser])

  useEffect(() => {
    selectedItems.length > 0 ? getDataPoints() : setSections([])
  }, [selectedItems, getDataPoints])

  useEffect(() => {
    setSelectedProjects([])
    setSelectedItems([])

    if (selectedClient) {
      setIsProjectLoading(true)

      const abortCtrl = new AbortController()
      const opts = {signal: abortCtrl.signal}
      fetchWithUser(
        `${config.apiUrl}/projects/client/${selectedClient?.id}`,
        opts
      )
        .then((response: any) => response.json())
        .then((data: BaseProjectResource[]) => {
          setProjects(data.map(p => ({...p, name: p.name.trim()})))
          setIsProjectLoading(false)
        })
        .catch(e => {
          if (e.name !== 'AbortError') {
            alert('Something went wrong. Please try again later.')
            setIsProjectLoading(false)
          }
        })

      return () => abortCtrl.abort()
    }
  }, [fetchWithUser, selectedClient])

  useEffect(() => {
    fetchWithUser(config.apiUrl + '/itemTypes', {
      method: 'GET'
    })
      .then(response => response.json())
      .then(data => {
        setItemTypes(data)
      })
  }, [fetchWithUser])

  const SelectProjects = () => {
    return (
      <CuiMultiSelect
        items={projects}
        limitTags={1}
        getOptionLabel={project => project.code + ' - ' + project.name}
        selectedValues={selectedProjects}
        label="Select Projects"
        onToggleOption={onSelectProject}
        onClearOptions={() => {
          setSelectedProjects([])
        }}
        isDataLoading={isProjectLoading}
        loadingInStartAdornment={true}
        loadingMessage="Loading data"
      />
    )
  }

  const SelectItems = () => {
    return (
      <PropertyReportItemsTable
        columns={columns}
        items={items.map(x => ({
          ...x,
          type: itemTypes.find(i => i.id === x.typeId) ?? x.type
        }))}
        itemTypes={itemTypes}
        selectedItems={selectedItems.map(i => i.lpid)}
        onSelectedItem={onSelecteItems}
      />
    )
  }

  return (
    <CuiMainContainer>
      {openSelectedDataPointsModal && (
        <SelectedDataPointsModal
          sections={sections}
          selectedDataPoints={selectedDataPoints}
          setSelectedDatapoints={setSelectedDatapoints}
          onCloseModal={() => setOpenSelectedDataPointsModal(false)}
        />
      )}
      <Grid container sx={{mt: 10}} spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            onChange={(event: any, newValue: Client | null) => {
              setSelectedProjects([])
              setProjects([])
              setSelectedClient(newValue?.id ? newValue : null)
            }}
            options={clients.filter(x => x.name)}
            getOptionLabel={client => client.code + ' - ' + client.name}
            includeInputInList
            renderInput={params => (
              <CuiTextField
                {...params}
                label="Select Client"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <React.Fragment>
                      {isClientLoading && (
                        <Box pl={1.5}>
                          <CircularProgress color="primary" size={20} />
                        </Box>
                      )}
                      {params.InputProps.startAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          {selectedClient && <Box>{SelectProjects()}</Box>}
        </Grid>
        <Grid item xs={5}>
          {selectedProjects?.length > 0 &&
            sections.length > 0 &&
            (reportType === ReportType.PropertyReport ||
              reportType === ReportType.PropertyDataPointsReport) && (
              <>
                {' '}
                <Button
                  variant="outlined"
                  onClick={() => setOpenSelectedDataPointsModal(true)}
                  size="large"
                  endIcon={<ArrowDropDownIcon />}
                  color="neutral"
                  sx={{p: '14px 21px'}}
                >
                  select data points
                </Button>
                {reportType === ReportType.PropertyReport && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={includeSource}
                        onChange={event => {
                          setIncludeSource(event.target.checked)
                        }}
                      />
                    }
                    label="Include Source"
                  />
                )}
              </>
            )}
        </Grid>
        <Grid item xs={1}>
          {selectedDataPoints.length > 0 && selectedItems.length > 0 && (
            <Box display="flex" justifyContent="flex-end">
              <Tooltip
                title={
                  reportType === ReportType.PropertyReport &&
                  selectedScopesCount > 1
                    ? 'You can only create property report for items that have the same scope.'
                    : ''
                }
              >
                <span>
                  <CuiProgressButton
                    disableElevation
                    variant="contained"
                    color="primary"
                    startIcon={<ExportIcon />}
                    onClick={() => onExportToExcel()}
                    loading={isLoading}
                    disabled={
                      isLoading ||
                      (selectedScopesCount > 1 &&
                        reportType === ReportType.PropertyReport)
                    }
                  >
                    Generate
                  </CuiProgressButton>
                </span>
              </Tooltip>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box pt={5} />
      {selectedClient && selectedProjects?.length > 0 && SelectItems()}
    </CuiMainContainer>
  )
}
