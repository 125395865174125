import React from 'react'
import Box from '@mui/material/Box'
import {styled} from '@mui/material/styles'
import {Backdrop, Card, CardContent} from '@mui/material'
import LoginButton from 'src/components/login/LoginButton'

const PREFIX = 'LoginPopup'

const classes = {
  card: `${PREFIX}-card`,
  backdrop: `${PREFIX}-backdrop`
}

const StyledBackdrop = styled(Backdrop)(({theme}) => ({
  [`& .${classes.card}`]: {
    position: 'relative',
    overflow: 'auto'
  },

  [`&.${classes.backdrop}`]: {
    position: 'absolute',
    zIndex: theme.zIndex.modal + 1
  }
}))

export default function LoginPopup() {
  return (
    <StyledBackdrop open={true} className={classes.backdrop}>
      <Card variant="outlined" elevation={4} className={classes.card}>
        <CardContent>
          <Box justifyContent="space-around">
            <Box>Your session has expired. Please relogin..</Box>
          </Box>
          <LoginButton />
        </CardContent>
      </Card>
    </StyledBackdrop>
  )
}
