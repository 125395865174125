import React from 'react'
import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  AccordionDetails,
  AccordionDetailsProps,
  accordionSummaryClasses
} from '@mui/material'

export function CuiAccordion({children, sx, ...props}: AccordionProps) {
  return (
    <Accordion
      {...props}
      sx={{
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'divider',
        boxShadow: 'none',
        '&:before': {
          display: 'none'
        },
        '&$expanded': {
          my: 1
        },
        ...sx
      }}
    >
      {children}
    </Accordion>
  )
}

export function CuiAccordionSummary({
  children,
  sx,
  ...props
}: AccordionSummaryProps) {
  return (
    <AccordionSummary
      {...props}
      sx={[
        {
          [`&.${accordionSummaryClasses.root}`]: {
            backgroundColor: theme => theme.palette.grey[50],
            minHeight: 48,
            [`&.${accordionSummaryClasses.expanded}`]: {
              backgroundColor: 'transparent',
              minHeight: 48
            }
          }
        },
        {
          [`& .${accordionSummaryClasses.content}`]: {
            margin: 0,
            [`&.${accordionSummaryClasses.expanded}`]: {
              margin: 0
            }
          }
        },
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])
      ]}
    >
      {children}
    </AccordionSummary>
  )
}

export function CuiAccordionDetails({
  children,
  ...props
}: AccordionDetailsProps) {
  return (
    <AccordionDetails {...props} sx={{p: 2, ...props.sx}}>
      {children}
    </AccordionDetails>
  )
}
