import React, {ReactNode} from 'react'
import {styled} from '@mui/material/styles'
import {DialogTitle} from '@mui/material'

const PREFIX = 'ItemTimerDialogTitle'

const classes = {
  title: `${PREFIX}-title`
}

const StyledDialogTitle = styled(DialogTitle)(({theme}) => ({
  [`&.${classes.title}`]: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.contrastText
  }
}))

interface ItemTimerModalProps {
  children: ReactNode
}

export default function ItemTimerDialogTitle({children}: ItemTimerModalProps) {
  return (
    <StyledDialogTitle className={classes.title}>{children}</StyledDialogTitle>
  )
}
