import React, {ReactNode} from 'react'
import {styled} from '@mui/material/styles'
import {Snackbar, SnackbarCloseReason, SnackbarOrigin} from '@mui/material'
import Alert, {AlertColor, AlertProps} from '@mui/material/Alert'

const PREFIX = 'CuiSnackbarMessage'

const classes = {
  multilineAlert: `${PREFIX}-multilineAlert`,
  info: `${PREFIX}-info`
}

const StyledSnackbar = styled(Snackbar)(({theme}) => ({
  [`&.${classes.multilineAlert}`]: {
    whiteSpace: 'pre-wrap'
  },

  [`& .${classes.info}`]: {
    backgroundColor: theme.palette.primary.main
  }
}))

export interface SnackbarMessageData {
  isOpen: boolean
  message: string
  severity?: AlertColor
  autoHideDuration?: number
}

interface CuiSnackbarMessageProps {
  isOpenSnackbar: boolean
  closeSnackbar: () => void
  message: string | undefined
  severity?: string
  variant?: AlertProps['variant']
  autoHideDuration?: number
  anchorOrigin?: SnackbarOrigin
  action?: ReactNode
  onExited?: () => void
}

export default function CuiSnackbarMessage({
  message,
  isOpenSnackbar,
  closeSnackbar,
  severity = 'info',
  variant = 'filled',
  autoHideDuration,
  anchorOrigin,
  action,
  onExited
}: CuiSnackbarMessageProps) {
  const onClose = (
    event: React.SyntheticEvent<any> | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }
    closeSnackbar()
  }

  const severityValue =
    severity === 'success' ? 'success' : severity === 'error' ? 'error' : 'info'

  return (
    <StyledSnackbar
      anchorOrigin={
        anchorOrigin ? anchorOrigin : {vertical: 'top', horizontal: 'center'}
      }
      open={isOpenSnackbar}
      onClose={onClose}
      autoHideDuration={autoHideDuration ? autoHideDuration : null}
      className={classes.multilineAlert}
      TransitionProps={{onExited: onExited}}
    >
      <Alert
        elevation={6}
        variant={variant}
        onClose={() => closeSnackbar()}
        severity={severityValue}
        className={
          severityValue === 'info' && variant === 'filled' ? classes.info : ''
        }
        action={action}
      >
        {message}
      </Alert>
    </StyledSnackbar>
  )
}
