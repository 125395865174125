import {GridFilterItem, GridFilterModel} from '@mui/x-data-grid-pro'
import {useEffect, useCallback, useReducer, useMemo} from 'react'
import {getFilterDate} from 'src/components/custom/CuiFilterDate'
import config from 'src/config'
import {useAuth} from 'src/context/Auth'
import {SaveType} from 'src/context/Autosave'
import useAutosave from 'src/hooks/useAutosave'

export enum FilterType {
  ProjectLP = 1,
  ProjectRD,
  AdminIssueFilter,
  AdminIssueSort,
  ProjectsTable,
  ItemsProjectTable,
  ItemsTable,
  InvoicesTable,
  InvoicePerProject,
  ItemsTableV2,
  ClientsTable
}

export const fixDates = <T>(object: T) => {
  Object.keys(object).forEach((key: string) => {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const value = object[key as keyof T]
      if (
        typeof value === 'string' &&
        !isNaN(new Date(value).getTime()) &&
        new Date(value).toISOString() === value
      )
        object = {...object, [key]: new Date(value)}
      if (key === 'items') {
        object = {
          ...object,
          [key]: (value as any as [GridFilterItem]).map(t => ({
            ...t,
            value: t.columnField.includes('At')
              ? t.value[2]
                ? [...getFilterDate(t.value[2]), t.value[2]]
                : t.value.map((d: string) => (d ? new Date(d) : null))
              : t.value
          }))
        }
      }
    }
  })
  return object
}

const mapSentData = (data: GridFilterModel) => ({
  ...data,
  items: data.items.filter(t => t.operatorValue !== 'quickSearch')
})
export interface FilterData<T> {
  id: number
  filters: T
}

interface State<T> {
  data: FilterData<T>
  ignoreChanges: boolean
}

export enum ActionType {
  Init = 1,
  IdUpdated,
  FilterChange
}

type Action<T> =
  | {type: ActionType.Init; data: FilterData<T>}
  | {type: ActionType.IdUpdated; data: number}
  | {type: ActionType.FilterChange; data: SetFilterArgs<T>}

const createReducer =
  <T>() =>
  (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case ActionType.Init:
        const newData = {
          ...action.data,
          filters: fixDates(action.data.filters)
        }
        return {
          ...state,
          data: newData,
          ignoreChanges: true
        }
      case ActionType.IdUpdated: {
        return {
          ...state,
          data: {...state.data, id: action.data}
        }
      }
      case ActionType.FilterChange:
        return {
          ...state,
          data: {
            ...state.data,
            filters:
              typeof action.data === 'function'
                ? action.data(state.data.filters)
                : action.data
          },
          ignoreChanges: false
        }
      default:
        throw new Error()
    }
  }
type SetFilterArgs<T> = T extends any ? T | ((prev: T) => T) : never

export function useFilters<T>(
  type: FilterType,
  initialFilter: T,
  refresh?: boolean
): [T, (S: SetFilterArgs<T>) => void] {
  const {fetchWithUser} = useAuth()
  const [{data, ignoreChanges}, dispatch] = useReducer(
    useMemo(() => createReducer<T>(), []),
    {
      data: {filters: initialFilter} as FilterData<T>,
      ignoreChanges: true
    }
  )

  const onSuccess = (res: FilterData<T>) => {
    if (!data.id) dispatch({type: ActionType.IdUpdated, data: res.id})
  }

  useAutosave<T>({
    id: data.id,
    url: `${config.apiUrl}/filters`,
    dataToSave: data.filters,
    type: SaveType.Filters,
    mapData: () => ({
      id: data.id,
      type,
      value: JSON.stringify(
        type === FilterType.AdminIssueFilter
          ? mapSentData(data.filters as any)
          : data.filters
      )
    }),
    ignoreChanges,
    delayToSaveInMs: 1,
    onPartialSucceeded: onSuccess,
    onSucceeded: onSuccess
  })

  const setFilters = useCallback((filters: SetFilterArgs<T>) => {
    dispatch({
      type: ActionType.FilterChange,
      data: filters
    })
  }, [])

  const load = useCallback(() => {
    fetchWithUser(`${config.apiUrl}/filters?type=${type}`)
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ActionType.Init,
          data: {
            id: data.id,
            filters: JSON.parse(data.value)
          }
        })
      })
      .catch(() =>
        dispatch({
          type: ActionType.Init,
          data: {filters: initialFilter} as FilterData<T>
        })
      )
  }, [fetchWithUser, type, initialFilter])

  useEffect(() => {
    if (refresh) load()
  }, [refresh, load])

  useEffect(() => {
    load()
  }, [load])

  return [data.filters, setFilters]
}
