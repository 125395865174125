import React from 'react'
import {styled} from '@mui/material/styles'
import {Popover, Typography, IconButton, Badge, Box} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import FolderOpen from '@mui/icons-material/FolderOpen'
import {getFormattedDate} from 'src/utils/formatDate'
const PREFIX = 'NotificationPopup'

const classes = {
  popover: `${PREFIX}-popover`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.popover}`]: {
    height: 'calc(100vh - 700px)',
    width: 'calc(100vh - 755px)'
  }
}))

export default function NotificationPopup() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const onNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const onNotificationClose = () => {
    setAnchorEl(null)
  }

  const updates: any[] = []

  return (
    <Root>
      <IconButton color="primary" onClick={onNotificationClick} size="large">
        <Badge badgeContent={updates.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {updates.length > 0 && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={onNotificationClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box className={classes.popover} p={3}>
            <Box
              display="flex"
              justifyContent="center"
              fontWeight={500}
              fontSize={16}
            >
              {' '}
              Updates
            </Box>
            {updates.map((u, index) => (
              <Box my={2} key={index}>
                <Box fontWeight={500} fontSize={14}>
                  {u.description}
                </Box>
                <Box display="flex" mt={1} justifyContent="space-between">
                  <FolderOpen />
                  <Box flexGrow={0.9}>
                    <Typography variant="body2">{u.item}</Typography>
                    <Box fontSize={12}>{u.deatails}</Box>
                    <Box display="flex" justifyContent="space-between" mt={1}>
                      <Box fontSize={12} color="grey.500">
                        {getFormattedDate(u.dateUploaded)}
                      </Box>
                      <Box fontSize={12} color="grey.500">
                        {u.dateUploaded.toLocaleString('en-US', {
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true
                        })}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Popover>
      )}
    </Root>
  )
}
