import Item, {
  ItemStatus,
  ItemType,
  ItemTypeGroup,
  ItemTypeGroupId
} from 'src/entities/Item'
import {ExportTo} from 'src/components/project/ExportProject'
import Client, {BaseClientResource} from 'src/entities/Client'
import Scope from 'src/entities/Scope'
import Person, {validatePerson} from 'src/entities/Person'
import BaseWithNameResource from 'src/entities/BaseResource'
import {ProjectAttachment} from 'src/entities/ProjectAttachment'
import TypeSummary from 'src/entities/TypeSummary'
import User from 'src/entities/User'
import Contact from 'src/entities/Contact'
import {
  EditableProjectItemType,
  getvalidateProjectItemType,
  validateProjectItemType
} from './ProjectItemType'
import {GridRowId} from '@mui/x-data-grid-pro'
import {BillingInfo} from './BillingInfo'

export enum ProjectStatus {
  Pending = 1,
  Signed,
  PMSettingUp,
  ReadyToAssign,
  InProgress,
  InvoiceNeeded,
  InvoiceCreated,
  InvoiceSent,
  Paid,
  PartiallyPaid,
  OnHold,
  Cancelled,
  Reopened,
  'PM Setting Up' = PMSettingUp,
  'Ready To Assign' = ReadyToAssign,
  'In Progress' = InProgress,
  'Invoice Needed' = InvoiceNeeded,
  'Invoice Created' = InvoiceCreated,
  'Invoice Sent' = InvoiceSent,
  'Partially Paid' = PartiallyPaid,
  'On Hold' = OnHold
}

export enum EstimatedCompletion {
  Days = 1,
  Weeks,
  'Week(s)' = Weeks,
  'Day(s)' = Days
}

export enum EstimateToPT {
  DueDate,
  EstimatedCompletion,
  Note,
  NoCompletionDate
}

export default interface Project extends BaseWithNameResource {
  code: string
  state: string
  city: string
  streetAddress: string
  client: Client
  cpStatus: CPStatus
  managers: ProjectManager[]
  scope: Scope
  dueDate: Date | null
  estimatedCompletionSetting: EstimatedCompletion
  estimatedCompletionRange: string
  estimatedCompletionNote: string
  estimateToPT: EstimateToPT
  status: ProjectStatus
  dateInvoiceSentStatus?: Date
  isReadyToStart: boolean
  exportToFile: ExportTo
  isPiecemealDelivery: boolean
  isOngoing: boolean
  documentOrigination: string
  items: Item[]
  attachments: ProjectAttachment[]
  salesReps: Person[]
  contacts: Contact[]
  totalItems: number
  rdTotalItems: number
  itemsType: ItemType
  dept: ItemTypeGroup
  statusSummerize: Record<ItemStatus, number>
  rdStatusSummerize: Record<ItemStatus, number>
  typeSummary: TypeSummary[]
  estimateByType: Record<number, number>
  rdEstimateByType: Record<number, number>
  estimateSum: number
  rdEstimateSum: number
  instruction: string
  projectItemTypes: EditableProjectItemType[]
  billingNote: string
  scopeOfProjectNote: string
  isPropertyReport: boolean
  isAmendment: boolean
  isDeposit: boolean
  depositServiceName: string
  isDiscountPercent: boolean
  ptSignature?: string
  elSignature?: string
  billingInfo: BillingInfo
  isInvoiceCreated: boolean
  mainContactId: number
  lastInvoiceNumber: string
  priorityType: PriorityType
}

export interface ProjectManager {
  id: number
  projectId: number
  managerId: number
  manager: User
  managerType: ManagerType
}

export enum ManagerType {
  Other = 0,
  LPRD,
  DD,
  ClientDirector
}
export enum CPStatus {
  None = 0,
  ForCP,
  Signed
}

export enum PriorityType {
  Firm = 0,
  Stragglers,
  Flexible,
  TBD
}

export interface BaseProjectResource extends BaseWithNameResource {
  code: string
  client: BaseClientResource
  scopeId: number
}

export interface ProjectValidation {
  isValid: boolean
  nameValidation?: string
  stateValidation?: string
  cityValidation?: string
  streetAddressValidation?: string
  clientValidation?: string
  salesRepsValidation?: string
  contactsValidation?: string
  projectItemTypesValidation?: string
  codeValidation?: string
  dueDateValidation?: string
  dateFormulaRangeValidation?: string
  dateFormulaSettingValidation?: string
  estimateValidation?: string
  estimateToPTValidation?: string
  amendmentValidation?: string
  priorityTypeValidation?: string
}

export interface ProjectwithChanges extends Project {
  changesColumns: string[]
}
export interface EditableProject
  extends ProjectwithChanges,
    ProjectValidation {}

export function getTerm(isOngoing: boolean) {
  return isOngoing ? 'Continuous' : 'Regular'
}

export function getDeliveryType(isPiecemealDelivery: boolean) {
  return isPiecemealDelivery ? 'Piecemeal' : 'On deadline'
}

export function getEstimatedCompletion() {
  return Object.keys(EstimatedCompletion)
    .filter(key => !isNaN(Number(key)))
    .map(key => Number(key) as EstimatedCompletion)
}

export function validateProjectCode(code: string) {
  return /^[A-Z]{3}-[A-Z]{2}$/.test(code)
}

export function validateProjectName(
  name: string,
  clientProjects: BaseProjectResource[]
) {
  return !clientProjects?.find(c => c.name === name)
}

export function validateProject(
  project: Project,
  clientProjects: BaseProjectResource[]
) {
  return !!(
    project.name &&
    validateProjectName(project.name, clientProjects) &&
    validateProjectCode(project.code) &&
    project.state?.length &&
    project.city?.length &&
    project.streetAddress?.length &&
    project.client?.id &&
    project.salesReps?.length &&
    project.salesReps.every(sr => validatePerson(sr)) &&
    (project.estimateToPT === EstimateToPT.NoCompletionDate ||
      project.dueDate ||
      (project.estimatedCompletionSetting &&
        project.estimatedCompletionRange) ||
      project.estimatedCompletionNote) &&
    (!project.dueDate || !isNaN(new Date(project.dueDate).getTime())) &&
    project.contacts?.filter(c => (c as any).selected)?.length &&
    project.contacts?.filter(c => (c as any).isMain)?.length &&
    project.contacts.every(c => validatePerson(c)) &&
    (!project.projectItemTypes ||
      project.projectItemTypes?.every(c => validateProjectItemType(c))) &&
    (!project.isAmendment ||
      (project.isAmendment &&
        project.billingInfo.amendmentRate &&
        project.billingInfo.chargeAmendmentsFrom)) &&
    ((project.estimateToPT === EstimateToPT.DueDate && project.dueDate) ||
      (project.estimateToPT === EstimateToPT.EstimatedCompletion &&
        project.estimatedCompletionRange &&
        project.estimatedCompletionSetting) ||
      (project.estimateToPT === EstimateToPT.Note &&
        project.estimatedCompletionNote) ||
      project.estimateToPT === EstimateToPT.NoCompletionDate) &&
    project.priorityType !== undefined
  )
}

const combineCode = (part1: string, part2: string) => `${part1}-${part2}`

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

const generateRandomCode = (existingCodes: string[]) => {
  for (let i = 0; i < alphabet.length; i++) {
    for (let j = 0; j < alphabet.length; j++) {
      const code = alphabet[i] + alphabet[j]
      if (!existingCodes.find(c => c === code)) return code
    }
  }
  return ''
}

export const generateProjectCode = (
  clientCode: string,
  projectName: string,
  existingCodes: string[]
) => {
  let name = projectName.toUpperCase().replace(/[^A-Z|\s]/g, '')
  if (!name) return combineCode(clientCode, generateRandomCode(existingCodes))

  let code = ''
  const words = name.split(' ').filter(n => n)

  if (words.length > 1) {
    code = words[0][0] + words[1][0]
    if (!existingCodes.find(c => c === code)) {
      return combineCode(clientCode, code)
    }
  }

  name = name.replace(/\s/g, '')
  code = name.slice(0, 2)
  if (code.length === 2 && !existingCodes.find(c => c === code))
    return combineCode(clientCode, code)

  const letters = name.replace(/[AEIOU]/g, '')
  for (let i = 0; i < letters.length - 1; i++) {
    const code = letters[0] + letters[1 + i]
    if (!existingCodes.find(c => c === code))
      return combineCode(clientCode, code)
  }

  for (let i = 0; i < alphabet.length; i++) {
    const code = name[0] + alphabet[i]
    if (!existingCodes.find(c => c === code))
      return combineCode(clientCode, code)
  }

  return combineCode(clientCode, generateRandomCode(existingCodes))
}

export const getProjectValidation = (
  project: EditableProject,
  clientProjects: BaseProjectResource[]
) => {
  const projectValidation = {
    isValid: validateProject(project, clientProjects),
    nameValidation: getProjectKeyValidation('name', project, clientProjects),
    cityValidation: getProjectKeyValidation('city', project, clientProjects),
    stateValidation: getProjectKeyValidation('state', project, clientProjects),
    streetAddressValidation: getProjectKeyValidation(
      'streetAddress',
      project,
      clientProjects
    ),
    clientValidation: getProjectKeyValidation('client', project),
    salesRepsValidation: getProjectKeyValidation('salesReps', project),
    contactsValidation: getProjectKeyValidation('contacts', project),
    projectItemTypesValidation: getProjectKeyValidation(
      'projectItemTypes',
      project
    ),
    dueDateValidation: getProjectKeyValidation('dueDate', project),
    estimateValidation: getProjectKeyValidation('estimate', project),
    estimateToPTValidation: getProjectKeyValidation('estimateToPT', project),
    amendmentValidation: getProjectKeyValidation('amendment', project),
    amendmentRateValidation: getProjectKeyValidation('amendmentRate', project),
    amendmentEstimateValidation: getProjectKeyValidation(
      'amendmentEstimate',
      project
    ),
    priorityTypeValidation: getProjectKeyValidation('priorityType', project)
  } as ProjectValidation

  return projectValidation
}

export const getProjectKeyValidation = (
  key: string,
  data: EditableProject,
  clientProjects: BaseProjectResource[] = [],
  showValidation: boolean = true
) => {
  const requiredText = 'Required'
  switch (key) {
    case 'name':
      return !data.name && showValidation
        ? requiredText
        : !validateProjectName(data.name, clientProjects)
        ? 'Already in use'
        : undefined
    case 'state':
      return !data.state && showValidation ? requiredText : undefined
    case 'city':
      return !data.city && showValidation ? requiredText : undefined
    case 'streetAddress':
      return !data.streetAddress && showValidation ? requiredText : undefined
    case 'client':
      return !data.client?.id && showValidation ? requiredText : undefined
    case 'salesReps':
      return !data.salesReps?.length && showValidation
        ? requiredText
        : undefined
    case 'contacts':
      return !data.contacts?.filter((d: any) => d.selected).length &&
        showValidation
        ? requiredText
        : !data.contacts?.filter((d: Contact) => d.isMain).length &&
          showValidation
        ? 'Main Contact must be selected'
        : undefined
    case 'projectItemTypes':
      return data.projectItemTypes?.some(c => !validateProjectItemType(c)) &&
        showValidation
        ? requiredText
        : undefined
    case 'dueDate':
      return data?.dueDate &&
        isNaN(new Date(data.dueDate).getTime()) &&
        showValidation
        ? 'Invalid date format'
        : undefined
    // case 'estimate':
    //   return !data.dueDate &&
    //     !data.estimatedCompletionSetting &&
    //     !data.estimatedCompletionRange &&
    //     !data.estimatedCompletionNote &&
    //     showValidation
    //     ? 'Fill at least one estimated completion field'
    //     : undefined
    case 'estimateToPT':
      return (data.estimateToPT === EstimateToPT.DueDate && !data.dueDate) ||
        (data.estimateToPT === EstimateToPT.EstimatedCompletion &&
          (!data.estimatedCompletionRange ||
            !data.estimatedCompletionSetting)) ||
        (data.estimateToPT === EstimateToPT.Note &&
          !data.estimatedCompletionNote &&
          showValidation)
        ? 'Fill in the selected estimate fields'
        : undefined
    case 'amendment':
      return !(
        !data.isAmendment ||
        (data.isAmendment &&
          data.billingInfo.amendmentRate &&
          data.billingInfo.chargeAmendmentsFrom)
      ) && showValidation
        ? 'Fill amendment rate and charge amendments from'
        : undefined
    case 'priorityType':
      return data.priorityType === undefined
        ? 'Choose Priority Type'
        : undefined
    default:
      return undefined
  }
}
export const getprojectItemTypesValidation = (
  prjectItemTypes: EditableProjectItemType[]
) => {
  return prjectItemTypes?.map(p => getvalidateProjectItemType(p))
}

export const getNewManagers = (
  prevManages: ProjectManager[],
  newManger: User[] | null,
  mangerType: ManagerType
) => {
  let managers = prevManages.filter(x => x.managerType !== mangerType)
  if (newManger) {
    const newManagers: ProjectManager[] = newManger.map(
      m =>
        ({
          managerId: m.id,
          managerType: mangerType
        } as ProjectManager)
    )
    managers = [...managers, ...newManagers]
  }
  return managers
}
export const getLprdManager = (project: Project) =>
  project?.managers.find(x => x.managerType === ManagerType.LPRD)

export const getLprdManagerFromManagers = (managers: ProjectManager[]) =>
  managers.find(x => x.managerType === ManagerType.LPRD)

export const getSummary = (
  visibleRows: Map<GridRowId, {[key: string]: any}>,
  isLPFilter: boolean,
  isRDFilter: boolean
) => {
  let estimate = 0
  let projects = 0
  let unassigned = 0
  let qcComplete = 0
  let inProgress = 0
  visibleRows.forEach(p => {
    if (
      isLPFilter ||
      p.dept?.id === ItemTypeGroupId.LP ||
      (!isRDFilter && p.dept?.id === ItemTypeGroupId.LPRD)
    ) {
      estimate += p.estimateSum + p.totalItems
      unassigned +=
        ((p.statusSummerize as Record<ItemStatus, number>)[
          'Unassigned' as any
        ] || 0) + p.estimateSum
      qcComplete +=
        (p.statusSummerize as Record<ItemStatus, number>)[
          'QCComplete' as any
        ] || 0
      inProgress +=
        (p.statusSummerize as Record<ItemStatus, number>)['InProcess' as any] ||
        0
    }
    if (
      isRDFilter ||
      p.dept?.id === ItemTypeGroupId.RD ||
      (!isLPFilter && p.dept?.id === ItemTypeGroupId.LPRD)
    ) {
      estimate += p.rdEstimateSum + p.rdTotalItems
      unassigned +=
        ((p.rdStatusSummerize as Record<ItemStatus, number>)[
          'Unassigned' as any
        ] || 0) + p.rdEstimateSum
      qcComplete +=
        (p.rdStatusSummerize as Record<ItemStatus, number>)[
          'QCComplete' as any
        ] || 0
      inProgress +=
        (p.rdStatusSummerize as Record<ItemStatus, number>)[
          'InProcess' as any
        ] || 0
    }
    projects++
  })
  return {
    estimate: estimate,
    projects: projects,
    unassign: unassigned,
    QCComplete: qcComplete,
    inProgress: inProgress
  }
}

export const getStatusSummary = (
  visibleRows: Map<GridRowId, {[key: string]: any}>,
  isLPFilter: boolean,
  isRDFilter: boolean,
  status: ItemStatus
) => {
  const statusName =
    status === ItemStatus.Unassigned
      ? 'Unassigned'
      : status === ItemStatus.InProcess
      ? 'InProcess'
      : 'QCComplete'
  let projects: Record<string, number> = {}
  visibleRows.forEach(p =>
    (isLPFilter || p.dept?.id === ItemTypeGroupId.LP) &&
    (p.statusSummerize[statusName] > 0 ||
      (status === ItemStatus.Unassigned && p.estimateSum > 0))
      ? (projects[p.code] =
          (p.statusSummerize[statusName] || 0) +
          (status === ItemStatus.Unassigned ? p.estimateSum : 0))
      : (isRDFilter || p.dept?.id === ItemTypeGroupId.RD) &&
        (p.rdStatusSummerize[statusName] > 0 ||
          (status === ItemStatus.Unassigned && p.rdEstimateSum > 0))
      ? (projects[p.code] =
          (p.rdStatusSummerize[statusName] || 0) +
          (status === ItemStatus.Unassigned ? p.rdEstimateSum : 0))
      : !isLPFilter &&
        !isRDFilter &&
        p.dept?.id === ItemTypeGroupId.LPRD &&
        (p.statusSummerize[statusName] > 0 ||
          p.rdStatusSummerize[statusName] > 0 ||
          (status === ItemStatus.Unassigned &&
            (p.estimateSum > 0 || p.rdEstimateSum > 0)))
      ? (projects[p.code] =
          (p.statusSummerize[statusName] || 0) +
          (p.rdStatusSummerize[statusName] || 0) +
          (status === ItemStatus.Unassigned
            ? p.estimateSum + p.rdEstimateSum
            : 0))
      : {}
  )
  return projects
}

export const getchangesColumns = (changesColumns: string[], fieldId: string) =>
  changesColumns.includes(fieldId)
    ? changesColumns
    : fieldId === 'contacts'
    ? [...changesColumns, fieldId, 'mainContactId']
    : [...changesColumns, fieldId]
