import React, {useEffect, useState} from 'react'
import {styled} from '@mui/material/styles'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Box,
  Tooltip,
  Alert,
  Link,
  Chip
} from '@mui/material'
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import {useAuth} from 'src/context/Auth'
import {grey} from '@mui/material/colors'
import NotificationPopup from 'src/components/navigation/NotificationPopup'
import config from 'src/config'
import MainMenu from 'src/components/navigation/Menu'
import WelcomeModal from 'src/components/login/WelcomeModal'
import Item, {ItemStatus} from 'src/entities/Item'
import {useParams} from 'react-router-dom'
import ItemTimerTracking from 'src/components/item/timeTracking/ItemTimerTracking'
import useActiveTimer from 'src/hooks/itemTracking/useActiveTimer'
import {ItemStatusColors} from '../UI/ItemStatusColors'

const PREFIX = 'HeaderNavbar'

const classes = {
  appBar: `${PREFIX}-appBar`,
  avatar: `${PREFIX}-avatar`,
  helpIcon: `${PREFIX}-helpIcon`
}

const StyledAppBar = styled(AppBar)(({theme}) => ({
  [`&.${classes.appBar}`]: {
    borderBottom: '1px solid ' + grey[300],
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 'inherit'
  },

  [`& .${classes.helpIcon}`]: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5)
  }
}))

interface HeaderNavbarProps {
  title?: string
  itemStatus?: ItemStatus
}

export default function HeaderNavbar({title, itemStatus}: HeaderNavbarProps) {
  const {user, fetchWithUser, logout} = useAuth()
  const {activeTimer, getActiveTimer, updateActiveTimer} = useActiveTimer()
  const {id} = useParams<{id: string}>()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const docTitle = (title ? `${title} - ` : '') + 'Realease'
  const [showWelcomeModal, setShowWelcomeModal] = React.useState(false)
  const [item, setItem] = useState({} as Item)

  const isInViewer = window.location.pathname.startsWith('/item/') && id

  useEffect(() => {
    let hideWelcomeModal = localStorage.getItem('hideWelcomeModal')
    if (!hideWelcomeModal) {
      setShowWelcomeModal(true)
      localStorage.setItem('hideWelcomeModal', '1')
    }
  }, [])

  const onLogoutClick = () => {
    logout()
    setAnchorEl(null)
  }

  const onAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseMenu = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    document.title = docTitle
  }, [docTitle])

  useEffect(() => {
    isInViewer &&
      fetchWithUser(`${config.apiUrl}/items/${id}`)
        .then(response => response.json())
        .then((data: Item) => {
          setItem(data)
          getActiveTimer()
        })
        .catch(() => {})
  }, [fetchWithUser, id, getActiveTimer, isInViewer])

  return (
    <StyledAppBar position="fixed" elevation={0} className={classes.appBar}>
      <Toolbar>
        {showWelcomeModal && <WelcomeModal />}
        <MainMenu />
        <Box flexGrow={1} display={'flex'} alignItems={'center'}>
          <Typography variant="h6">{'ReaLease'}</Typography>
        </Box>
        <div>
          <Box display="flex" alignItems="center">
            {isInViewer && item.lpid && (
              <>
                {item.fof && (
                  <Alert severity="success" sx={{mr: 15}}>
                    This is a Form Lease. Follow the{' '}
                    <Link href={`/item/${item.fof.id}`} target="_blank">
                      FOF
                    </Link>{' '}
                    Abstracting Procedure
                  </Alert>
                )}
                {item.status && (
                  <Chip
                    sx={{
                      color: 'white',
                      backgroundColor:
                        ItemStatusColors[itemStatus || item.status] ?? grey[500]
                    }}
                    label={ItemStatus[itemStatus || item.status]}
                  ></Chip>
                )}
                <ItemTimerTracking
                  itemId={item.id}
                  itemLpid={item.lpid}
                  disabled={
                    (activeTimer && activeTimer.itemId !== item.id) || false
                  }
                  activeTimer={
                    activeTimer?.itemId === item.id ? activeTimer : null
                  }
                  setActiveTimer={updateActiveTimer}
                />
              </>
            )}
            {!config.isProduction && (
              <Box>
                <NotificationPopup />
              </Box>
            )}
            <Tooltip title="Move to LPRD help center">
              <IconButton
                href="https://lprd.madisondocs.com/"
                target="_blank"
                size="large"
              >
                <LiveHelpOutlinedIcon
                  className={classes.helpIcon}
                  color="primary"
                />
              </IconButton>
            </Tooltip>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={onAvatarClick}
              color="inherit"
              size="large"
            >
              <Avatar className={classes.avatar}>
                {user?.givenName?.charAt(0)}
                {user?.surname?.charAt(0)}
              </Avatar>
            </IconButton>
          </Box>
          {open && (
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={open}
              onClose={onCloseMenu}
            >
              <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
            </Menu>
          )}
        </div>
      </Toolbar>
    </StyledAppBar>
  )
}
