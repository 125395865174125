import React, {useRef, useEffect, useState} from 'react'
import {styled} from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
const PREFIX = 'CuiOverflowTooltip'

const classes = {
  ellipsisCell: `${PREFIX}-ellipsisCell`
}

const Root = styled('div')(() => ({
  [`&.${classes.ellipsisCell}`]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

interface CuiOverflowTooltipProps {
  value: string
  title?: string
}

export default function CuiOverflowTooltip({
  value,
  title
}: CuiOverflowTooltipProps) {
  const [hoverStatus, setHover] = useState(false)
  const textElementRef = useRef<HTMLDivElement>(null)

  const compareSize = () => {
    if (textElementRef.current !== null) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      setHover(compare)
    }
  }

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)
  }, [])

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize)
    },
    []
  )

  return title || value ? (
    <Tooltip title={title || value} disableHoverListener={!hoverStatus}>
      <Root ref={textElementRef} className={classes.ellipsisCell}>
        {value}
      </Root>
    </Tooltip>
  ) : (
    <div>{value}</div>
  )
}
