import {
  lime,
  blue,
  pink,
  deepOrange,
  purple,
  teal,
  orange,
  lightGreen,
  indigo,
  green,
  red
} from '@mui/material/colors'
import {ItemStatus} from 'src/entities/Item'

export const ItemStatusColors: Record<number, string> = {
  [ItemStatus.MC]: orange[800],
  [ItemStatus.Proofing]: blue.A100,
  [ItemStatus.InProgress]: pink[400],
  [ItemStatus.Corrections]: deepOrange[500],
  [ItemStatus.ClientReady]: teal[400],
  [ItemStatus.Update]: lightGreen[400],
  [ItemStatus.QCComplete]: purple[400],
  [ItemStatus.ReadyToSend]: lightGreen[400],
  [ItemStatus.SentToClient]: lime[400],
  [ItemStatus.Unassigned]: orange[400],
  [ItemStatus.InProcess]: indigo[400],
  [ItemStatus.Completed]: green[400],
  [ItemStatus.N_A]: red[400]
}
