import {GridFilterOperator, GridFilterItem} from '@mui/x-data-grid-pro'
import BaseWithNameResource from 'src/entities/BaseResource'

export const autocompleteFilter: GridFilterOperator = {
  value: 'isAnyOf',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null
    }
    return ({value}) =>
      (filterItem.value as BaseWithNameResource[]).some(x =>
        value.includes(x.name)
      )
  }
}
