import {useEffect, useState, useCallback} from 'react'

export default function useCrossTabState<T>(
  stateKey: string,
  defaultValue: any
): [T, (value: T) => void] {
  const [state, setState] = useState<T>(defaultValue)

  const setTabState = useCallback(
    (value: T) => {
      setState(value)
      localStorage.setItem(stateKey, JSON.stringify(value))
    },
    [stateKey]
  )

  const onReceieveMessage = useCallback(
    (e: any) => {
      const {key, newValue} = e
      console.log(
        'storage ' +
          new Date().getHours() +
          ':' +
          new Date().getMinutes() +
          ':' +
          new Date().getSeconds()
      )
      if (key === stateKey) {
        setState(JSON.parse(newValue))
      }
    },
    [stateKey]
  )

  useEffect(() => {
    window.addEventListener('storage', onReceieveMessage)
    return () => window.removeEventListener('storage', onReceieveMessage)
  }, [onReceieveMessage])

  return [state, setTabState]
}
