import React, {useEffect, useRef, useState} from 'react'
import config from 'src/config'
import {useAuth} from 'src/context/Auth'

const ReportersContext = React.createContext({})

function ReportersContextProvider({children}: any) {
  const reportersRef = useRef<any>(null)
  const [ready, setReady] = useState(false)
  const {user} = useAuth()

  useEffect(() => {
    if (config.isDevelopment) return // dont run in dev

    import(/* webpackChunkName: 'reporters' */ './../utils/reporters').then(
      x => {
        reportersRef.current = x.default
        setReady(true)
      }
    )
  }, [])

  // update reporters with user data
  useEffect(() => {
    if (!ready || !user || !Object.keys(user).length) return

    const {logrocket, rollbar} = reportersRef.current

    const {givenName, surname, displayName, mail: email} = user
    const name = givenName && surname ? `${givenName} ${surname}` : displayName

    logrocket.identify(user.id, {name, email})
    rollbar.configure({
      payload: {person: {id: user.id, email, username: name}}
    })
  }, [ready, user])

  return (
    <ReportersContext.Provider value={{}}>{children}</ReportersContext.Provider>
  )
}

export {ReportersContextProvider, ReportersContext}
