import BaseWithNameResource from 'src/entities/BaseResource'

export enum SaveStatusEnum {
  NoChanges = 0,
  UnsavedChanges = 1,
  Saving = 2,
  Saved = 3,
  'Unsaved changes' = UnsavedChanges,
  'Saving...' = Saving,
  'Saved!' = Saved
}

export default interface Scope extends BaseWithNameResource {
  isBank: boolean
  baseScopeId: number
  baseScope: BaseWithNameResource
  scopeSections: ScopeSection[]
  hideAddSectionButton: boolean
  dataPointCount: number
  isError: boolean
  hasLinkedItems: boolean
  saveStatus: SaveStatusEnum
}

export interface ScopeWithBase extends BaseWithNameResource {
  baseScope: BaseWithNameResource
}

export interface Section extends BaseWithNameResource {}
export enum DataPointType {
  Text = 1,
  Date = 2,
  Number = 3
}
export enum DataPointSpecialType {
  None = 0,
  RentChart = 1,
  BreakpointChart = 2,
  'Rent Chart' = RentChart,
  'Breakpoint Chart' = BreakpointChart
}
export enum DataPointPropertyType {
  None = 0,
  Sqft = 1,
  ReportHeader = 2,
  LandlordName = 4,
  TenantName = 8,
  NotInReplacement = 16,
  LeaseExpirationDate = 32,
  ExportToRentRoll = 64,

  'Report Header' = ReportHeader,
  'Landlord Name' = LandlordName,
  'Tenant Name' = TenantName,
  'Not in Replacement' = NotInReplacement,
  'Lease Expiration Date' = LeaseExpirationDate,
  'Export to Rent Roll' = ExportToRentRoll
}
export enum DataPointEmptyType {
  NoLeaseProvision = 0,
  Blank = 1,
  Delete = 2,
  Silent = 3,
  N_A = 4,
  'No Lease Provision' = NoLeaseProvision,
  'N/A' = N_A
}

export enum DataPointAdditionalType {
  None = 0,
  Indent = 1,
  EmbedTitle = 2,
  NewLine = 3,
  'Embed Title Within String' = EmbedTitle,
  'New Line (No Title)' = NewLine
}

export interface DataPoint extends BaseWithNameResource {
  instruction: string
  dataPointType: DataPointType
  parentDataPointId?: number
  parentDataPointName?: string
  bankOriginalName: string
  dataPointEmptyType: DataPointEmptyType
  dataPointAdditionalType: DataPointAdditionalType
  specialType: DataPointSpecialType
  propertyType: DataPointPropertyType
  abstractReportName: string
  propertyReportName: string
  colonLine: number
  scopeSectionId: number
  sort: number
  byBankScope: boolean
  tempId: number
  addOrRemoveStatus?: boolean
  isNew?: boolean
  isError?: boolean
  isEqualSearch?: boolean
}

export interface ScopeSection {
  id: number
  scopeId: number
  sectionId: number
  sort: number
  dataPoints: DataPoint[]
  section: Section
  overrideName: string
  name: string
  hideAddDataPointButton: boolean
  byBankScope: boolean
  tempId: number
  addOrRemoveStatus?: boolean
  isNew?: boolean
}

export const getDataPointNameWithColon = (dataPoint: DataPoint) =>
  dataPoint.colonLine === 1 && dataPoint.name.indexOf('\n') > -1
    ? dataPoint.name.replace('\n', ':\n')
    : dataPoint.name + ':'

export const inScope = (a: ScopeSection | DataPoint) =>
  a.addOrRemoveStatus ||
  (!a.byBankScope && a.addOrRemoveStatus !== false && a.id !== undefined)

export const replaceWhitespace = (value: string) =>
  value
    .replace(/[\r\n]/g, ' ')
    .replace(/ +(?= )/g, '')
    .trim()
