import React, {useState} from 'react'
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  CuiAccordion,
  CuiAccordionDetails,
  CuiAccordionSummary
} from 'src/components/custom/CuiAccordion'
import {ItemDataPoint, SectionItemDataPoint} from 'src/entities/ItemDataPoint'
import CuiCancelSaveButton from '../custom/CuiCancelSaveButton'

interface SelectedDataPointsModalProps {
  sections: SectionItemDataPoint[]
  selectedDataPoints: ItemDataPoint[]
  setSelectedDatapoints: (dataPoints: ItemDataPoint[]) => void
  onCloseModal: () => void
}

export default function SelectedDataPointsModal({
  sections,
  selectedDataPoints,
  setSelectedDatapoints,
  onCloseModal
}: SelectedDataPointsModalProps) {
  const [modalDataPoints, setModalDataPoints] =
    useState<ItemDataPoint[]>(selectedDataPoints)
  const onCancelClick = () => {
    onCloseModal()
  }

  const onSaveClick = () => {
    setSelectedDatapoints(modalDataPoints)
    onCloseModal()
  }

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle>Select data points</DialogTitle>
      <DialogContent>
        {sections.map((section: SectionItemDataPoint) => (
          <CuiAccordion key={section.id} square>
            <CuiAccordionSummary
              expandIcon={<ExpandMore />}
              sx={{pl: 2, margin: 0}}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    indeterminate={
                      section.itemDataPoints.some(d =>
                        modalDataPoints.includes(d)
                      ) &&
                      !section.itemDataPoints.every(d =>
                        modalDataPoints.includes(d)
                      )
                    }
                    checked={section.itemDataPoints.every(d =>
                      modalDataPoints.includes(d)
                    )}
                    onChange={e => {
                      if (e.target.checked) {
                        setModalDataPoints(prev => [
                          ...prev,
                          ...section.itemDataPoints.filter(
                            cd => !prev.includes(cd)
                          )
                        ])
                      } else
                        setModalDataPoints(prev =>
                          prev.filter(d => !section.itemDataPoints.includes(d))
                        )
                    }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  />
                }
                label={<Box>{section.name}</Box>}
              />
            </CuiAccordionSummary>
            <CuiAccordionDetails sx={{p: '0 0 0 16px'}}>
              <List>
                {section.itemDataPoints.map((dp: ItemDataPoint) => (
                  <>
                    <ListItem
                      key={dp.dataPoint.id}
                      dense
                      button
                      onClick={() => {
                        if (
                          !modalDataPoints.find(
                            d => d.dataPoint.id === dp.dataPoint.id
                          )
                        ) {
                          setModalDataPoints(prev => [...prev, dp])
                        } else
                          setModalDataPoints(prev =>
                            prev.filter(d => d.dataPoint.id !== dp.dataPoint.id)
                          )
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          color="primary"
                          checked={
                            !!modalDataPoints.find(
                              d => d.dataPoint.id === dp.dataPoint.id
                            )
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={dp.dataPoint.name} />
                    </ListItem>
                  </>
                ))}
              </List>
            </CuiAccordionDetails>
          </CuiAccordion>
        ))}
      </DialogContent>
      <DialogActions>
        <CuiCancelSaveButton
          onCancel={onCancelClick}
          onSave={onSaveClick}
          error={false}
          loading={false}
        />
      </DialogActions>
    </Dialog>
  )
}
