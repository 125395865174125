import React, {useEffect, useState} from 'react'
import {Grid, List, Typography, Tooltip, IconButton, Box} from '@mui/material'
import TimeTracking, {
  isBillable,
  TimeType,
  getTypeOptions,
  getTypeText,
  isItemExists,
  isCurrentActive
} from 'src/entities/TimeTracking'
import {useAuth} from 'src/context/Auth'
import config from 'src/config'
import {TimerModalStatus} from 'src/components/item/timeTracking/ItemTimerTracking'
import {getTimerFormat, getLocalDateFromUtc} from 'src/utils/formatDate'
import ItemTimeModal from 'src/components/item/timeTracking/ItemTimerModal'
import CuiFilter from 'src/components/custom/CuiFilter'
import CuiFilterDate from 'src/components/custom/CuiFilterDate'
import {useLocalStorage} from 'src/hooks/useLocalStorage'
import ItemTimerViewLine from 'src/components/item/timeTracking/ItemTimerViewLine'
import DBUser from 'src/entities/User'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ItemTimerFormModal from 'src/components/item/timeTracking/ItemTimerFormModal'
import {blue} from '@mui/material/colors'

interface ItemTimerStartModalProps {
  open: boolean
  setOpen?: (open: TimerModalStatus) => void
  onClose?: (totalLoggedTimeInHours?: number) => void
  hideTimer: boolean
  itemId?: number
  activeTimerId?: number
  setActiveTimer?: (activeTimer: TimeTracking | null) => void
  itemLpid: string
}

export default function ItemTimerViewModal({
  open,
  setOpen,
  onClose,
  hideTimer,
  itemId,
  activeTimerId,
  setActiveTimer,
  itemLpid
}: ItemTimerStartModalProps) {
  const {fetchWithUser, user} = useAuth()
  const [timeTrackings, setTimeTrackings] = useState<TimeTracking[]>([])
  const [timeTypeFilter, setTimeTypeFilter] = useState<TimeType[]>([])
  const [dateFromFilter, setDateFromFilter] = useState<Date | null>(null)
  const [dateToFilter, setDateToFilter] = useState<Date | null>(null)
  const [selectedValue, setSelectedValue] = useLocalStorage(
    'timeTrackingDateSelectedValue',
    null
  )
  const [openCreate, setOpenCreate] = useState<TimerModalStatus>(
    TimerModalStatus.None
  )

  useEffect(() => {
    open &&
      isItemExists(itemId) &&
      fetchWithUser(
        `${config.apiUrl}/timetracking?` + (itemId && `itemId=${itemId}`),
        {
          method: 'GET'
        }
      )
        .then(response => response.json())
        .then((data: TimeTracking[]) => {
          setTimeTrackings(data)
        })
        .catch(() => {
          alert('Something went wrong. Please try again later.')
        })
  }, [fetchWithUser, itemId, open])

  const onUpdate = (timeTracking: TimeTracking) => {
    setTimeTrackings(prev =>
      prev.map(t => {
        if (t.id === timeTracking.id) {
          if (isCurrentActive(t.id, activeTimerId) && timeTracking.endTime) {
            setActiveTimer?.(null)
          }
          return timeTracking
        }
        return t
      })
    )
  }

  const onDelete = (timeTracking: TimeTracking) => {
    setTimeTrackings(prev =>
      prev.filter((t: TimeTracking) => {
        if (t.id === timeTracking.id && isCurrentActive(t.id, activeTimerId)) {
          setActiveTimer?.(null)
        }
        return t.id !== timeTracking.id
      })
    )
  }

  const onCreate = (timeTracking: TimeTracking) => {
    if (!timeTracking.user)
      timeTracking.user = {
        username: user?.userPrincipalName,
        name: user?.displayName
      } as DBUser
    setTimeTrackings(prev =>
      [...prev, timeTracking].sort(
        (t1, t2) =>
          new Date(t2.startTime).getTime() - new Date(t1.startTime).getTime()
      )
    )
    setOpenCreate(TimerModalStatus.None)
  }

  const isFilteredByType = (timeType: TimeType) =>
    !timeTypeFilter?.length || timeTypeFilter.includes(timeType)

  const isFilteredbyDate = (startTime: Date | null) => {
    if (startTime && (dateFromFilter || dateToFilter)) {
      let time = getLocalDateFromUtc(startTime)
      return (
        !dateFromFilter ||
        (time >= dateFromFilter && (!dateToFilter || time <= dateToFilter))
      )
    }
    return true
  }

  const filterTrackings = timeTrackings.filter(
    t => isFilteredByType(t.timeType) && isFilteredbyDate(t.startTime)
  )

  return (
    <ItemTimeModal
      maxWidth={'md'}
      isBillable={isBillable(itemId)}
      open={open}
      setOpen={setOpen}
      onCloseModal={() => {
        onClose?.(
          filterTrackings.reduce(
            (sum, t) =>
              (sum = t.endTime
                ? sum + (t.duration && t.duration > 0 ? t.duration : 0)
                : sum + 0),
            0
          ) /
            60 /
            60
        )
      }}
      dialogTitle={`Time Logged – ${itemLpid}`}
      dialogActions={
        <Grid
          container
          justifyContent="space-between"
          alignItems={'center'}
          sx={{height: 48}}
        >
          <Grid item>
            {!hideTimer && (
              <Tooltip title={'Create'}>
                <IconButton
                  disabled={!isItemExists(itemId)}
                  sx={{color: 'common.white'}}
                  onClick={() => {
                    setOpenCreate(TimerModalStatus.Create)
                  }}
                  size="large"
                >
                  <AddCircleOutlineIcon htmlColor={blue[800]} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item>
            <Box mr={3} fontWeight={500}>
              {'Total: ' +
                getTimerFormat(
                  filterTrackings.reduce(
                    (sum, t) =>
                      (sum = t.endTime
                        ? sum + (t.duration && t.duration > 0 ? t.duration : 0)
                        : sum + 0),
                    0
                  )
                )}
            </Box>
          </Grid>
        </Grid>
      }
    >
      <>
        {!isBillable(itemId) && (
          <Box sx={{ml: 2}}>
            <CuiFilterDate
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              dateFromFilter={dateFromFilter}
              setDateFromFilter={setDateFromFilter}
              dateToFilter={dateToFilter}
              setDateToFilter={setDateToFilter}
            />

            <CuiFilter
              text="Type"
              options={getTypeOptions(false)}
              selectedOptions={timeTypeFilter || []}
              setSelectedOption={setTimeTypeFilter}
              format={getTypeText}
            />
          </Box>
        )}
        <List component="nav">
          {filterTrackings.map(timeTracking => (
            <ItemTimerViewLine
              key={timeTracking.id}
              timeTracking={timeTracking}
              activeTimerId={activeTimerId}
              user={user}
              hideTimer={hideTimer}
              onUpdate={onUpdate}
              onDelete={onDelete}
              itemLpid={itemLpid}
            />
          ))}
          {filterTrackings.length === 0 && (
            <Typography sx={{ml: 2}}>No Logs</Typography>
          )}
        </List>
        {openCreate === TimerModalStatus.Create && (
          <ItemTimerFormModal
            itemLpid={itemLpid}
            timeTrackingData={
              {
                itemId: itemId
              } as TimeTracking
            }
            open={openCreate}
            setOpen={setOpenCreate}
            onSave={onCreate}
          />
        )}
      </>
    </ItemTimeModal>
  )
}
