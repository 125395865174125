import BaseWithNameResource, {BaseResource} from 'src/entities/BaseResource'
import User, {UserRole} from 'src/entities/User'

export enum TimeType {
  Abstracting = 1,
  Proofing = 2,
  Correcting = 3,
  IncomeInput = 4,
  IncomeOutput = 5,
  IncomeAnalysis = 6,
  ExpenseInput = 7,
  ExpenseOutput = 8,
  ExpenseAnalysis = 9,
  ReimbursementInput = 10,
  ReimbursementOutput = 11,
  ReimbursementAnalysis = 12,
  PMExportingReport = 13,
  PMGenera = 14,
  WorkRelated = 15,
  Admin = 16,
  Break = 17,
  Downtime = 18,
  Personal = 19,
  'Income Input' = IncomeInput,
  'Income Output' = IncomeOutput,
  'Income Analysis' = IncomeAnalysis,
  'Expense Input' = ExpenseInput,
  'Expense Output' = ExpenseOutput,
  'Expense Analysis' = ExpenseAnalysis,
  'Reimbursement Input' = ReimbursementInput,
  'Reimbursement Output' = ReimbursementOutput,
  'Reimbursement Analysis' = ReimbursementAnalysis,
  'Project Manager - Exporting Report' = PMExportingReport,
  'Project Manager - General' = PMGenera,
  'Work-Related' = WorkRelated
}

const getNonBillableValues = () => {
  return [
    TimeType.WorkRelated,
    TimeType.Admin,
    TimeType.Break,
    TimeType.Downtime,
    TimeType.Personal
  ]
}

export const isBillable = (itemId?: number) => {
  return itemId !== null && itemId !== undefined
}

export const isItemExists = (itemId?: number) => {
  return itemId || !isBillable(itemId)
}
export const isCurrentActive = (id: number, activeTimerId?: number) =>
  id === activeTimerId

export const isDisableEditing = (
  activeTimerId: number | undefined,
  timeTracking: TimeTracking,
  userName: string,
  roles: string[],
  disableTimer: boolean
) => {
  return (
    isCurrentActive(timeTracking.id, activeTimerId) ||
    ((timeTracking.user.username.toLocaleLowerCase() !==
      userName?.toLocaleLowerCase() ||
      disableTimer) &&
      !roles?.some(x => x === UserRole[UserRole.Admin]))
  )
}

export const getTypeOptions = (isBillable: boolean) => {
  var x = Object.keys(TimeType).reduce((list, type) => {
    const typeNumber = Number(type)
    if (
      !isNaN(typeNumber) &&
      typeNumber &&
      ((isBillable && !getNonBillableValues().includes(typeNumber)) ||
        (!isBillable && getNonBillableValues().includes(typeNumber)))
    ) {
      list.push(typeNumber as TimeType)
    }
    return list
  }, [] as TimeType[])
  return x
}

export const getTypeText = (timeType: TimeType) => {
  return TimeType[timeType]
}

export interface TimeTrackingValidaion {
  isValid: boolean
  entryDateErrorMessage: string
  startTimeErrorMessage: string
  endTimeErrorMessage: string
}

export const onStartTimeValidation = (
  startTime: Date | null,
  endTime: Date | null
) => {
  if (!startTime || isNaN(startTime.getTime())) {
    return {isValid: false} as TimeTrackingValidaion
  } else if (startTime?.getTime() >= new Date().getTime()) {
    return {
      isValid: false,
      startTimeErrorMessage: 'Start Time entered is in the future!'
    } as TimeTrackingValidaion
  } else if (endTime && startTime.getTime() >= endTime.getTime()) {
    return {
      isValid: false,
      startTimeErrorMessage: 'Start time should be before end time!'
    } as TimeTrackingValidaion
  } else {
    return {isValid: true} as TimeTrackingValidaion
  }
}

export const onEndTimeValidation = (
  startTime: Date | null,
  endTime: Date | null
) => {
  if (!endTime || isNaN(endTime.getTime())) {
    return {isValid: false} as TimeTrackingValidaion
  } else if (endTime?.getTime() >= new Date().getTime()) {
    return {
      isValid: false,
      endTimeErrorMessage: 'End Time entered is in the future!'
    } as TimeTrackingValidaion
  } else if (startTime && startTime.getTime() >= endTime.getTime()) {
    return {
      isValid: false,
      endTimeErrorMessage: 'End time should be after start time!'
    } as TimeTrackingValidaion
  } else {
    return {isValid: true} as TimeTrackingValidaion
  }
}

export const onDateValidation = (
  date: Date,
  startTime: Date | null,
  endTime: Date | null
) => {
  if (date.getTime() >= new Date().getTime()) {
    return {
      isValid: false,
      entryDateErrorMessage: 'Date Entered is in the future!'
    } as TimeTrackingValidaion
  }

  if (startTime && startTime?.getTime() >= new Date().getTime()) {
    return {
      isValid: false,
      startTimeErrorMessage: 'Start Time entered is in the future!'
    } as TimeTrackingValidaion
  }

  if (endTime && endTime?.getTime() >= new Date().getTime()) {
    return {
      isValid: false,
      endTimeErrorMessage: 'End Time entered is in the future!'
    } as TimeTrackingValidaion
  }
  if (endTime && startTime && startTime.getTime() >= endTime.getTime()) {
    return {
      isValid: false,
      startTimeErrorMessage: 'Start time should be before end time!'
    } as TimeTrackingValidaion
  }
  return {isValid: true} as TimeTrackingValidaion
}

export default interface TimeTracking extends BaseResource {
  itemId?: number
  item: BaseWithNameResource
  userId: number
  user: User
  timeType: TimeType
  startTime: Date
  endTime: Date | null
  comment?: string
  duration?: number
}
