import * as React from 'react'
import {createTheme, ThemeProvider, alpha} from '@mui/material/styles'
import {grey} from '@mui/material/colors'

const theme = createTheme({
  palette: {
    neutral: {
      main: grey[300],
      dark: grey[400]
    }
  }
})

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

function Styles({children}: any) {
  return (
    <ThemeProvider
      theme={createTheme(theme, {
        components: {
          MuiButton: {
            variants: [
              {
                props: {variant: 'contained', color: 'neutral'},
                style: {
                  color: theme.palette.getContrastText(theme.palette.grey[300])
                }
              },
              {
                props: {variant: 'outlined', color: 'neutral'},
                style: {
                  color: theme.palette.text.primary,
                  borderColor:
                    theme.palette.mode === 'light'
                      ? 'rgba(0, 0, 0, 0.23)'
                      : 'rgba(255, 255, 255, 0.23)',
                  '&.Mui-disabled': {
                    border: `1px solid ${theme.palette.action.disabledBackground}`
                  },
                  '&:hover': {
                    borderColor:
                      theme.palette.mode === 'light'
                        ? 'rgba(0, 0, 0, 0.23)'
                        : 'rgba(255, 255, 255, 0.23)',
                    backgroundColor: alpha(
                      theme.palette.text.primary,
                      theme.palette.action.hoverOpacity
                    )
                  }
                }
              },
              {
                props: {color: 'neutral', variant: 'text'},
                style: {
                  color: theme.palette.text.primary,
                  '&:hover': {
                    backgroundColor: alpha(
                      theme.palette.text.primary,
                      theme.palette.action.hoverOpacity
                    )
                  }
                }
              }
            ]
          },
          typography: {
            subtitle1: {fontWeight: 500},
            button: {
              textTransform: 'none'
            }
          }
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1600
          }
        }
      })}
    >
      {children}
    </ThemeProvider>
  )
}

export default Styles
