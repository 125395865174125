export function round(value: any, decimals: number = 2) {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals)
}

export function formatNumber(number: number, addPrefix: boolean = true) {
  return (
    (addPrefix ? '$ ' : '') +
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  )
}
