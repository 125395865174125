import React, {useState} from 'react'
import {Autocomplete, Grid, styled, Box} from '@mui/material'
import CuiTextField from 'src/components/custom/CuiTextField'
import CuiNumberFormat from 'src/components/custom/CuiNumberFormat'
import useAutosave from 'src/hooks/useAutosave'
import {SaveType} from 'src/context/Autosave'
import config from 'src/config'
import {SplitInvoiceBy} from 'src/entities/BillingInfo'
import {getEnumOption} from 'src/utils/enumHelper'
import ProjectBilling from 'src/entities/ProjectBilling'
import CuiTypography from '../custom/CuiTypography'

const PREFIX = 'BillingInfoFields'

const classes = {
  box: `${PREFIX}-box`
}

const StyledBox = styled(Box)(({theme}) => ({
  [`&.${classes.box}`]: {
    border: '1px solid ' + theme.palette.grey[300],
    borderRadius: '3px',
    backgroundColor: theme.palette.grey[50]
  }
}))

interface BillingInfoFieldsProps {
  projectBilling: ProjectBilling
  onChangeBillingInfo: (key: string, data: any) => void
  onChangeProjectBilling: (key: string, data: any) => void
}

export const getSplitByText = (spliyBy: SplitInvoiceBy) => {
  return SplitInvoiceBy[spliyBy]
}

export default function BillingInfoFields({
  projectBilling,
  onChangeBillingInfo,
  onChangeProjectBilling
}: BillingInfoFieldsProps) {
  const url = `${config.apiUrl}/projects/billing`

  const [ignoreChanges, setIgnoreChanges] = useState(true)

  const onSaveSucceeded = () => setIgnoreChanges(true)

  const onChange = (field: string, value: any) => {
    setIgnoreChanges(false)
    onChangeBillingInfo(field, value)
  }

  const onChangeNote = (field: string, value: any) => {
    setIgnoreChanges(false)
    onChangeProjectBilling(field, value)
  }

  useAutosave<ProjectBilling>({
    url: url,
    dataToSave: projectBilling,
    type: SaveType.Project,
    id: projectBilling?.id,
    delayToSaveInMs: 5000,
    ignoreChanges: ignoreChanges,
    onSucceeded: onSaveSucceeded
  })

  return (
    <Grid container xs={12} spacing={2} justifyContent="space-around">
      <Grid item xs={2}>
        <CuiNumberFormat
          label="Amendment Rate"
          variant="outlined"
          value={projectBilling?.billingInfo?.amendmentRate || ''}
          addPrefix
          onChange={(e: any) =>
            onChange('amendmentRate', (e.target.value as number) || 0)
          }
        />
      </Grid>
      <Grid item xs={2}>
        <CuiNumberFormat
          label="Count From"
          variant="outlined"
          decimalScale={0}
          value={projectBilling?.billingInfo?.chargeAmendmentsFrom || ''}
          onChange={(e: any) =>
            onChange('chargeAmendmentsFrom', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={2}>
        <CuiNumberFormat
          label="Global Rate"
          variant="outlined"
          value={projectBilling?.billingInfo?.globalRate || ''}
          addPrefix
          onChange={(e: any) =>
            onChange('globalRate', (e.target.value as number) || 0)
          }
        />
      </Grid>
      <Grid item xs={2}>
        <CuiNumberFormat
          label="Discount"
          variant="outlined"
          value={projectBilling?.billingInfo?.discount || ''}
          addPrefix
          onChange={(e: any) =>
            onChange('discount', (e.target.value as number) || 0)
          }
        />
      </Grid>
      <Grid item xs={2}>
        <CuiNumberFormat
          label="Discount Percent"
          variant="outlined"
          value={projectBilling?.billingInfo?.discountPercent || ''}
          addSuffix
          onChange={(e: any) =>
            onChange('discountPercent', (e.target.value as number) || 0)
          }
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          value={
            getEnumOption(SplitInvoiceBy).find(
              x => x === projectBilling?.billingInfo?.splitInvoiceBy
            ) || null
          }
          onChange={(event: any, newValue: any) => {
            if (newValue) {
              onChange('splitInvoiceBy', newValue)
            }
          }}
          options={getEnumOption(SplitInvoiceBy)}
          getOptionLabel={t => getSplitByText(t)}
          isOptionEqualToValue={(option, value) => {
            return option === value
          }}
          includeInputInList
          renderInput={params => (
            <CuiTextField
              {...params}
              label="Split Invoice By"
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <CuiTextField
          label={'Notes'}
          value={projectBilling?.billingNote || ''}
          onChange={event => {
            onChangeNote('billingNote', event.target.value)
          }}
          multiline
          variant="outlined"
          fullWidth
        />
      </Grid>
      {projectBilling?.client?.billingInstruction && (
        <Grid item md={12}>
          <CuiTypography variant="subtitle1">
            Client Billing Note:
          </CuiTypography>
          <StyledBox className={classes.box} padding={3}>
            <CuiTypography
              htmlValue={projectBilling?.client?.billingInstruction}
            />
          </StyledBox>
        </Grid>
      )}
    </Grid>
  )
}
