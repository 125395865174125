import React, {useState} from 'react'
import {Grid} from '@mui/material'
import {Autocomplete} from '@mui/material'
import TimeTracking, {
  isBillable,
  getTypeOptions,
  getTypeText,
  isItemExists
} from 'src/entities/TimeTracking'
import {useAuth} from 'src/context/Auth'
import config from 'src/config'
import {TimerModalStatus} from 'src/components/item/timeTracking/ItemTimerTracking'
import CuiTextField from 'src/components/custom/CuiTextField'
import ItemTimeModal from 'src/components/item/timeTracking/ItemTimerModal'
import CuiLoading from 'src/components/custom/CuiLoading'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'

interface ItemTimerStartModalProps {
  open: boolean
  setOpen: (open: TimerModalStatus) => void
  timeTrackingData: TimeTracking
  setTimeTrackingData: React.Dispatch<React.SetStateAction<TimeTracking>>
  onStart: (timeTracking: TimeTracking) => void
  itemLpid?: string
}

export default function ItemTimerStartModal({
  open,
  setOpen,
  timeTrackingData,
  setTimeTrackingData,
  onStart,
  itemLpid
}: ItemTimerStartModalProps) {
  const {fetchWithUser} = useAuth()
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onStartClick = () => {
    if (!timeTrackingData.timeType) {
      setErrorMessage('Type is required!')
      return
    }
    setErrorMessage('')
    setLoading(true)
    const options = {
      method: 'POST',
      body: JSON.stringify(timeTrackingData),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    fetchWithUser(config.apiUrl + '/timetracking/start', options)
      .then(response => response.json())
      .then((data: TimeTracking) => {
        onStart(data as TimeTracking)
        setLoading(false)
        setOpen(TimerModalStatus.None)
      })
      .catch(e => {
        if (e.status === 409) {
          setErrorMessage(
            "There is open task for you. You can't work on two tasks in parallel. Please check!"
          )
        } else {
          alert('Something went wrong. Please try again later.')
        }
        setLoading(false)
      })
  }

  return (
    <ItemTimeModal
      open={open}
      setOpen={setOpen}
      isBillable={isBillable(timeTrackingData.itemId)}
      dialogTitle={`Start Session ${itemLpid}`}
    >
      {!isItemExists(timeTrackingData.itemId) ? (
        <CuiLoading />
      ) : (
        <>
          <Grid item>
            <Autocomplete
              value={
                getTypeOptions(isBillable(timeTrackingData.itemId)).find(
                  x => x === timeTrackingData.timeType
                ) || null
              }
              onChange={(event: any, newValue: any) => {
                if (newValue) {
                  setTimeTrackingData({
                    ...timeTrackingData,
                    timeType: newValue
                  })
                  setErrorMessage('')
                }
              }}
              fullWidth
              options={getTypeOptions(isBillable(timeTrackingData.itemId))}
              getOptionLabel={t => getTypeText(t)}
              isOptionEqualToValue={option =>
                option === timeTrackingData.timeType
              }
              includeInputInList
              renderInput={params => (
                <CuiTextField
                  {...params}
                  required
                  label="Type"
                  variant="outlined"
                  helperText={errorMessage}
                />
              )}
            />
          </Grid>
          <Grid item>
            <CuiProgressButton
              variant="contained"
              onClick={onStartClick}
              loading={loading}
              color="primary"
              fullWidth
            >
              Start
            </CuiProgressButton>
          </Grid>
        </>
      )}
    </ItemTimeModal>
  )
}
