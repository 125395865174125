export function uniqueArray(arr: any[]) {
  const j = {} as any

  arr.forEach(function (v) {
    j[v + '::' + typeof v] = v
  })

  return Object.keys(j).map(function (v) {
    return j[v]
  })
}
