import React, {ReactNode} from 'react'
import {
  Dialog,
  DialogProps,
  DialogContent,
  Grid,
  DialogActions
} from '@mui/material'

import ItemTimerDialogTitle from 'src/components/item/timeTracking/ItemTimerDialogTitle'
import {TimerModalStatus} from 'src/components/item/timeTracking/ItemTimerTracking'
import {blue} from '@mui/material/colors'
interface ItemTimerModalProps {
  children: ReactNode
  dialogTitle: ReactNode
  dialogActions?: ReactNode
  open: boolean
  setOpen?: (open: TimerModalStatus) => void
  onCloseModal?: () => void
  isBillable: boolean
  maxWidth?: DialogProps['maxWidth']
  disableBackdropClick?: boolean
}

export default function ItemTimeModal({
  children,
  dialogTitle,
  dialogActions,
  open,
  setOpen,
  onCloseModal,
  isBillable,
  maxWidth = 'sm',
  disableBackdropClick = false
}: ItemTimerModalProps) {
  const onClose = (e: any) => {
    if (!disableBackdropClick) {
      setOpen?.(TimerModalStatus.None)
      onCloseModal?.()
    }
    e.stopPropagation()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onBackdropClick={onClose}
      fullWidth
      maxWidth={maxWidth}
    >
      <ItemTimerDialogTitle>
        {isBillable ? dialogTitle : 'Non Billable'}
      </ItemTimerDialogTitle>
      <DialogContent
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <Grid
          container
          direction={'column'}
          spacing={3}
          justifyContent={'flex-start'}
          sx={{
            my: 1,
            minHeight: 250
          }}
        >
          {children}
        </Grid>
      </DialogContent>
      {dialogActions && (
        <DialogActions
          onClick={e => {
            e.stopPropagation()
          }}
          sx={{
            bgcolor: blue[50],
            color: blue[800],
            paddingRight: 1,
            paddingLeft: 1
          }}
        >
          {dialogActions}{' '}
        </DialogActions>
      )}
    </Dialog>
  )
}
