import {useCallback, useState} from 'react'
import useAzureBlobClient from 'src/hooks/useAzureBlobClient'
import retry from 'src/utils/retry'

export interface AzureDownloadResult {
  data?: Blob
  errorCode: string
  isSucceeded: boolean
}

export default function useAzureDownload(
  storageName: string,
  containerName: string
) {
  const {getClient} = useAzureBlobClient(storageName, containerName)
  const [loading, setLoading] = useState(false)

  const downloadFile = useCallback(
    async (file: string) => {
      setLoading(true)

      const client = await getClient()
      if (!client) {
        return {isSucceeded: false} as AzureDownloadResult
      }

      try {
        const data = await retry<AzureDownloadResult>(async () => {
          const blockBlobClient = client.getBlockBlobClient(file)
          const downloadResponse = await blockBlobClient.download()
          if (downloadResponse.errorCode)
            return {
              isSucceeded: false,
              errorCode: downloadResponse.errorCode
            } as AzureDownloadResult

          const blob = await downloadResponse.blobBody
          return {
            isSucceeded: !downloadResponse.errorCode,
            data: blob
          } as AzureDownloadResult
        }, 2)
        setLoading(false)
        return data
      } catch (error: any) {
        console.error(error)
        setLoading(false)
        return {
          isSucceeded: false,
          errorCode: error.statusCode.toString()
        } as AzureDownloadResult
      }
    },
    [getClient]
  )

  return {
    downloadFile,
    loading
  }
}
