import {useCallback, useEffect} from 'react'
import {useAuth} from 'src/context/Auth'
import config from 'src/config'
import useCrossTabState from 'src/hooks/useCrossTabState'
import TimeTrackingActive from 'src/entities/TimeTrackingActive'

export default function useActiveTimer() {
  const {fetchWithUser, user} = useAuth()
  const [activeTimer, setTabState] =
    useCrossTabState<TimeTrackingActive | null>(
      'activeTimer_' + window.location.host,
      null
    )

  const updateActiveTimer = (activeTimer: TimeTrackingActive | null) => {
    setTabState(activeTimer)
  }

  const getActiveTimer = useCallback(() => {
    if (!user) return
    fetchWithUser(config.apiUrl + '/timetracking/active-timer')
      .then(response => response.json())
      .then((newActiveTimer: TimeTrackingActive) => {
        setTabState(newActiveTimer)
      })
      .catch(e => {
        if (e.status === 404) {
          setTabState(null)
        }
      })
  }, [fetchWithUser, user, setTabState])

  const onVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible' && !activeTimer?.stopping) {
      getActiveTimer()
    }
  }, [getActiveTimer, activeTimer])

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [onVisibilityChange])

  return {activeTimer, getActiveTimer, updateActiveTimer}
}
