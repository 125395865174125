import React, {useState, useEffect, useCallback} from 'react'
import {Box} from '@mui/material'
import Item, {
  getSimpleStatuses,
  getStatusText,
  ItemStatus,
  ItemType
} from 'src/entities/Item'
import CuiTable, {CuiColumn} from 'src/components/custom/table/CuiTable'
import CuiFilter from 'src/components/custom/CuiFilter'
import {uniqueArray} from 'src/utils/arrayHelpers'
import {ReactText} from 'react'

interface PropertyReportItemsTable {
  columns: CuiColumn[]
  items: Item[]
  itemTypes: ItemType[]
  selectedItems: string[]
  onSelectedItem: (
    selectedItems: Item[] | ((prevSelectedItems: Item[]) => Item[])
  ) => void
}

const statusOptions = getSimpleStatuses()

export default function PropertyReportItemsTable({
  columns,
  items,
  itemTypes,
  selectedItems,
  onSelectedItem
}: PropertyReportItemsTable) {
  const [typeFilter, setTypeFilter] = useState<ItemType[]>()
  const [statusFilter, setStatusFilter] = useState<ItemStatus[]>()
  const [abstractorsFilter, setAbstractorsFilter] = useState<string[]>()
  const [scopesFilter, setScopesFilter] = useState<string[]>()
  const [tenantFilter, setTenantFilter] = useState<string[]>()

  const onItemClick = useCallback(
    (item: Item) =>
      onSelectedItem(prev =>
        prev.find(i => i.lpid === item.lpid)
          ? prev.filter(i => i.lpid !== item.lpid)
          : [...prev, item]
      ),
    [onSelectedItem]
  )

  const onItemCheck = useCallback(
    (checkedItems: ReactText[]) =>
      onSelectedItem(items.filter(i => checkedItems.includes(i.lpid))),
    [items, onSelectedItem]
  )

  useEffect(() => {
    ;(typeFilter ||
      statusFilter ||
      abstractorsFilter ||
      scopesFilter ||
      tenantFilter) &&
      onSelectedItem([])
  }, [
    typeFilter,
    statusFilter,
    abstractorsFilter,
    scopesFilter,
    tenantFilter,
    onSelectedItem
  ])

  const abstractors = ([] as string[]).concat(
    ...uniqueArray(items.filter(x => x.abstractor).map(i => i.abstractor.name))
  )
  const scopes = ([] as string[]).concat(
    ...uniqueArray(items.map(i => i.scope?.name))
  )

  const tenants = ([] as string[]).concat(
    ...uniqueArray(items.filter(x => x.tenant).map(i => i.tenant))
  )

  const filteredItems = items.filter(
    i =>
      (!typeFilter?.length ||
        (i.type && typeFilter.map(x => x.name).includes(i.type?.name))) &&
      (!statusFilter?.length || statusFilter.includes(i.status)) &&
      (!abstractorsFilter?.length ||
        abstractorsFilter.includes(i.abstractor?.name)) &&
      (!scopesFilter?.length || scopesFilter.includes(i.scope?.name)) &&
      (!tenantFilter?.length || tenantFilter.includes(i.tenant))
  )

  return (
    <>
      <CuiFilter
        text="Type"
        options={itemTypes
          .filter(x => x.group !== null)
          .sort((a, b) => (a.name > b.name ? 1 : -1))}
        selectedOptions={typeFilter || []}
        setSelectedOption={setTypeFilter}
        format={itemType => itemType.name}
      />
      <CuiFilter
        text="Status"
        options={statusOptions}
        selectedOptions={statusFilter || []}
        setSelectedOption={setStatusFilter}
        format={getStatusText}
      />
      <CuiFilter
        text="Abstractor"
        options={abstractors}
        selectedOptions={abstractorsFilter || []}
        setSelectedOption={setAbstractorsFilter}
      />
      <CuiFilter
        text="Scope"
        options={scopes}
        selectedOptions={scopesFilter || []}
        setSelectedOption={setScopesFilter}
      />
      <CuiFilter
        autoComplete
        text="File Name"
        options={tenants}
        selectedOptions={tenantFilter || []}
        setSelectedOption={setTenantFilter}
        format={x => x}
      />
      <Box mt={3} />
      <CuiTable
        columns={columns}
        rows={filteredItems}
        selectedKeys={selectedItems}
        searchColumns={[
          'lpid',
          'status',
          'resolution',
          'abstractor',
          'scope.name',
          'tenant'
        ]}
        searchPlaceHolder={
          'Search by Title, Status, Types, Abstractor, Scope or File Name'
        }
        hasPaging
        leavePositionOnRefresh
        denseUI
        hover
        stickyHeader
        maxHeight="60vh"
        defaultRowsPerPage={50}
        keyColumn={'lpid'}
        onRowCheck={onItemCheck}
        onRowClick={onItemClick}
        clearSelectedRow
        checkboxColumn
      />
    </>
  )
}
