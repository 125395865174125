import {BlobServiceClient} from '@azure/storage-blob'
import {useCallback} from 'react'
import useAzureBlobSAS from 'src/hooks/useAzureBlobSAS'
import {getListInCurrentDir} from 'src/utils/AzureStorage'

export default function useAzureBlobClient(
  storageName: string,
  containerName: string
) {
  const {getSAS} = useAzureBlobSAS(containerName)

  const getClient = useCallback(async () => {
    const sas = await getSAS()
    if (!sas) return
    const service = new BlobServiceClient(`${storageName}?${sas}`)

    return service.getContainerClient(containerName)
  }, [getSAS, storageName, containerName])

  const getBlobsInDir = useCallback(
    async (directorty?: string) => {
      const client = await getClient()
      if (!client) return
      return await getListInCurrentDir(client, directorty)
    },
    [getClient]
  )

  return {
    getClient,
    getBlobsInDir
  }
}
