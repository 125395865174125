import React, {useState} from 'react'
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteValue,
  Checkbox,
  Chip,
  Divider,
  Tooltip
} from '@mui/material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

export interface AutocompleteProps1<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  optionDisabelKey?: string
  disableEmptyValue?: boolean
  emptyValueMessage?: string
  format?: (value: any) => void
  addSelectAll?: boolean
}

export default function CuiSelectAutoComplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: AutocompleteProps1<T, Multiple, DisableClearable, FreeSolo>) {
  const {
    renderInput,
    optionDisabelKey,
    disableEmptyValue,
    emptyValueMessage,
    onChange,
    format,
    options,
    addSelectAll,
    ...prop
  } = props
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const [loading, setLoading] = useState(false)

  const onChangeData = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => {
    if (
      disableEmptyValue &&
      Array.isArray(value) &&
      (value as any[]).length === 0
    ) {
      setLoading(true)
      event.preventDefault()
      return
    }
    if ((details?.option as any)?.name === 'Select all')
      if ((prop.value as any[]).length === options.length)
        onChange?.(event, [] as any, reason, details)
      else onChange?.(event, options as any, reason, details)
    else onChange?.(event, value, reason, details)
  }

  const allOptions = addSelectAll
    ? [{name: 'Select all', id: -1}, ...options]
    : (options as any[])
  return (
    <Autocomplete
      options={allOptions}
      fullWidth
      isOptionEqualToValue={(option, v) =>
        (option as any).id ? (option as any).id === (v as any).id : option === v
      }
      getOptionLabel={option => (option as any)?.name || ''}
      renderOption={(props, option, {selected}) => (
        <>
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{marginRight: 8}}
              checked={
                (option as any)?.name === 'Select all' &&
                options.length === (prop.value as any).length
                  ? true
                  : selected
              }
              indeterminate={
                (option as any)?.name === 'Select all' &&
                (prop.value as any).length > 0 &&
                (prop.value as any).length < options.length
              }
              disabled={optionDisabelKey && (option as any)[optionDisabelKey]}
            />
            {format && (option as any)?.name !== 'Select all'
              ? format(option as any)
              : (option as any)?.name}
          </li>
          {(option as any)?.name === 'Select all' && (
            <Divider variant="middle" />
          )}
        </>
      )}
      renderTags={(tagValue: T[], getTagProps: AutocompleteRenderGetTagProps) =>
        tagValue.map((option, index) => {
          const {key, ...pros} = {...getTagProps({index})}
          return (
            <Tooltip
              placement="right-start"
              title={emptyValueMessage || ''}
              key={key}
              open={loading}
              leaveDelay={1000}
              onClose={() => {
                setLoading(false)
              }}
            >
              <Chip
                label={(option as any)?.name}
                key={key}
                {...pros}
                disabled={
                  props.disabled ||
                  (optionDisabelKey && (option as any)[optionDisabelKey])
                }
              />
            </Tooltip>
          )
        })
      }
      getOptionDisabled={option =>
        props.disabled ||
        (optionDisabelKey && (option as any)[optionDisabelKey])
      }
      renderInput={renderInput}
      onChange={onChangeData}
      {...prop}
    />
  )
}
