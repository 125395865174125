import React, {ReactNode, ElementType} from 'react'
import {styled} from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import {Box, Typography} from '@mui/material'
import classNames from 'classnames'

const PREFIX = 'CuiMainContainer'

const classes = {
  root: `${PREFIX}-root`,
  large: `${PREFIX}-large`,
  child: `${PREFIX}-child`,
  fixedHeader: `${PREFIX}-fixedHeader`
}

const StyledGrid = styled(Grid)(({theme}) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(8)
  },

  [`& .${classes.large}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },

  [`& .${classes.child}`]: {
    marginTop: theme.spacing(5)
  },

  [`& .${classes.fixedHeader}`]: {
    position: 'fixed'
  }
}))

export interface CuiMainContainerProps extends React.PropsWithChildren<{}> {
  isChild?: boolean
  large?: boolean
  title?: string
  subtitle?: string | ElementType
  subHeading?: string
  isFixed?: boolean
  titleAction?: ReactNode
}

function Title({
  mainTitle,
  Subtitle,
  subHeading,
  isFixed,
  titleAction
}: {
  mainTitle?: string
  Subtitle?: string | ElementType
  subHeading?: string
  isFixed?: boolean
  titleAction?: ReactNode
}) {
  return (
    <Box mb={4} mt={4} className={classNames(isFixed && classes.fixedHeader)}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">
          {mainTitle}
          {Subtitle && (
            <Typography display="inline" variant="body1">
              {typeof Subtitle === 'string' ? Subtitle : <Subtitle />}
            </Typography>
          )}
        </Typography>
        <Box>{titleAction}</Box>
      </Box>
      {subHeading && (
        <Typography display="inline" variant="body1">
          {subHeading}
        </Typography>
      )}
    </Box>
  )
}

export default function CuiMainContainer(props: CuiMainContainerProps) {
  return (
    <StyledGrid
      container
      justifyContent="space-around"
      className={props.isChild ? classes.child : classes.root}
    >
      <Grid
        item
        xs={props.large ? 12 : 10}
        className={props.large ? classes.large : undefined}
      >
        {props.title && (
          <Title
            mainTitle={props.title}
            Subtitle={props.subtitle}
            subHeading={props.subHeading}
            isFixed={props.isFixed}
            titleAction={props.titleAction}
          />
        )}
        <Box mt={props.isFixed ? 12 : 0}>{props.children}</Box>
      </Grid>
    </StyledGrid>
  )
}
