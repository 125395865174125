import React from 'react'
import CuiMainContainer from 'src/components/custom/CuiMainContainer'
import InvoiceTable from './InvoiceTable'

interface InvoicePageProps {
  projectId?: number
}

export default function InvoicePage({projectId}: InvoicePageProps) {
  return (
    <CuiMainContainer title={'Invoices'}>
      <InvoiceTable projectId={projectId} />
    </CuiMainContainer>
  )
}
