import React, {Suspense, useState, useCallback} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import 'typeface-roboto'
import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {ReportersContextProvider} from 'src/context/reporters'
import {AuthContextProvider} from 'src/context/Auth'
import ProtectedRoute from 'src/components/navigation/ProtectedRoute'
import Styles from 'src/context/Styles'
import CuiLoading from 'src/components/custom/CuiLoading'
import Menu from 'src/components/navigation/Menu'
import Login from 'src/components/login/LoginPage'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
import Logout from 'src/components/login/Logout'
import {isChrome} from 'react-device-detect'
import {LicenseInfo} from '@mui/x-data-grid-pro'
import {AutosaveContextProvider} from 'src/context/Autosave'
import config from 'src/config'
import InvoicePage from './components/billing/invoice/InvoicePage'
import TimeTrackingReportPage from './components/report/TimeTrackingReportPage'
import {ItemStatus} from './entities/Item'
import {ReportType} from 'src/components/report/PropertyReportPage'

const MyTaskPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MyTaskPage" */ 'src/components/project/MyTaskPage'
    )
)

const ClientsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ClientsPage" */ 'src/components/client/ClientsPage'
    )
)

const ProjectPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectPage" */ 'src/components/project/ProjectPage'
    )
)
const ScopesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ScopesPage" */ 'src/components/scope/ScopesPage'
    )
)
const ItemPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ItemPage" */ 'src/components/item/workspace/ItemPage'
    )
)

const ItemsPage = React.lazy(
  () =>
    import(/* webpackChunkName: "ItemPage" */ 'src/components/item/ItemsPage')
)

const ItemProjectPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ItemPage" */ 'src/components/item/ItemProjectPage '
    )
)
const OcrTrackerPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OcrTrackerPage" */ 'src/components/file/OcrTrackerPage'
    )
)
const ScopePage = React.lazy(
  () =>
    import(/* webpackChunkName: "ScopePage" */ 'src/components/scope/ScopePage')
)
const ProjectsTablePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectsTablePage" */ 'src/components/project/ProjectsTable'
    )
)
const PropertyReportPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PropertyReportPage" */ 'src/components/report/PropertyReportPage'
    )
)
const FilesPage = React.lazy(
  () =>
    import(/* webpackChunkName: "FilesPage" */ 'src/components/file/FilesPage')
)

const ChangeLogPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangeLogPage" */ 'src/components/changeLog/ChangeLogPage'
    )
)

const WhatsNewPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WhatsNewPage" */ 'src/components/changeLog/WhatsNewPage'
    )
)

const DDReportPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DDReportPage" */ 'src/components/report/DDReportPage'
    )
)
const ReportsMenuPage = React.lazy(
  () => import('src/components/report/ReportsMenuPage')
)

const CommissionsReportPage = React.lazy(
  () => import('src/components/report/CommissionsReportPage')
)

const ProjectsAverageReportPage = React.lazy(
  () => import('src/components/report/ProjectsAverageReportPage')
)

const EBRReportPage = React.lazy(
  () => import('src/components/report/EBRReportPage')
)
const RevenueReportPage = React.lazy(
  () => import('src/components/report/RevenueReportPage')
)
const SalesProjectionsReportPage = React.lazy(
  () => import('src/components/report/SalesProjectionsReportPage')
)
const AdminIssuePage = React.lazy(
  () => import('src/components/adminIssue/AdminIssuePage')
)

const BillingPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BillingPage" */ 'src/components/billing/BillingPage'
    )
)
const MigrationPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MigrationPage" */ 'src/components/report/MigrationPage'
    )
)

LicenseInfo.setLicenseKey(config.MUIDataGridLicenseKey)

export default function App() {
  const [title, setTitle] = useState<string>()
  const [currentItemStatus, setCurrentItemStatus] = useState<ItemStatus>()

  const onChangeHeader = useCallback((title: string) => {
    setTitle(title)
  }, [])

  const onChangeItemStatus = useCallback((item: ItemStatus) => {
    setCurrentItemStatus(item)
  }, [])

  if (!isChrome) {
    alert('ReaLease is only supported on Google Chrome.')
    throw Error('ReaLease is only supported on Google Chrome.')
  }

  return (
    <div className="App">
      <Styles>
        <AuthContextProvider>
          <ReportersContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <BrowserRouter>
                <CuiErrorBoundary>
                  <Suspense fallback={<CuiLoading />}>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <ProtectedRoute title="">
                            <Menu />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route path="/login" element={<Login />}></Route>
                      <Route path="/logout" element={<Logout />}></Route>
                      <Route
                        path="/mytasks"
                        element={
                          <ProtectedRoute title="My Tasks">
                            <MyTaskPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/projectsTable"
                        element={
                          <ProtectedRoute title="Projects List">
                            <AutosaveContextProvider>
                              <ProjectsTablePage />
                            </AutosaveContextProvider>
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/projects/:id"
                        element={
                          <ProtectedRoute title="Project">
                            <ProjectPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/adminIssue"
                        element={
                          <ProtectedRoute title="Admin Issues">
                            <AutosaveContextProvider>
                              <AdminIssuePage />
                            </AutosaveContextProvider>
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/items"
                        element={
                          <ProtectedRoute title="All Tasks">
                            <AutosaveContextProvider>
                              <ItemsPage />
                            </AutosaveContextProvider>
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/items/V2"
                        element={
                          <ProtectedRoute title="All Tasks V2">
                            <AutosaveContextProvider>
                              <ItemsPage v2={true} />
                            </AutosaveContextProvider>
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/item/:id"
                        element={
                          <ProtectedRoute
                            title={title || 'Item'}
                            itemStatus={currentItemStatus}
                          >
                            <AutosaveContextProvider>
                              <ItemPage
                                onChangeHeader={onChangeHeader}
                                onChangeItemStatus={onChangeItemStatus}
                              />
                            </AutosaveContextProvider>
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/items/:projectId"
                        element={
                          <ProtectedRoute title={title || 'Tasks List'}>
                            <AutosaveContextProvider>
                              <ItemProjectPage
                                onChangeHeader={onChangeHeader}
                              />
                            </AutosaveContextProvider>
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/ocrtracker"
                        element={
                          <ProtectedRoute title="OCR Tracker">
                            <OcrTrackerPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route path="/documents">
                        <Route
                          index
                          element={
                            <ProtectedRoute title="Documents">
                              <FilesPage />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path=":path"
                          element={
                            <ProtectedRoute title="Documents">
                              <FilesPage />
                            </ProtectedRoute>
                          }
                        />
                      </Route>

                      <Route
                        path="/ddReport"
                        element={
                          <ProtectedRoute title="DD Report">
                            <DDReportPage />
                          </ProtectedRoute>
                        }
                      ></Route>

                      <Route
                        path="/propertyreport"
                        element={
                          <ProtectedRoute title="Property Report">
                            <PropertyReportPage
                              reportType={ReportType.PropertyReport}
                            />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path={'/scopes/:id/*'}
                        element={
                          <ProtectedRoute title="Scope">
                            <ScopePage />
                          </ProtectedRoute>
                        }
                      ></Route>

                      <Route
                        path="/scopes"
                        element={
                          <ProtectedRoute title="Scope List">
                            <ScopesPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/reports"
                        element={
                          <ProtectedRoute title="Reports">
                            <ReportsMenuPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/reports/commissions"
                        element={
                          <ProtectedRoute title="Commissions Report">
                            <CommissionsReportPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/reports/timeTracking"
                        element={
                          <ProtectedRoute title="Time Tracking Report">
                            <TimeTrackingReportPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/reports/SalesProjectionsReport"
                        element={
                          <ProtectedRoute title="Sales Projections Report">
                            <SalesProjectionsReportPage />
                          </ProtectedRoute>
                        }
                      ></Route>

                      <Route
                        path="/reports/projectsaverage"
                        element={
                          <ProtectedRoute title="Projects Average Report">
                            <ProjectsAverageReportPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/reports/revenue"
                        element={
                          <ProtectedRoute title="Revenue Report">
                            <RevenueReportPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/reports/ebr"
                        element={
                          <ProtectedRoute title="EBR Report">
                            <EBRReportPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/reports/PropertyRentRollReport"
                        element={
                          <ProtectedRoute title="Property Rent Roll Report">
                            <PropertyReportPage
                              reportType={ReportType.PropertyRentRollReport}
                            />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/reports/propertyDataPoints"
                        element={
                          <ProtectedRoute title="Property Datapoints Report">
                            <PropertyReportPage
                              reportType={ReportType.PropertyDataPointsReport}
                            />
                          </ProtectedRoute>
                        }
                      ></Route>

                      <Route
                        path="/billing/:projectId"
                        element={
                          <ProtectedRoute title="Billing">
                            <BillingPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/invoices"
                        element={
                          <ProtectedRoute title="Invoices">
                            <AutosaveContextProvider>
                              <InvoicePage />
                            </AutosaveContextProvider>
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/clients"
                        element={
                          <ProtectedRoute title="Clients">
                            <ClientsPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/whats-new"
                        element={
                          <ProtectedRoute title="What's New">
                            <WhatsNewPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/change-log"
                        element={
                          <ProtectedRoute>
                            <AutosaveContextProvider>
                              <ChangeLogPage />
                            </AutosaveContextProvider>
                          </ProtectedRoute>
                        }
                      ></Route>
                      <Route
                        path="/migration"
                        element={
                          <ProtectedRoute title="Migration">
                            <MigrationPage />
                          </ProtectedRoute>
                        }
                      ></Route>
                    </Routes>
                  </Suspense>
                </CuiErrorBoundary>
              </BrowserRouter>
            </LocalizationProvider>
          </ReportersContextProvider>
        </AuthContextProvider>
      </Styles>
    </div>
  )
}
