import {
  getGridDateOperators,
  GridFilterItem,
  GridFilterOperator,
  GridCellParams
} from '@mui/x-data-grid-pro'
import {getLocalDateFromUtc} from 'src/utils/formatDate'

const getDateParamValue = (params: any) =>
  new Date(params as Date).setHours(0, 0, 0, 0)
const getOnlyDate = () => new Date().setHours(0, 0, 0, 0)

export const DateFilters: GridFilterOperator[] = [
  ...getGridDateOperators(),
  {
    value: 'today',
    label: 'today',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }
      return (params: GridCellParams) =>
        getDateParamValue(params.value) === getOnlyDate()
    }
  },
  {
    value: 'yesterday',
    label: 'yesterday',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }
      return (params: GridCellParams) =>
        getDateParamValue(params.value) ===
        getDateParamValue(new Date().setDate(new Date().getDate() - 1))
    }
  },
  {
    value: 'thisWeek',
    label: 'this week',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }
      const today = new Date()
      return (params: GridCellParams) =>
        getDateParamValue(params.value) >=
          getDateParamValue(today.setDate(today.getDate() - today.getDay())) &&
        getDateParamValue(params.value) <=
          getDateParamValue(today.setDate(today.getDate() + 6))
    }
  },
  {
    value: 'thisMonth',
    label: 'this month',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }
      const today = new Date()
      const fullYear = today.getFullYear(),
        month = today.getMonth()
      return params =>
        getDateParamValue(params.value) >=
          getDateParamValue(new Date(fullYear, month, 1)) &&
        getDateParamValue(params.value) <=
          getDateParamValue(new Date(fullYear, month + 1, 0))
    }
  },
  {
    value: 'lastMonth',
    label: 'last month',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }
      const today = new Date()
      const fullYear = today.getFullYear(),
        month = today.getMonth()
      return (params: GridCellParams) =>
        getDateParamValue(params.value) >=
          getDateParamValue(new Date(fullYear, month - 1, 1)) &&
        getDateParamValue(params.value) <=
          getDateParamValue(new Date(fullYear, month, 0))
    }
  },
  {
    value: 'todayPlus7',
    label: 'today + 7',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }
      return (params: GridCellParams) =>
        getDateParamValue(params.value) >= getOnlyDate() &&
        getDateParamValue(params.value) <=
          new Date().setDate(new Date().getDate() + 7)
    }
  },
  {
    value: 'todayPlus30',
    label: 'today + 30',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }
      return (params: GridCellParams) =>
        getDateParamValue(params.value) >= getOnlyDate() &&
        getDateParamValue(params.value) <=
          new Date().setDate(new Date().getDate() + 30)
    }
  },
  {
    value: 'pastDuePlus7',
    label: 'today + 7 + past due',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }
      return (params: GridCellParams) =>
        new Date(params.value as Date) >= new Date(0, 0, 0) &&
        getDateParamValue(params.value) <=
          new Date().setDate(new Date().getDate() + 7)
    }
  },
  {
    value: 'pastDue',
    label: 'today + past due',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }
      return (params: GridCellParams) =>
        new Date(params.value as Date) >= new Date(0, 0, 0) &&
        getDateParamValue(params.value) <= getOnlyDate()
    }
  }
]

export const dateFilter: GridFilterOperator = {
  value: 'isAnyOf',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value || (!filterItem.value[0] && !filterItem.value[1])) {
      return null
    }
    return ({value}: GridCellParams) => {
      if (!value) return false
      const dateFromFilter =
        (filterItem.value as (Date | null)[]).length > 0
          ? filterItem.value[0]
          : null
      const dateToFilter =
        (filterItem.value as (Date | null)[]).length > 1
          ? filterItem.value[1]
          : null
      if (value && (dateFromFilter || dateToFilter)) {
        let time = getLocalDateFromUtc(value)
        return (
          !dateFromFilter ||
          (time >= dateFromFilter && (!dateToFilter || time <= dateToFilter))
        )
      }
      return true
    }
  }
}
