import {
  ScopeSection,
  DataPoint,
  DataPointType,
  DataPointSpecialType
} from './Scope'
import BaseWithNameResource, {BaseResource} from 'src/entities/BaseResource'
import {
  getFullFormattedDate,
  maxDate,
  minDate,
  validateDate
} from 'src/utils/formatDate'

export interface ItemDataPoint extends BaseResource {
  item: BaseResource
  dataPoint: DataPoint
  dataPointId: number
  dataPointName: string
  value: string
  suggestedValue: string
  status: DataPointValueStatus
  isUpdating: boolean
  isSavedSucceeded: boolean
  isSavedFailed: boolean
}

export interface ItemDataPointFOF extends ItemDataPoint {
  isRemoved: boolean
  links: LinkProperties[]
}

export interface LinkProperties {
  uuid: string
  pageNumber: null
}
export interface ScopeSectionItem {
  scopeSection: ScopeSection
  itemDataPoints: ItemDataPoint[]
}
export interface SectionItemDataPoint extends BaseWithNameResource {
  itemDataPoints: ItemDataPoint[]
}

export enum DataPointValueStatus {
  None,
  CopyFromDocument,
  CopyFromDocumentWithChanges,
  CopyFromDB,
  ReviewSuggested,
  ChangesApproved
}

export interface UpdateFOFResource {
  itemDataPoints: ItemDataPoint[]
}

export function getValueWithoutLink(value: string) {
  const removeLink = value.replace(/<a(.*?)<\/a>/g, '')
  const removeLink1 = removeLink.replace(/(<div>)|(<\/div>)/g, ' ')
  return removeLink1.trim()
}

export function getOnlyLinkFromValue(value: string) {
  const link = value.match(/<div><a(.*?)<\/a><\/div>/)?.[0]
  return link || ''
}

export function validateValue(
  data: string | Date,
  dataPointType: DataPointType
) {
  const dataWithoutLink = data instanceof Date ? '' : getValueWithoutLink(data)
  if (dataPointType === DataPointType.Number) {
    const withoutCommaNum = (dataWithoutLink as string).replace(/,/g, '')
    return (
      !isNaN(Number(withoutCommaNum)) &&
      /^\d{0,10}(\.\d{0,5})?$/.test(withoutCommaNum) &&
      (!(dataWithoutLink as string).includes(',') ||
        withoutCommaNum.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') ===
          dataWithoutLink)
    )
  } else if (dataPointType === DataPointType.Date) {
    if (data instanceof Date) {
      const d = new Date(data)
      return (
        !isNaN(d.getTime()) &&
        d.getTime() > minDate.getTime() &&
        d.getTime() < maxDate.getTime()
      )
    } else
      return !dataWithoutLink || validateDate(dataWithoutLink.toString(), true)
  } else return true
}

export const formatValueWithLink = (
  dataWithLink: string,
  type: DataPointType
) => {
  const value =
    type === DataPointType.Text
      ? dataWithLink
      : getValueWithoutLink(dataWithLink)
  const link = getOnlyLinkFromValue(dataWithLink)
  const newValue = formatValue(value, link, type)
  const a = newValue
    .replace(' class="new"', '')
    .replace('href="newTag"', 'href="#"')
  return a
}
export const formatValue = (
  data: string | Date,
  link: string,
  type: DataPointType
) => {
  let value = ''
  switch (type) {
    case DataPointType.Date:
      value = getFullFormattedDate(new Date(data))
      break
    case DataPointType.Text:
      value = /\S/.test(getValueWithoutLink(data as string))
        ? (data as string)
        : ''
      break
    case DataPointType.Number:
      value = (data as string).replace(/,/g, '')
      break
  }
  if (type !== DataPointType.Text) {
    value = `<div>${value}</div>` + (link ? `<div>${link}</div>` : '')
  }
  return value.replaceAll(' contenteditable="false"', '')
}

export const isRegularDataPoint = (itemDatapoint: ItemDataPoint) => {
  return itemDatapoint.dataPoint.specialType === DataPointSpecialType.None
}

export function getDataPoints(sections: SectionItemDataPoint[]) {
  return (
    sections?.reduce(
      (prev, current) =>
        prev.concat(current.itemDataPoints.filter(x => isRegularDataPoint(x))),
      [] as ItemDataPoint[]
    ) || ([] as ItemDataPoint[])
  )
}
