import React from 'react'
import CuiErrorMessage from 'src/components/custom/CuiErrorMessage'
import CuiErrorNetworkIndicator from './CuiErrorNetworkIndicator'

export default class CuiErrorBoundary extends React.Component<
  {
    children?: React.ReactNode // 👈️ added type for children
  },
  {hasError: boolean; error: any}
> {
  constructor(props: {children: React.ReactNode}) {
    super(props)
    this.state = {hasError: false, error: undefined}
  }

  static getDerivedStateFromError(error: any) {
    if (error.name === 'ChunkLoadError') {
      window.location.reload()
      return {}
    }

    return {hasError: true, error}
  }

  render() {
    const {hasError} = this.state
    return (
      <>
        <CuiErrorNetworkIndicator />
        {hasError ? <CuiErrorMessage /> : this.props.children}
      </>
    )
  }
}
