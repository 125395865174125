export const getDescendantProp = (row: any, path: string) => {
  if (!path) return row
  return path.split('.').reduce((acc, part) => acc && acc[part], row)
}

export type OrderDirection = 'asc' | 'desc'

export function descendingComparator(
  a: any,
  b: any,
  orderBy: string,
  format?: any
) {
  let aData = getDescendantProp(a, orderBy)
  aData = format ? format(aData) : aData
  let bData = getDescendantProp(b, orderBy)
  bData = format ? format(bData) : bData
  return bData === null || bData < aData
    ? -1
    : aData === null || bData > aData
    ? 1
    : 0
}

export function getComparator(
  order: OrderDirection,
  orderBy: string,
  format?: any
) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy, format)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy, format)
}

export function tableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}
