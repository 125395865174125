import {useCallback, useState} from 'react'
import useAzureDownload, {AzureDownloadResult} from 'src/hooks/useAzureDownload'
import {
  getFileType,
  getPdfOfWordPath,
  FileType,
  getMediaTypes
} from 'src/utils/AzureStorage'
import FileSaver from 'file-saver'

export default function useAzureDownloadAndOpen(
  storageName: string,
  containerName: string,
  wordContainerName?: string
) {
  const {downloadFile} = useAzureDownload(storageName, containerName)
  const {downloadFile: downloadWordToPdfFile} = useAzureDownload(
    storageName,
    wordContainerName || containerName
  )
  const [loading, setLoading] = useState(false)

  const openInNewTab = useCallback(
    async (file: string) => {
      let result: AzureDownloadResult | undefined
      let type = getFileType(file, false)
      setLoading(true)
      if (type === FileType.Doc || type === FileType.Docx) {
        result = await downloadWordToPdfFile(getPdfOfWordPath(file))
        if (result.isSucceeded) type = FileType.Pdf
      }
      if (
        result === undefined ||
        (result.errorCode === '404' &&
          (type === FileType.Doc || type === FileType.Docx))
      ) {
        result = await downloadFile(file)
      }
      if (result.isSucceeded && result.data) {
        const blob = new Blob([result.data], {type: getMediaTypes(type)})
        if ([FileType.Pdf, FileType.Jpeg, FileType.Jpg].includes(type)) {
          const blobURL = URL.createObjectURL(blob)
          window.open(blobURL, '_blank')
          setLoading(false)
        } else {
          FileSaver.saveAs(blob, file.substring(file.lastIndexOf('/') + 1))
          setLoading(false)
        }
      }
    },
    [downloadFile, downloadWordToPdfFile]
  )

  return {
    openInNewTab,
    loading
  }
}
