import React, {useState} from 'react'
import {styled} from '@mui/material/styles'
import {Typography, Box, Button, Dialog, DialogContent} from '@mui/material'
import Confetti from 'react-confetti'

const PREFIX = 'WelcomeModal'

const classes = {
  root: `${PREFIX}-root`,
  welcomeImg: `${PREFIX}-welcomeImg`,
  txt: `${PREFIX}-txt`
}

const Root = styled('div')(({theme}) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  [`& .${classes.welcomeImg}`]: {
    backgroundColor: 'unset',
    backgroundImage: "url('/confetti_background.png')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '700px',
    boxShadow: 'none'
  },

  [`& .${classes.txt}`]: {
    color: theme.palette.primary.contrastText
  }
}))

export default function WelcomeModal() {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <Root>
      <Box>
        {isOpen && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            tweenDuration={1000}
            colors={['#007DFF', '#FF505F', '#FBD836', '#FD1C72', '#00D6AD']}
            numberOfPieces={400}
            friction={0.995}
          />
        )}
      </Box>
      <Dialog
        classes={{
          paper: classes.welcomeImg
        }}
        maxWidth="md"
        fullWidth={true}
        open={isOpen}
        onClose={onClose}
        disablePortal
      >
        <DialogContent
          classes={{
            root: classes.root
          }}
        >
          <Box textAlign="center">
            <Box mb={6}>
              <Typography variant="h4" className={classes.txt}>
                Hello!
              </Typography>
            </Box>
            <Box mb={9}>
              <Typography variant="h2" className={classes.txt}>
                Welcome to ReaLease
              </Typography>
            </Box>
            <Box mb={10}>
              <Typography variant="h4" className={classes.txt}>
                Same work,
              </Typography>
              <Typography
                variant="h4"
                gutterBottom={true}
                className={classes.txt}
              >
                just faster and easier
              </Typography>
            </Box>
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="error"
              onClick={onClose}
            >
              Ready to start?
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Root>
  )
}
