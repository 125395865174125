import React, {Suspense} from 'react'
import {RouteProps} from 'react-router'
import {Navigate, useLocation} from 'react-router-dom'

import {useAuth} from 'src/context/Auth'
import LoginPopup from 'src/components/login/LoginPopup'
import CuiLoading from 'src/components/custom/CuiLoading'
import HeaderNavbar from './HeaderNavbar'
import {ItemStatus} from 'src/entities/Item'

interface ProtectedRouteProps extends RouteProps {
  loginPopup?: boolean
  title?: string
  itemStatus?: ItemStatus
}

export default function ProtectedRoute({
  children,
  loginPopup,
  title,
  itemStatus
}: ProtectedRouteProps) {
  const {isAuth, isMsalAuth, shouldLogout} = useAuth()
  const location = useLocation()

  if (shouldLogout && loginPopup) return <LoginPopup />
  if (isAuth) {
    return (
      <>
        <HeaderNavbar title={title} itemStatus={itemStatus} />
        <Suspense fallback={<CuiLoading />}>{children}</Suspense>
      </>
    )
  }
  if (isMsalAuth) {
    return (
      <>
        <HeaderNavbar title={title} />
        <Suspense fallback={<CuiLoading />}>
          <CuiLoading />
        </Suspense>
      </>
    )
  }
  return <Navigate to="/login" replace state={{from: location.pathname}} />
}
