import React from 'react'
import {useEffect} from 'react'
import useNavigatorOnLine from 'src/hooks/useNavigatorOnLine'
import CuiSnackbarMessage from './CuiSnackbarMessage'

export default function CuiErrorNetworkIndicator() {
  const isOnline = useNavigatorOnLine()

  useEffect(() => {
    isOnline
      ? console.log('You are online ' + new Date())
      : console.log('You are currently offline ' + new Date())
  }, [isOnline])

  return !isOnline ? (
    <CuiSnackbarMessage
      isOpenSnackbar={!isOnline}
      closeSnackbar={() => {}}
      message="You are currently offline. Please contact IT"
      severity="error"
    />
  ) : (
    <></>
  )
}
