import BaseWithNameResource from 'src/entities/BaseResource'

export function getEnumOption(type: any, filterArr?: number[]) {
  return Object.keys(type)
    .filter(key => {
      return !filterArr || filterArr?.includes(Number(key))
    })
    .reduce((prev, dpType) => {
      const resNumber = Number(dpType)
      if (!isNaN(resNumber) && resNumber) {
        prev.push(resNumber as any)
      }
      return prev
    }, [] as number[])
}

export function getEnumOptionWithLabel(
  type: any,
  isEnumFlag: boolean = false,
  exceptValues: any[] = []
) {
  return Object.keys(type)
    .filter(x => !exceptValues.includes(Number(x)))
    .reduce((prev, dpType) => {
      const resNumber = Number(dpType)
      if (
        !isNaN(resNumber) &&
        (isEnumFlag ? (resNumber & (resNumber - 1)) === 0 : true)
      ) {
        prev.push({
          value: resNumber as any,
          label: type[resNumber]
        })
      }
      return prev
    }, [] as {value: any; label: string}[])
}

export function getEnumAsBaseWithNameResource(
  type: any,
  isEnumFlag: boolean = false,
  exceptValues: any[] = []
) {
  return Object.keys(type)
    .filter(x => !exceptValues.includes(Number(x)))
    .reduce((prev, dpType) => {
      const resNumber = Number(dpType)
      if (
        !isNaN(resNumber) &&
        resNumber &&
        (isEnumFlag ? (resNumber & (resNumber - 1)) === 0 : true)
      ) {
        prev.push({
          id: resNumber as number,
          name: type[resNumber]
        } as BaseWithNameResource)
      }
      return prev
    }, [] as BaseWithNameResource[])
}
