import React, {useEffect} from 'react'
import {styled} from '@mui/material/styles'
import {Typography, TypographyProps} from '@mui/material'
import classnames from 'classnames'
import DOMPurify from 'dompurify'

const PREFIX = 'CuiTypography'

const classes = {
  noPadding: `${PREFIX}-noPadding`,
  ellipsis: `${PREFIX}-ellipsis`
}

const StyledTypography = styled(Typography)({
  [`&.${classes.noPadding}&.ql-editor`]: {
    padding: '0',
    whiteSpace: 'normal'
  }
})

type CuiTypographyProps = TypographyProps & {
  htmlValue?: string
  isBubbleRichTextBoxValue?: boolean
}

export default function CuiTypography({
  htmlValue,
  isBubbleRichTextBoxValue,
  className,
  ...props
}: CuiTypographyProps) {
  useEffect(() => {
    if (isBubbleRichTextBoxValue) {
      require('react-quill/dist/quill.core.css')
    }
  }, [isBubbleRichTextBoxValue])

  return (
    <StyledTypography
      className={classnames(
        isBubbleRichTextBoxValue && 'ql-editor',
        isBubbleRichTextBoxValue && classes.noPadding,
        className
      )}
      dangerouslySetInnerHTML={
        htmlValue
          ? {
              __html: DOMPurify.sanitize(
                htmlValue?.replace(/<p[^>]*>\s*<\/p>/g, '<br>')
              )
            }
          : undefined
      }
      {...props}
    >
      {props.children}
    </StyledTypography>
  )
}
