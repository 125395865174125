import React from 'react'
import {styled} from '@mui/material/styles'
import {CircularProgress, IconButton, IconButtonProps} from '@mui/material'

const PREFIX = 'CuiProgressIconButton'

const classes = {
  input: `${PREFIX}-input`,
  wrapper: `${PREFIX}-wrapper`,
  buttonProgress: `${PREFIX}-buttonProgress`
}

const Root = styled('div')(({theme}) => ({
  [`& .${classes.input}`]: {
    fontSize: 14
  },

  [`&.${classes.wrapper}`]: {
    position: 'relative',
    display: 'inline'
  },

  [`& .${classes.buttonProgress}`]: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

type CuiProgressIconButtonProps = IconButtonProps & {
  loading?: boolean
}

export default function CuiProgressIconButton({
  loading,
  ...props
}: CuiProgressIconButtonProps) {
  return (
    <Root className={classes.wrapper}>
      <IconButton disabled={loading} {...props} size="large"></IconButton>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Root>
  )
}
