const config = {
  AADRedirectUrl: process.env.REACT_APP_AAD_REDIRECT_URL || '',
  AADConfig: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID || '',
    authority: process.env.REACT_APP_AAD_AUTHORITY || ''
  },
  fileApiUrl: process.env.REACT_APP_FILE_API || '',
  apiUrl: process.env.REACT_APP_API || '',
  ddReportApi: process.env.REACT_APP_DDREPORT_API || '',
  azureFileShareStorage: {
    name: process.env.REACT_APP_AZURE_FILE_STORAGE || '',
    share: process.env.REACT_APP_AZURE_STORAGE_SHARE || '',
    ocrContainer: process.env.REACT_APP_POST_OCR_CONTAINER || ''
  },
  azureOcrBlobStorage: {
    name: process.env.REACT_APP_AZURE_BLOB_STORAGE || '',
    container: process.env.REACT_APP_POST_OCR_CONTAINER || ''
  },
  azureWordToPdfBlobStorage: {
    name: process.env.REACT_APP_AZURE_BLOB_STORAGE || '',
    container: process.env.REACT_APP_WORD_TO_PDF_CONTAINER || ''
  },
  azureProjectDocumentBlobStorage: {
    name: process.env.REACT_APP_AZURE_BLOB_STORAGE || '',
    container: process.env.REACT_APP_PROJECT_DOCS_CONTAINER || ''
  },
  azureChangeLogDocumentBlobStorage: {
    name: process.env.REACT_APP_AZURE_BLOB_STORAGE || '',
    container: process.env.REACT_APP_CHANGE_LOG_DOCS_CONTAINER || ''
  },
  azureInvoiceAttachmentsBlobStorage: {
    name: process.env.REACT_APP_AZURE_BLOB_STORAGE || '',
    container: process.env.REACT_APP_INVOICE_CONTAINER || ''
  },
  azureSearch: {
    url: process.env.REACT_APP_AZURE_SEARCH || '',
    index: process.env.REACT_APP_AZURE_SEARCH_INDEX || '',
    apiVersion: process.env.REACT_APP_AZURE_SEARCH_APIVERSION || '',
    apiKey: process.env.REACT_APP_AZURE_SEARCH_APIKEY || ''
  },
  isProduction: process.env.REACT_APP_BRANCH_NAME === 'master',
  isMaster:
    process.env.REACT_APP_BRANCH_NAME === 'master' &&
    process.env.REACT_APP_URL?.includes('master'),
  isDevelopment: process.env.NODE_ENV === 'development',
  logRocketAppId: process.env.REACT_APP_LOGROCKET_APPID || '',
  rollbarPostClientItem: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM || '',
  viewerLicenseKey: process.env.REACT_APP_VIEWER_LICENSE_KEY || '',
  MUIDataGridLicenseKey: process.env.REACT_APP_MUI_DATA_GRID_LICENSE_KEY || '',
  trashFolder: process.env.REACT_APP_TRASH_FOLDER || '00_Trash/',
  favicon: {
    icon: process.env.REACT_APP_ICON || 'favicon.ico',
    activeIcon: process.env.REACT_APP_ICON || 'favActive.svg'
  },
  clientsTest: ['TRR - Training', 'TES - Test Name']
}

export default config
