import React from 'react'
import classNames from 'classnames'
import {Button, CircularProgress} from '@mui/material'
import {useAuth} from 'src/context/Auth'
import {ReactComponent as MicrosoftIcon} from 'src/images/MicrosoftLogo.svg'
import {styled} from '@mui/material/styles'

const PREFIX = 'LoginButton'

const classes = {
  submit: `${PREFIX}-submit`,
  whiteColor: `${PREFIX}-whiteColor`
}

const StyledButton = styled(Button)(({theme}) => ({
  [`&.${classes.submit}`]: {
    padding: theme.spacing(1, 4),
    marginTop: theme.spacing(3)
  },

  [`&.${classes.whiteColor}, & .${classes.whiteColor}`]: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white
  }
}))

interface LoginButtonProps {
  color?: 'white'
}

export default function LoginButton({color}: LoginButtonProps) {
  const {login, isLoggingIn} = useAuth()

  return (
    <StyledButton
      type="submit"
      variant="outlined"
      className={classNames(classes.submit, color && classes.whiteColor)}
      onClick={() => login()}
      startIcon={<MicrosoftIcon />}
      endIcon={
        isLoggingIn && (
          <CircularProgress
            size={24}
            className={color ? classes.whiteColor : ''}
          />
        )
      }
    >
      Continue with Microsoft
    </StyledButton>
  )
}
