import {useCallback, useState} from 'react'
import useAzureBlobClient from 'src/hooks/useAzureBlobClient'
import {BlockBlobUploadResponse} from '@azure/storage-blob'
import {setParentDate} from 'src/utils/AzureStorage'

export interface AzureResult {
  data: BlockBlobUploadResponse
  isSucceeded: boolean
}

export default function useAzureUpload(
  storageName: string,
  containerName: string
) {
  const {getClient} = useAzureBlobClient(storageName, containerName)
  const [loading, setLoading] = useState<boolean>(false)

  const uploadFile = useCallback(
    async (blob: Blob, filePath: string, abortSignal?: AbortSignal) => {
      setLoading(true)
      const client = await getClient()
      if (!client) {
        setLoading(false)
        return false
      }
      try {
        const blockBlobClient = client.getBlockBlobClient(filePath)
        const dateModified = new Date().toUTCString()
        console.log('save file:before send file ' + filePath)
        setLoading(true)

        const data = await blockBlobClient.upload(blob, blob.size, {
          metadata: {datemodified: dateModified},
          abortSignal: abortSignal
        })
        console.log('save file:send file succces' + filePath)

        await setParentDate(client, filePath, dateModified, abortSignal)
        setLoading(false)
        return !data.errorCode
      } catch (error) {
        console.error(error)
        setLoading(true)
        if (abortSignal?.aborted) return undefined
        return false
      }
    },
    [getClient]
  )

  return {
    uploadFile,
    loading
  }
}
