import React from 'react'
import {Typography, Box, Link} from '@mui/material'
import CuiMainContainer from 'src/components/custom/CuiMainContainer'

export default function CuiErrorMessage({
  errorMessage = ''
}: {
  errorMessage?: string
}) {
  return (
    <>
      <CuiMainContainer>
        <Box
          sx={{
            zIndex: theme => theme.zIndex.tooltip,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" sx={{mt: 2, color: 'error.main'}}>
            {errorMessage || 'Oooops! something went wrong...'}
          </Typography>
          <Typography sx={{mt: 1}}>
            Click <Link href="">here</Link> to reload
          </Typography>
        </Box>
      </CuiMainContainer>
    </>
  )
}
