import React from 'react'
import {useState, useEffect, useCallback, useMemo} from 'react'
import {Box, Stack} from '@mui/material'
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  useGridApiRef,
  GridFilterModel,
  GridLinkOperator,
  GridCellEditCommitParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridFilterItem,
  gridFilteredSortedRowIdsSelector,
  GridRowId,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridPreProcessEditCellProps,
  GridToolbarColumnsButton
} from '@mui/x-data-grid-pro'
import {
  SavingGridRow,
  AttachmentViewCell,
  ViewInvoiceCell,
  PaidAtEditCell,
  quickSearch,
  statusFilter,
  DeleteCell,
  isDeletedFilter,
  NumberEditCell,
  DiscountPercentEditCell,
  projectFilter
} from './InvoiceTableComponents'
import InvoiceTableFilter from './InvoiceTableFilter'
import InvoicesDetailsModal from './InvoicesDetailsModal'
import InvoiceTableAttachmentModal from './InvoiceTableAttachmentModal'
import InvoiceAttachment from 'src/entities/InvoiceAttachment'
import CuiCustomRow from 'src/components/custom/dataGrid/CuiCustomRow'
import {useAuth} from 'src/context/Auth'
import Invoice, {getTotalAmount} from 'src/entities/Invoice'
import {getFormattedDate} from 'src/utils/formatDate'
import {getEnumOptionWithLabel} from 'src/utils/enumHelper'
import config from 'src/config'
import {formatNumber} from 'src/utils/numberHelper'
import {BaseProjectResource, ProjectStatus} from 'src/entities/Project'
import {dateFilter} from 'src/components/custom/dataGrid/CuiDateFilters'
import {autocompleteFilter} from 'src/components/custom/dataGrid/CuiAutoComplete'
import {useAutosaveContext, SaveType} from 'src/context/Autosave'
import {
  customPreProcessEditCellProps,
  isSameValue
} from 'src/components/custom/dataGrid/CuiComponents'
import {UserRole} from 'src/entities/User'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import CuiSnackbarMessage, {
  SnackbarMessageData
} from 'src/components/custom/CuiSnackbarMessage'
import {FilterType, fixDates} from 'src/hooks/api/useFilters'
import {
  ColumnSetting,
  GridSettings
} from 'src/components/custom/dataGrid/CuiSettings'
import {
  GridInitialState,
  GridApiRef
} from '@mui/x-data-grid-pro/typeOverloads/reexports'

export enum InvoiceGridColumn {
  Client = 'client',
  Project = 'project',
  ProjectName = 'projectName',
  PropertyName = 'splitByValue',
  invoiceNumber = 'invoiceNumber',
  CreatedAt = 'createdAt',
  Amount = 'amount',
  Paid = 'paidAmount',
  PaidAt = 'paidAt',
  Discount = 'discount',
  DiscountPercent = 'discountPercent',
  Attachments = 'attachments',
  ProjectStatus = 'projectStatus',
  IsProjectStatusChanged = 'isProjectStatusChanged',
  Comments = 'comments',
  IsDeleted = 'isDeleted',
  View = 'View',
  ArchiveComments = 'ArchiveComments'
}

interface InvoicePageProps {
  projectId?: number
}

interface CustomToolbarProps {
  onSave?: () => void
  isSending?: boolean
  isDisabled?: boolean
}

const customToolbar = ({onSave, isSending, isDisabled}: CustomToolbarProps) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector></GridToolbarDensitySelector>
      <GridToolbarExport printOptions={{disableToolbarButton: true}} />
      <Stack pt={0.5} ml={0.5}>
        <CuiProgressButton
          variant="outlined"
          size="small"
          onClick={onSave}
          loading={isSending}
          disabled={isDisabled}
        >
          save as my default view
        </CuiProgressButton>
      </Stack>
    </GridToolbarContainer>
  )
}

const customComponents = {
  Toolbar: customToolbar,
  Row: CuiCustomRow
}

const projectColumns: GridColDef[] = [
  {
    field: InvoiceGridColumn.Project,
    headerName: 'Code',
    width: 100,
    valueGetter: cell => (cell.value as BaseProjectResource)?.code,
    filterOperators: [projectFilter]
  },
  {
    field: InvoiceGridColumn.Client,
    headerName: 'Client',
    width: 250,
    valueGetter: params =>
      (params.row as Invoice)?.project?.client?.code +
      ' - ' +
      (params.row as Invoice)?.project?.client?.name,
    filterOperators: [autocompleteFilter]
  },
  {
    field: InvoiceGridColumn.ProjectName,
    headerName: 'Project',
    valueGetter: params => (params.row as Invoice)?.project?.name,
    width: 200
  }
]

const invoiceColumns: GridColDef[] = [
  {
    field: InvoiceGridColumn.ProjectStatus,
    headerName: 'Project Status',
    width: 170,
    type: 'singleSelect',
    valueOptions: getEnumOptionWithLabel(ProjectStatus),
    valueFormatter: cell => ProjectStatus[Number(cell.value)] || '',
    filterOperators: [statusFilter]
  },
  {
    field: InvoiceGridColumn.invoiceNumber,
    headerName: 'Invoice #',
    width: 100,
    ...customPreProcessEditCellProps(InvoiceGridColumn.invoiceNumber),
    filterOperators: [quickSearch]
  },
  {
    field: InvoiceGridColumn.CreatedAt,
    headerName: 'Date Created',
    width: 150,
    valueFormatter: cell => getFormattedDate(cell.value as Date),
    type: 'date',
    filterOperators: [dateFilter]
  },
  {
    field: InvoiceGridColumn.Amount,
    headerName: 'Amount',
    width: 100,
    valueFormatter: cell => cell.value && formatNumber(cell.value as number)
  },
  {
    field: InvoiceGridColumn.Discount,
    headerName: 'Discount',
    width: 100,
    valueFormatter: cell => cell.value && formatNumber(cell.value as number),
    renderEditCell: (params: GridRenderEditCellParams) =>
      NumberEditCell(params),
    ...customPreProcessEditCellProps(InvoiceGridColumn.Discount)
  },
  {
    field: InvoiceGridColumn.DiscountPercent,
    headerName: 'Discount Percent',
    width: 100,
    valueFormatter: cell => cell.value && cell.value + '%',
    renderEditCell: (params: GridRenderEditCellParams) =>
      DiscountPercentEditCell(params),
    ...customPreProcessEditCellProps(InvoiceGridColumn.DiscountPercent)
  },
  {
    field: InvoiceGridColumn.Paid,
    headerName: 'Paid',
    width: 100,
    valueFormatter: cell => cell.value && formatNumber(cell.value as number),
    renderEditCell: (params: GridRenderEditCellParams) =>
      NumberEditCell(params),
    ...customPreProcessEditCellProps(InvoiceGridColumn.Paid)
  },

  {
    field: InvoiceGridColumn.PaidAt,
    headerName: 'Date Paid',
    width: 215,
    valueFormatter: cell => getFormattedDate(cell.value as Date),
    renderEditCell: (params: GridRenderEditCellParams) =>
      PaidAtEditCell(params),
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      return {
        ...params.props,
        error:
          isNaN(new Date(params.props.value as Date).getTime()) ||
          isSameValue(params, InvoiceGridColumn.PaidAt)
      }
    },
    type: 'date',
    filterOperators: [dateFilter]
  },
  {
    field: InvoiceGridColumn.PropertyName,
    headerName: 'Property Name',
    width: 200,
    valueGetter: params =>
      (params.row as Invoice)?.billingInfo.splitInvoiceBy === 2
        ? (params.row as Invoice).splitByValue
        : '',
    ...customPreProcessEditCellProps(InvoiceGridColumn.PropertyName)
  },
  {
    field: InvoiceGridColumn.Comments,
    headerName: 'Comments',
    width: 200,
    ...customPreProcessEditCellProps(InvoiceGridColumn.Comments)
  },
  {
    field: InvoiceGridColumn.View,
    headerName: 'View',
    width: 100,
    sortable: false,
    renderCell: () => ViewInvoiceCell(),
    disableExport: true
  },
  {
    field: InvoiceGridColumn.ArchiveComments,
    disableExport: true
  }
]

const editableFields = [
  InvoiceGridColumn.ProjectStatus,
  InvoiceGridColumn.Discount,
  InvoiceGridColumn.DiscountPercent,
  InvoiceGridColumn.Paid,
  InvoiceGridColumn.PaidAt,
  InvoiceGridColumn.invoiceNumber,
  InvoiceGridColumn.Comments
]

const initialState = {
  sorting: {
    sortModel: [{field: InvoiceGridColumn.CreatedAt, sort: 'desc'}]
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field]
  },
  columns: {
    columnVisibilityModel: {
      [InvoiceGridColumn.ArchiveComments]: false
    }
  }
} as GridInitialState

const saveRows = (
  ids: GridRowId[],
  fieldId: string,
  value: any,
  prev: SavingInvoice[]
) => {
  return prev.map(row =>
    ids.some(x => x === row.id)
      ? {...row, [fieldId]: value, ignoreChanges: false}
      : row
  )
}

const getSelectedAndFilteredRow = (apiRef: GridApiRef) => {
  const selected: GridRowId[] = Array.from(
    apiRef.current.getSelectedRows().keys()
  )
  const filters = gridFilteredSortedRowIdsSelector(apiRef)
  return selected.filter(x => filters.some(y => y === x))
}

const getRowsToChange = (apiRef: GridApiRef, currentId: number) => {
  const selected: GridRowId[] = getSelectedAndFilteredRow(apiRef)
  return selected.includes(currentId) ? selected : [currentId as GridRowId]
}

export interface SavingInvoice extends Invoice {
  ignoreChanges?: boolean
}

export default function InvoiceTable({projectId}: InvoicePageProps) {
  const [rows, setRows] = useState<Invoice[]>([])
  const [savingRows, setSavingRows] = useState<SavingInvoice[]>([])
  const [openViewModal, setOpenViewModal] = useState<{
    invoiceId: number | null
    open: boolean
  }>({invoiceId: null, open: false})

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        columnField: InvoiceGridColumn.IsDeleted,
        operatorValue: 'is',
        value: false,
        id: 1
      } as GridFilterItem
    ],
    linkOperator: GridLinkOperator.And
  })
  const [loading, setLoading] = useState(true)

  const [openAttachmentModal, setOpenAttachmentModal] = useState<{
    invoice: Invoice | null
    open: boolean
  }>({invoice: null, open: false})
  const [settingsId, setSettingsId] = useState(0)
  const [isSendingSettings, setIsSendingSettings] = useState(false)
  const [isDisabledSending, setIsDisabledSending] = useState(true)
  const [snackbarData, setSnackbarData] = useState<SnackbarMessageData>({
    isOpen: false,
    message: ''
  })

  const {fetchWithUser, roles} = useAuth()
  const apiRef = useGridApiRef()
  const {inProgress} = useAutosaveContext()

  useEffect(() => {
    const columnsToAddByPermissions: GridColDef[] = []

    if (roles?.length === 1 && roles[0] !== UserRole[UserRole.SalesRepAdmin])
      columnsToAddByPermissions.push({
        field: InvoiceGridColumn.IsDeleted,
        headerName: '',
        width: 100,
        sortable: false,
        renderCell: function Delete(params: GridRenderCellParams) {
          return <DeleteCell {...params} />
        },
        filterOperators: [isDeletedFilter],
        disableExport: true
      })

    columnsToAddByPermissions.push({
      field: InvoiceGridColumn.Attachments,
      headerName: 'Attachments',
      width: 250,
      renderCell: (params: GridRenderCellParams) =>
        AttachmentViewCell(
          params,
          !(
            roles?.length === 1 && roles[0] === UserRole[UserRole.SalesRepAdmin]
          )
        ),
      sortable: false,
      disableExport: true
    })

    apiRef.current.updateColumns(columnsToAddByPermissions)
  }, [roles, apiRef])

  const saveDefaultFilters = () => {
    const columnSettings: ColumnSetting[] = []
    apiRef.current.getAllColumns().map(column => {
      columnSettings.push({
        field: column.field,
        index: apiRef.current.getColumnIndex(column.field),
        width: column.width
      })
      return column
    })
    setIsSendingSettings(true)
    const tableSettings: GridSettings = {
      density: apiRef.current.state.density.value,
      columnVisibilityModel: apiRef.current.state.columns.columnVisibilityModel,
      sortModel: apiRef.current.getSortModel(),
      filterModel: filterModel,
      pinnedColumns: apiRef.current.getPinnedColumns(),
      columnIndexes: columnSettings
    }
    fetchWithUser(`${config.apiUrl}/filters`, {
      method: 'PUT',
      body: JSON.stringify({
        id: settingsId,
        type: projectId
          ? FilterType.InvoicePerProject
          : FilterType.InvoicesTable,
        value: JSON.stringify(tableSettings)
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    })
      .then(res => res.json())
      .then(data => {
        setSnackbarData({
          isOpen: true,
          message: 'Your default view has been successfully saved!',
          severity: 'success'
        })
        !settingsId && setSettingsId(data.id)
        setIsSendingSettings(false)
      })
  }

  const getDefaultFilters = useCallback(() => {
    fetchWithUser(
      `${config.apiUrl}/filters?type=${
        projectId ? FilterType.InvoicePerProject : FilterType.InvoicesTable
      }`
    )
      .then(res => res.status === 200 && res.json())
      .then(data => {
        if (data) {
          const gridSettings: GridSettings = JSON.parse(data.value)
          apiRef.current.setDensity(gridSettings.density)
          apiRef.current.setSortModel(gridSettings.sortModel)
          setFilterModel(fixDates(gridSettings.filterModel))
          gridSettings.columnIndexes?.map((column: ColumnSetting) => {
            if (column.width) {
              if (apiRef.current.getColumn(column.field))
                apiRef.current.setColumnWidth(column.field, column.width)
            }
            apiRef.current.setColumnIndex(column.field, column.index)
            return column
          })
          apiRef.current.setColumnVisibilityModel({
            ...gridSettings.columnVisibilityModel,
            ...initialState.columns?.columnVisibilityModel
          })
          setSettingsId(data.id)
        }
        setIsDisabledSending(false)
      })
  }, [fetchWithUser, apiRef, projectId])

  const columns = useMemo(
    () => (projectId ? invoiceColumns : [...projectColumns, ...invoiceColumns]),
    [projectId]
  )

  const updateAmount = useCallback(
    (ids: GridRowId[], field: string, value: number) => {
      const newAmounts = ids.map(id => {
        const row = apiRef.current.getRow(id)

        const newBillingInfo = {
          ...(row as Invoice).billingInfo,
          discountPercent:
            field === InvoiceGridColumn.DiscountPercent
              ? value
              : (row as Invoice).discountPercent,
          discount:
            field === InvoiceGridColumn.Discount
              ? value
              : (row as Invoice).discount
        }

        const newAmount = getTotalAmount(
          [],
          newBillingInfo,
          1,
          (row as Invoice).subAmount
        )
        return {id: id, [field]: value, [InvoiceGridColumn.Amount]: newAmount}
      })

      apiRef.current.updateRows(newAmounts)
      setSavingRows(prev =>
        prev.map(row => {
          const newAmount = newAmounts.find(x => x.id === row.id)
          return newAmount
            ? {
                ...row,
                [field]: value,
                [InvoiceGridColumn.Amount]: newAmount[InvoiceGridColumn.Amount],
                ignoreChanges: false
              }
            : row
        })
      )
    },
    [apiRef]
  )

  const onCellEditCommit = useCallback(
    (params: GridCellEditCommitParams) => {
      let selectedIds: GridRowId[] = getRowsToChange(
        apiRef,
        params.id as number
      )

      if (params.field === InvoiceGridColumn.ProjectStatus) {
        let projectIds: number[] = []
        selectedIds.map(id => {
          let currentInvoice = rows.find(
            (invoice: Invoice) => invoice.id === id
          )

          const invoiceFiltered = rows.filter((invoice: Invoice) => {
            return (
              invoice.projectId === currentInvoice?.projectId &&
              invoice?.id !== id
            )
          })

          const currentIds: number[] = invoiceFiltered.map(
            (invoice: Invoice) => invoice.id
          )
          projectIds = [...projectIds, ...currentIds]
          return id
        })

        const allIds = [...selectedIds, ...projectIds]

        apiRef.current.updateRows(
          allIds.map(x => {
            return {
              id: x,
              [params.field]: params.value
            }
          })
        )

        setSavingRows(prev =>
          prev.map(row =>
            allIds.some(x => x === row.id)
              ? {
                  ...row,
                  [params.field]: params.value,
                  ignoreChanges: selectedIds.find(id => id === row.id)
                    ? false
                    : true
                }
              : {
                  ...row,
                  ignoreChanges: true
                }
          )
        )
      } else if (
        params.field === InvoiceGridColumn.DiscountPercent ||
        params.field === InvoiceGridColumn.Discount
      ) {
        updateAmount(
          selectedIds.length > 0 ? selectedIds : [params.id],
          params.field,
          params.value as number
        )
      } else {
        apiRef.current.updateRows(
          selectedIds.map(x => {
            return {
              id: x,
              [params.field]: params.value
            }
          })
        )

        setSavingRows(prev =>
          saveRows([...selectedIds], params.field, params.value, prev)
        )
      }
    },
    [apiRef, updateAmount, rows]
  )

  const onCellClick = useCallback((p: GridCellParams) => {
    if (p.field === InvoiceGridColumn.View) {
      setOpenViewModal({invoiceId: (p.row as Invoice).id, open: true})
    } else if (p.field === InvoiceGridColumn.Attachments) {
      setOpenAttachmentModal({invoice: p.row as Invoice, open: true})
    }
  }, [])

  const onCloseModal = (
    attachment: InvoiceAttachment | null,
    isDelete?: boolean
  ) => {
    let selectedIds: GridRowId[] = []

    if (attachment) {
      if (isDelete) {
        if (openAttachmentModal?.invoice?.id)
          selectedIds = [openAttachmentModal.invoice.id as GridRowId]
      } else {
        selectedIds = getRowsToChange(
          apiRef,
          openAttachmentModal?.invoice?.id || 0
        )
      }
      apiRef.current.updateRows(
        selectedIds.map(id => {
          const invoiceSaved: Invoice = apiRef.current.getRow(id) as Invoice

          let attachments = isDelete
            ? invoiceSaved.attachments.filter(
                current => current.file.path !== attachment.file.path
              )
            : invoiceSaved.attachments

          attachments =
            attachments.length === invoiceSaved.attachments.length
              ? [...(invoiceSaved.attachments || []), attachment]
              : attachments

          return {
            id: id,
            attachments: attachments
          }
        })
      )
    }
    setOpenAttachmentModal({invoice: null, open: false})
  }
  useEffect(() => {
    getDefaultFilters()
  }, [getDefaultFilters])

  useEffect(() => {
    let ignore = false
    const url =
      `${config.apiUrl}/invoice` + (projectId ? `/project/${projectId}` : '')
    fetchWithUser(url, {
      method: 'GET'
    })
      .then(response => response.json())
      .then((data: Invoice[]) => {
        if (!ignore) {
          console.log('before setRows')
          setRows(data.filter(x => !x.isDeleted))
          setSavingRows(data.filter(x => !x.isDeleted))
          console.log('after setSavingRows')
        }
      })
      .finally(() => {
        setLoading(false)
      })

    //support react 18 script mode
    return () => {
      ignore = true
    }
  }, [fetchWithUser, projectId])

  useEffect(() => {
    let editableColumns =
      roles?.some(x => x === UserRole[UserRole.Admin]) &&
      invoiceColumns
        .map(column => {
          if (editableFields.toString().includes(column.field))
            return {...column, editable: true}
          return column
        })
        .filter(x => x.editable)
    editableColumns && apiRef.current.updateColumns(editableColumns)
  }, [roles, apiRef])

  return (
    <>
      <Box pt={2} pb={2} width="100%">
        <InvoiceTableFilter
          projectId={projectId}
          filterModel={filterModel}
          setFilterModel={setFilterModel}
        />
      </Box>
      <Box sx={{width: '100%', height: '73vh'}}>
        {rows && (
          <DataGridPro
            columnThreshold={0}
            disableColumnMenu
            apiRef={apiRef}
            rows={rows}
            loading={loading}
            columns={columns}
            components={customComponents}
            componentsProps={{
              toolbar: {
                sx: {ml: 0.5, mt: 0.5},
                printOptions: {disableToolbarButton: true, disable: true},
                onSave: saveDefaultFilters,
                isSending: isSendingSettings,
                isDisabled: isDisabledSending
              }
            }}
            filterModel={filterModel}
            disableSelectionOnClick
            disableColumnFilter
            onCellEditCommit={onCellEditCommit}
            initialState={initialState}
            density={'compact'}
            pagination
            checkboxSelection
            onCellClick={onCellClick}
            isCellEditable={() =>
              !roles.some(r => r === UserRole[UserRole.SalesRepAdmin])
            }
          />
        )}
      </Box>
      <CuiSnackbarMessage
        isOpenSnackbar={snackbarData.isOpen}
        closeSnackbar={() => setSnackbarData(m => ({...m, isOpen: false}))}
        message={snackbarData.message}
        severity={snackbarData.severity}
        autoHideDuration={5000}
        variant="outlined"
      />
      {savingRows.map(x => (
        <SavingGridRow key={x.id} data={x}></SavingGridRow>
      ))}
      {openAttachmentModal.invoice && (
        <InvoiceTableAttachmentModal
          invoice={openAttachmentModal.invoice}
          invoiceIds={getRowsToChange(apiRef, openAttachmentModal.invoice.id)}
          open={openAttachmentModal.open}
          onClose={onCloseModal}
        />
      )}
      {openViewModal.invoiceId &&
        !inProgress?.find(process => process.type === SaveType.Invoice) && (
          <InvoicesDetailsModal
            invoiceId={openViewModal.invoiceId}
            open={openViewModal.open}
            onClose={() => setOpenViewModal({invoiceId: null, open: false})}
          />
        )}
    </>
  )
}
