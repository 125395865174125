import React, {useState} from 'react'
import {styled} from '@mui/material/styles'
import {Grid} from '@mui/material'
import CuiMainContainer from 'src/components/custom/CuiMainContainer'
import {useAuth} from 'src/context/Auth'
import config from 'src/config'
import CuiDatePicker from 'src/components/custom/CuiDatePicker'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import {downloadFile} from 'src/utils/DownloadFile'

const PREFIX = 'CommissionsReportPage'

const classes = {
  root: `${PREFIX}-root`,
  iconColor: `${PREFIX}-iconColor`
}

const StyledCuiMainContainer = styled(CuiMainContainer)(({theme}) => ({
  [`& .${classes.root}`]: {
    marginTop: theme.spacing(10)
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.common.white
  }
}))

const yesterday = () => {
  let t = new Date()
  t.setDate(t.getDate() - 1)
  return t
}

export default function TimeTrackingReportPage() {
  const [isLoading, setIsLoading] = useState(false)
  const [FromCreatedAt, setFromCreatedAt] = useState<Date | null>(yesterday())
  const [ToCreatedAt, setToCreatedAt] = useState<Date | null>(yesterday())
  const {fetchWithUser} = useAuth()

  const onExportToExcel = () => {
    if (!FromCreatedAt || !ToCreatedAt)
      alert('You must fill the From and To parameters')
    else {
      setIsLoading(true)
      const options = {
        method: 'POST',
        body: JSON.stringify({
          from: FromCreatedAt,
          to: ToCreatedAt,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
      fetchWithUser(
        config.apiUrl + `/reports/timeTrackingByStartDateReport`,
        options
      )
        .then(response => {
          downloadFile(response).then(() => {})
        })
        .catch(e => {
          if (e.status === 403) {
            alert('You are not allowed to generate commissions report')
          } else {
            alert('Something went wrong. Please try again later.')
          }
        })
        .finally(() => setIsLoading(false))
    }
  }

  return (
    <StyledCuiMainContainer title="TimeTracking Report">
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={4}>
          <CuiDatePicker
            label="TimeTracking From"
            value={FromCreatedAt}
            onChange={(date: Date | null) => {
              setFromCreatedAt(date)
            }}
            InputProps={{fullWidth: true}}
          />
        </Grid>
        <Grid item xs={4}>
          <CuiDatePicker
            label="TimeTracking To"
            value={ToCreatedAt}
            onChange={(date: Date | null) => {
              setToCreatedAt(date)
            }}
            InputProps={{fullWidth: true}}
          />
        </Grid>
        <Grid item xs={4}>
          <CuiProgressButton
            variant="contained"
            color="primary"
            onClick={onExportToExcel}
            loading={isLoading}
          >
            Generate
          </CuiProgressButton>
        </Grid>
      </Grid>
    </StyledCuiMainContainer>
  )
}
