import React from 'react'
import {Tooltip, Button} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'

interface CuiCancelSaveButtonProps {
  onCancel: () => void
  onSave: () => void
  disableCancel?: boolean
  disableSave?: boolean
  loading?: boolean
  error?: boolean
  saveButtonText?: string
}

export default function CuiCancelSaveButton({
  onCancel,
  onSave,
  disableCancel,
  disableSave,
  loading,
  error,
  saveButtonText
}: CuiCancelSaveButtonProps) {
  return (
    <>
      {error && (
        <Tooltip title="Failed to save data, please try again">
          <ErrorOutlineIcon color="error" />
        </Tooltip>
      )}
      <Button color="primary" onClick={onCancel} disabled={disableCancel}>
        Cancel
      </Button>
      <CuiProgressButton
        color="primary"
        onClick={onSave}
        loading={loading}
        disabled={disableSave}
      >
        {saveButtonText ?? 'Save'}
      </CuiProgressButton>
    </>
  )
}
