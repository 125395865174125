import React, {useState} from 'react'
import classNames from 'classnames'
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ClearIcon from '@mui/icons-material/Close'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  StyledFilterButton,
  classes as styledFilterButtonClasses
} from './CuiFilterButton'
import CuiSelectAutoComplete from './CuiSelectAutoComplete'
import CuiTextField from './CuiTextField'

interface CuiFilterProps {
  text: string
  options: any[]
  selectedOptions: any[]
  setSelectedOption: (selectedOptions: any[]) => void
  format?: (option: any) => string
  autoComplete?: boolean
}

export default function CuiFilter({
  text,
  options,
  selectedOptions,
  setSelectedOption,
  format,
  autoComplete
}: CuiFilterProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const [inputValue, setInputValue] = useState('')

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)

  const onClose = () => {
    setAnchorEl(null)
    setInputValue('')
  }

  const getFormattedValue = (value: any) => (format ? format(value) : value)

  const sortOptions = options.sort((a, b) =>
    getFormattedValue(a) > getFormattedValue(b) ? 1 : -1
  )

  const onToggleCheck = (value: any) => () => {
    const currentIndex = value.id
      ? selectedOptions.findIndex(op => op.id === value.id)
      : selectedOptions.indexOf(value)
    const newSelected = [...selectedOptions]

    if (currentIndex === -1) {
      newSelected.push(value)
    } else {
      newSelected.splice(currentIndex, 1)
    }
    setSelectedOption(newSelected)
  }

  const onAllOptionChange = () =>
    setSelectedOption(selectedOptions.length === options.length ? [] : options)

  const isFiltered = selectedOptions.length > 0

  return (
    <>
      <StyledFilterButton
        variant={isFiltered ? 'text' : 'outlined'}
        size="small"
        color={isFiltered ? 'primary' : 'neutral'}
        className={classNames(
          styledFilterButtonClasses.outlinedFilterBtn,
          isFiltered && styledFilterButtonClasses.filteredBtn
        )}
        onClick={onClick}
      >
        {isFiltered && (
          <CloseIcon
            className={styledFilterButtonClasses.filteredTag}
            fontSize="small"
            onClick={event => {
              setSelectedOption([])
              event.stopPropagation()
            }}
          />
        )}
        {text}
        {selectedOptions.length === 1 && (
          <Typography
            variant="body2"
            className={styledFilterButtonClasses.filteredTag}
          >
            {getFormattedValue(selectedOptions[0])}
          </Typography>
        )}
        {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </StyledFilterButton>
      {anchorEl && (
        <Popover
          sx={{maxHeight: '100%', padding: 0}}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 50, horizontal: 'left'}}
          transformOrigin={{vertical: 'top', horizontal: 'left'}}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onClose}
          PaperProps={{sx: {overflowY: autoComplete ? 'hidden' : 'auto'}}}
        >
          <Box display="flex" justifyContent="space-between" px={1}>
            <Box
              fontWeight={500}
              paddingLeft={2}
              paddingTop={1}
              fontSize="20px"
            >
              {text}
            </Box>
            <IconButton aria-label="delete" onClick={onClose} size="large">
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
          {autoComplete ? (
            <Box p={2} minWidth={'20vw'}>
              <CuiSelectAutoComplete
                multiple
                value={selectedOptions}
                getOptionLabel={option => getFormattedValue(option)}
                onChange={(event, selected) => setSelectedOption(selected)}
                options={sortOptions}
                inputValue={inputValue}
                renderInput={params => (
                  <CuiTextField
                    onChange={e => setInputValue(e.target.value)}
                    {...params}
                    sx={{zIndex: theme => theme.zIndex.modal + 1}}
                    variant="outlined"
                    label={text}
                    autoFocus
                  />
                )}
                disableClearable
                disableCloseOnSelect
                format={format}
                addSelectAll
                open
                componentsProps={{
                  popper: {disablePortal: true, placement: 'bottom-start'},
                  paper: {sx: {pt: 0.1}}
                }}
                sx={{minHeight: '47vh'}}
                renderTags={() => null}
              />
            </Box>
          ) : (
            <List
              sx={{
                padding: theme => theme.spacing(2, 2, 0, 2),
                backgroundColor: theme => theme.palette.background.paper,
                maxWidth: '260px'
              }}
            >
              <ListItem key={'all'} dense button onClick={onAllOptionChange}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={options.length === selectedOptions.length}
                    indeterminate={
                      selectedOptions.length > 0 &&
                      selectedOptions.length < options.length
                    }
                    disableRipple
                    color="primary"
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <ListItemText primary={'Select All'} />
              </ListItem>
              <Divider />
              {sortOptions.map(opt => {
                return (
                  <ListItem
                    key={opt.id || opt}
                    dense
                    button
                    onClick={onToggleCheck(opt)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          opt.id
                            ? selectedOptions.findIndex(
                                op => op.id === opt.id
                              ) !== -1
                            : selectedOptions.indexOf(opt) !== -1
                        }
                        disableRipple
                        color="primary"
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    <ListItemText primary={getFormattedValue(opt)} />
                  </ListItem>
                )
              })}
            </List>
          )}
        </Popover>
      )}
    </>
  )
}
