import User from 'src/entities/User'
import BaseWithNameResource, {BaseResource} from 'src/entities/BaseResource'
import Project from 'src/entities/Project'
import {
  DataPointValueStatus,
  ItemDataPoint,
  SectionItemDataPoint
} from 'src/entities/ItemDataPoint'
import {ItemsFiles} from 'src/entities/ItemCheckedFile'
import File from 'src/entities/File'
import {ScopeWithBase} from './Scope'

export enum ItemStatus {
  Unassigned = 1 << 1,
  InProgress = 1 << 2,
  Proofing = 1 << 3,
  Corrections = 1 << 4,
  ClientReady = 1 << 5,
  Update = 1 << 6,
  QCComplete = 1 << 7,
  ReadyToSend = 1 << 8,
  SentToClient = 1 << 9,
  Closed = 1 << 10,
  MC = 1 << 11,
  MCConfirmed = 1 << 12,
  Cancelled = 1 << 13,
  OnHold = 1 << 14,
  NotBilling = 1 << 15,
  Stage1InProgress = 1 << 16,
  Stage1Done = 1 << 17,

  InProcess = InProgress |
    Proofing |
    Corrections |
    ClientReady |
    Update |
    Stage1InProgress |
    Stage1Done,
  AbstractionInProgress = MC |
    InProgress |
    Proofing |
    Corrections |
    ClientReady |
    QCComplete |
    Update |
    ReadyToSend,
  Completed = ReadyToSend | SentToClient | Closed,
  RealyCompleted = SentToClient | Closed,
  N_A = MC | MCConfirmed | Cancelled | OnHold,
  OpenGroup = InProcess | AbstractionInProgress | Unassigned,
  CloseGroup = Completed | N_A | NotBilling,

  'In Progress' = InProgress,
  'Client Ready' = ClientReady,
  'QC Complete' = QCComplete,
  'Ready to Send' = ReadyToSend,
  'Sent to client' = SentToClient,
  'MC Confirmed' = MCConfirmed,
  'On Hold' = OnHold,
  'Not Billing' = NotBilling,
  'In Process' = InProcess,
  'Abstraction In Progress' = AbstractionInProgress,
  'N/A' = N_A,
  'Open Items' = OpenGroup,
  'Close Items' = CloseGroup,
  'Stage 1-In Progress' = Stage1InProgress,
  'Stage 1-Done' = Stage1Done
}

export enum MCIssueStatus {
  None = 0,
  New = 1,
  Approved = 2,
  OnSyncData = 3,
  MCSentToClient = 4,
  ClientResponse = 5,
  Resolved = 6,
  'MC Sent To Client' = MCSentToClient,
  'On Sync Data' = OnSyncData,
  'Client Response' = ClientResponse
}

export enum ItemHighTimeReasoning {
  None = 0,
  NotCopyable = 1,
  MajorTenant = 2,
  MultipleSuites = 3,
  MultipleAmendments = 4,
  NewLease = 5,
  LengthyLease = 6,
  UnfamiliarClientForm = 7,
  Other = 8,
  NoExplanationRequired = 9,
  '1. Not copyable' = NotCopyable,
  '2. Major Tenant' = MajorTenant,
  '3. Multiple Suites' = MultipleSuites,
  '4. Multiple Amendments' = MultipleAmendments,
  '5. New Lease (Not commenced yet)' = NewLease,
  '6. Lengthy Lease' = LengthyLease,
  '7. Unfamiliar Client Form' = UnfamiliarClientForm,
  '8. Other' = Other,
  '9. No Explanation Required' = NoExplanationRequired
}

export enum ReopenStatusReasoning {
  None = 0,
  AdditionalDocuments = 1,
  AbstractError = 2,
  'Error in Abstract' = AbstractError,
  'Additional Documents' = AdditionalDocuments
}

export enum ItemTypeGroupId {
  LP = 1,
  RD,
  LPRD
}

export enum Form {
  None = 0,
  Office,
  Retail,
  MajorTenant,
  Government,
  License,
  Easement,
  NY,
  GroundLease,
  MgmtAgmt,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
  N,
  O,
  P,
  Q,
  R,
  S,
  T,
  U,
  V,
  W,
  X,
  Y,
  Z,
  Unique,
  'Major Tenant' = MajorTenant,
  'Ground Lease' = GroundLease,
  'Mgmt Agmt' = MgmtAgmt
}

export enum ItemResponsibleParty {
  NA = 0,
  DLC,
  LP
}

export interface ItemTypeGroup extends BaseWithNameResource {}

export interface ItemType extends BaseWithNameResource {
  group: ItemTypeGroup
  code: string
  tagColor: string
  average: number
  rateType: RateType
  isHourly: boolean
  isGlobal: boolean
  isSubType: boolean
  isCounted4Item: boolean
  isUpdate: boolean
  isManualDeliverables: boolean
  isSpecialSubType: boolean
  isGeneralProjectTimes: boolean
  isHideTimer: boolean
  isCamPools: boolean
  descriptions: ItemTypeDescription[]
}

export interface ItemTypeDescription {
  id: number
  description: string
}

export enum RateType {
  None = 0,
  Abstract = 1,
  Amendment = 2,
  Hour = 3,
  Entry = 4,
  Tenant = 5
}

export default interface Item extends BaseResource {
  project: Project
  projectId: number
  status: ItemStatus
  type?: ItemType
  typeId: number
  form: Form
  scopeId: number
  scope: ScopeWithBase
  tenant: string
  lastFile: File
  fof: FOF
  isFOF: boolean
  isFOFBase: boolean
  isLocked: boolean
  sectionItemDataPoints: SectionItemDataPoint[]
  checkedFiles: ItemsFiles[]
  doubleCheck: boolean
  buzzWordSearch: boolean
  abstractRead: boolean
  mcIssueStatus?: MCIssueStatus
  mcIssue: string
  mcIssueLastRevised: Date
  lastRevised: Date
  notes: string
  highTimeReasoning: ItemHighTimeReasoning
  exceedingAverageHours: string
  docsInLeaseFile: number
  timeLogged: string
  totalLoggedTime: Date
  totalLoggedTimeInHours: number
  totalLoggedTimeInMinutes: number
  clientResponse: string
  highHoursReasoning: string
  abstractor: User
  abstractorId: number
  proofer: User
  prooferId: number
  propertyName: string
  buildingName: string
  specialRate?: number
  dateSentToClient?: Date
  isExtraPaid: boolean
  invoiceId: number
  amount: number
  lpid: string
  updatedAt: Date
  priority: number
  Receivable: string
  itemDataPoints: ItemDataPoint[]
  deliverables: number
  itemAverage: number

  //DD Columns
  UsersOfDDClientDirectors: BaseResource[]
  UsersOfDDProjectManagers: BaseResource[]
  DDExpenseSupervisor: BaseResource[]
  DDReimbursementSupervisor: BaseResource[]
  DDNotes: string

  //Special Columns
  dateRecieved: Date
  siteNum: string
  responsibleParty: ItemResponsibleParty
  deliverableBoxURL: string
  dueDate: Date
  canceleStatusNote: string
  reopenStatusReason: string

  //project Column
  projectCode: string
  projectName: string
  lLpManagerId: number
  isOngoing: boolean
  DDProjectInstruction: string
  directory: File
  documentOrigination: string
  isReportAmendment: boolean
  invoiceNumber: string
  isSyncCP: boolean
}

export interface ReopenReason {
  reopenStatusReasoning: ReopenStatusReasoning
  reopenOn: Date
}

export interface FOF extends BaseResource {
  lastFile: File
}
export function getStatusText(status: number) {
  return ItemStatus[status]
}

export function getSimpleStatuses() {
  return Object.keys(ItemStatus).reduce((list, key) => {
    const keyNumber = Number(key)
    if (!isNaN(keyNumber) && (keyNumber & (keyNumber - 1)) === 0)
      list.push(keyNumber as ItemStatus)
    return list
  }, [] as ItemStatus[])
}

export const isStandartLPType = (type: ItemType) =>
  type.group?.id === ItemTypeGroupId.LP &&
  !type.isUpdate &&
  !type.isSubType &&
  !type.isSpecialSubType &&
  !type.isGlobal

export function isDisabledExportByDataPoint(itemDataPoints: ItemDataPoint[]) {
  const a = itemDataPoints?.filter(
    x => x.status === DataPointValueStatus.CopyFromDocumentWithChanges
  )
  return !!a?.length
}
