import React, {useCallback, useState, useEffect} from 'react'
import {Button, Stack} from '@mui/material'
import {GridFilterModel} from '@mui/x-data-grid-pro'
import CuiFilterDate, {
  FilterDateEnum
} from 'src/components/custom/CuiFilterDate'
import {
  changeFilterData,
  getFilterModel
} from 'src/components/custom/dataGrid/CuiExternalFilter'
import {InvoiceGridColumn} from './InvoiceTable'
import SearchIcon from '@mui/icons-material/Search'
import {useAuth} from 'src/context/Auth'
import {BaseProjectResource, ProjectStatus} from 'src/entities/Project'
import config from 'src/config'
import CuiFilter from 'src/components/custom/CuiFilter'
import {BaseClientResource} from 'src/entities/Client'
import CuiTextField from 'src/components/custom/CuiTextField'
import {projectStatusCompleted} from 'src/entities/Invoice'
import {getEnumOption} from 'src/utils/enumHelper'

export interface InvoiceTableFilterProps {
  filterModel: GridFilterModel
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>
  projectId?: number
}

export default function InvoiceTableFilter({
  filterModel,
  setFilterModel,
  projectId
}: InvoiceTableFilterProps) {
  const [clients, setClients] = useState<BaseClientResource[]>([])
  const [projects, setProjects] = useState<BaseProjectResource[]>([])

  const {fetchWithUser} = useAuth()

  const onChangeFilterModel = useCallback(
    (columnField: string, value: any, operatorValue: string = 'isAnyOf') => {
      setFilterModel(p => ({
        ...p,
        items: changeFilterData(p.items, columnField, value, operatorValue)
      }))
    },
    [setFilterModel]
  )

  const setDates = useCallback(
    (value: (Date | FilterDateEnum | null)[]) => {
      onChangeFilterModel(InvoiceGridColumn.CreatedAt, value)
    },
    [onChangeFilterModel]
  )

  const setPaidDates = useCallback(
    (value: (Date | FilterDateEnum | null)[]) => {
      onChangeFilterModel(InvoiceGridColumn.PaidAt, value)
    },
    [onChangeFilterModel]
  )
  useEffect(() => {
    const localStorageValue = localStorage
      .getItem('invoiceProjectsFilter')
      ?.split(',')
      .map(x => Number(x))

    if (localStorageValue && projects.length > 0) {
      localStorage.removeItem('invoiceProjectsFilter')
      setFilterModel(p => ({
        ...p,
        items: changeFilterData(
          p.items,
          InvoiceGridColumn.Project,
          localStorageValue.map(x => projects?.find(p => p.id === x)),
          'isAnyOf'
        )
      }))
    }
  }, [projects, setFilterModel])

  useEffect(() => {
    if (!projectId) {
      fetchWithUser(config.apiUrl + '/clients/base')
        .then(response => response.json())
        .then(data => setClients(data))

      fetchWithUser(config.apiUrl + '/projects/base')
        .then(response => response.json())
        .then(data => setProjects(data))
    }
  }, [fetchWithUser, projectId])

  return (
    <Stack
      direction="row"
      spacing={0}
      flexWrap="wrap"
      justifyContent="flex-end"
    >
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={
              getFilterModel(filterModel, InvoiceGridColumn.IsDeleted, 'is')
                ?.value
            }
            onChange={e => {
              onChangeFilterModel(
                InvoiceGridColumn.IsDeleted,
                e.target.checked,
                'is'
              )
            }}
            size="small"
          />
        }
        label="Display Deleted Invoices"
      /> */}
      <Button
        onClick={() => {
          setFilterModel(p => ({
            ...p,
            items: p.items.filter(
              x => x.columnField === InvoiceGridColumn.IsDeleted
            )
          }))
        }}
      >
        Clear All
      </Button>
      {!projectId && (
        <>
          <CuiFilter
            autoComplete
            text="Client"
            options={clients}
            selectedOptions={
              getFilterModel(filterModel, InvoiceGridColumn.Client)?.value || []
            }
            setSelectedOption={selected =>
              onChangeFilterModel(InvoiceGridColumn.Client, selected)
            }
            format={(o: BaseClientResource) => o.code + ' - ' + o.name}
          />
          <CuiFilter
            autoComplete
            text="Project"
            options={projects}
            selectedOptions={
              getFilterModel(filterModel, InvoiceGridColumn.Project)?.value ||
              []
            }
            setSelectedOption={selected =>
              onChangeFilterModel(InvoiceGridColumn.Project, selected)
            }
            format={(p: BaseProjectResource) => p.code + ' - ' + p.name}
          />
          <CuiFilter
            text="Project Status"
            options={getEnumOption(ProjectStatus, projectStatusCompleted)}
            selectedOptions={
              getFilterModel(filterModel, InvoiceGridColumn.ProjectStatus)
                ?.value || []
            }
            setSelectedOption={selected =>
              onChangeFilterModel(InvoiceGridColumn.ProjectStatus, selected)
            }
            format={(o: ProjectStatus) => ProjectStatus[o]}
          />
        </>
      )}
      <CuiFilterDate
        label={'Date Created'}
        selectedValue={
          getFilterModel(filterModel, InvoiceGridColumn.CreatedAt)
            ?.value?.[2] || null
        }
        dateFromFilter={
          getFilterModel(filterModel, InvoiceGridColumn.CreatedAt)
            ?.value?.[0] || null
        }
        dateToFilter={
          getFilterModel(filterModel, InvoiceGridColumn.CreatedAt)
            ?.value?.[1] || null
        }
        setDatesFilter={setDates}
      />
      <CuiFilterDate
        label={'Date Paid'}
        selectedValue={
          getFilterModel(filterModel, InvoiceGridColumn.PaidAt)?.value?.[2] ||
          null
        }
        dateFromFilter={
          getFilterModel(filterModel, InvoiceGridColumn.PaidAt)?.value?.[0] ||
          null
        }
        dateToFilter={
          getFilterModel(filterModel, InvoiceGridColumn.PaidAt)?.value?.[1] ||
          null
        }
        setDatesFilter={setPaidDates}
      />
      <CuiTextField
        value={
          getFilterModel(
            filterModel,
            InvoiceGridColumn.invoiceNumber,
            'quickSearch'
          )?.value || ''
        }
        onChange={e => {
          onChangeFilterModel(
            InvoiceGridColumn.invoiceNumber,
            e.target.value,
            'quickSearch'
          )
        }}
        placeholder="Invoice number"
        variant="standard"
        size="small"
        InputProps={{
          startAdornment: <SearchIcon color="disabled" />
        }}
        sx={{pt: 0.3}}
      ></CuiTextField>
    </Stack>
  )
}
