import React, {useState, useEffect} from 'react'
import TimeTracking from 'src/entities/TimeTracking'
import CuiTimer from 'src/components/custom/CuiTimer'
import ItemTimerStartModal from 'src/components/item/timeTracking/ItemTimerStartModal'
import ItemTimerViewModal from 'src/components/item/timeTracking/ItemTimerViewModal'
import {useAuth} from 'src/context/Auth'
import config from 'src/config'
import {getLocalDateFromUtc} from 'src/utils/formatDate'
import TimeTrackingActive from 'src/entities/TimeTrackingActive'
import ItemTimerStopModal from './ItemTimerStopModal'

interface ItemTimerTrackingProps {
  activeTimer: TimeTrackingActive | null
  hideTimer?: boolean
  setActiveTimer: (activeTimer: TimeTrackingActive | null) => void
  itemId?: number
  disabled: boolean
  itemLpid: string
}

export enum TimerModalStatus {
  None = 0,
  Start = 1,
  Stop = 2,
  Edit = 3,
  View = 4,
  Delete = 5,
  Create = 5
}

export default function ItemTimerTracking({
  itemId,
  activeTimer,
  hideTimer = false,
  setActiveTimer,
  disabled,
  itemLpid
}: ItemTimerTrackingProps) {
  const {fetchWithUser} = useAuth()
  const [timeTrackingData, setTimeTrackingData] = useState({} as TimeTracking)
  const [open, setOpen] = useState<TimerModalStatus>(TimerModalStatus.None)

  const onStartOpen = () => {
    setOpen(TimerModalStatus.Start)
  }

  const onViewOpen = () => {
    setOpen(TimerModalStatus.View)
  }

  const onStart = (timeTracking: TimeTracking) => {
    setActiveTimer(timeTracking)
  }

  const onStop = () => {
    setOpen(TimerModalStatus.Stop)
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        ...timeTrackingData,
        offsetTime: getLocalDateFromUtc(
          timeTrackingData.startTime
        ).getTimezoneOffset()
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    setActiveTimer({...activeTimer, stopping: true} as TimeTrackingActive)
    fetchWithUser(config.apiUrl + '/timetracking/stop', options)
      .then(response => response.json())
      .then(() => {
        setActiveTimer(null)
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.')
      })
      .finally(() => {
        setOpen(TimerModalStatus.None)
      })
  }

  useEffect(() => {
    activeTimer
      ? setTimeTrackingData(activeTimer)
      : setTimeTrackingData({itemId: itemId} as TimeTracking)
  }, [activeTimer, itemId])

  const isActive = () => {
    return timeTrackingData?.startTime && !timeTrackingData?.endTime
  }

  return (
    <>
      <CuiTimer
        onStart={onStartOpen}
        onStop={onStop}
        onTimeClick={onViewOpen}
        disabled={disabled}
        hideTimer={hideTimer}
        hideTimerText={
          'This item is locked, Please contact Admin to create a new ticket'
        }
        disabledText={"You can't work on two tasks in parallel"}
        running={isActive()}
        initializedValue={(isActive() && timeTrackingData.duration) || 0}
        isStopping={activeTimer?.stopping}
      />
      {open === TimerModalStatus.Start && (
        <ItemTimerStartModal
          open={open === TimerModalStatus.Start}
          setOpen={setOpen}
          timeTrackingData={timeTrackingData}
          setTimeTrackingData={setTimeTrackingData}
          onStart={onStart}
          itemLpid={itemLpid}
        />
      )}
      {open === TimerModalStatus.Stop && (
        <ItemTimerStopModal
          open={open === TimerModalStatus.Stop}
          setOpen={setOpen}
          timeTrackingData={timeTrackingData}
          itemLpid={itemLpid}
        />
      )}
      {open === TimerModalStatus.View && (
        <ItemTimerViewModal
          open={open === TimerModalStatus.View}
          setOpen={setOpen}
          itemId={timeTrackingData.itemId}
          itemLpid={itemLpid}
          activeTimerId={activeTimer?.id}
          hideTimer={hideTimer}
          setActiveTimer={setActiveTimer}
        />
      )}
    </>
  )
}
