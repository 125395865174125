import {useDebouncedCallback} from 'use-debounce'
import {useCallback, useEffect, useReducer, useRef} from 'react'
import {useAuth} from 'src/context/Auth'
import {SaveType, useAutosaveContext} from 'src/context/Autosave'

const DEBOUNCE_SAVE_DELAY_MS = 2000

interface useAutosaveProps<T> {
  dataToSave: T
  delayToSaveInMs?: number
  type: SaveType
  id: number
  url: string
  method?: string
  mapData?: (dataToSave: T) => any
  onSucceeded?: (response: any) => void
  onPartialSucceeded?: (response: any) => void
  onFailed?: () => void
  ignoreChanges?: boolean
  dataIsInValid?: boolean
}

const getId = (type: SaveType, id: number) => `${type}:${id}`

export enum ActionType {
  StartEditing,
  StartSending,
  NeedToSendAgainNow,
  NeedToSendAgain,
  EndSending,
  EndSendAgain,
  EndSendAgainNow,
  GetResponse
}
interface State {
  isPending: boolean
  isWaiting: boolean
  isSending: boolean
  sendAgain: boolean
  sendAgainNow: boolean
  response: any
  isSendFailed: boolean
  isSendSucsses: boolean
}
type Action =
  | {type: ActionType.StartEditing}
  | {type: ActionType.StartSending}
  | {type: ActionType.NeedToSendAgainNow}
  | {type: ActionType.NeedToSendAgain}
  | {type: ActionType.GetResponse; isSucses: boolean; respone: any}
  | {type: ActionType.EndSending; isProcessFinish: boolean}
  | {type: ActionType.EndSendAgain}
  | {type: ActionType.EndSendAgainNow}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.StartEditing:
      return {...state, isWaiting: true, isPending: true}
    case ActionType.StartSending:
      return {...state, isSending: true, isWaiting: false}
    case ActionType.EndSending:
      return {
        ...state,
        isPending: !action.isProcessFinish,
        isSending: false,
        isSendSucsses: false,
        isSendFailed: false,
        response: null
      }
    case ActionType.GetResponse:
      return {
        ...state,
        isSendFailed: !action.isSucses,
        isSendSucsses: action.isSucses,
        response: action.respone
      }
    case ActionType.EndSendAgain:
      return {...state, sendAgain: false}
    case ActionType.EndSendAgainNow:
      return {...state, sendAgainNow: false}
    case ActionType.NeedToSendAgainNow:
      return {
        ...state,
        sendAgainNow: true,
        sendAgain: false,
        isSendFailed: false,
        isSendSucsses: false
      }
    case ActionType.NeedToSendAgain:
      return {
        ...state,
        sendAgain: true,
        sendAgainNow: false,
        isSending: false,
        isSendFailed: false,
        isSendSucsses: false
      }
    default:
      throw new Error()
  }
}

const initialState: State = {
  isPending: false,
  isWaiting: false,
  isSending: false,
  sendAgain: false,
  sendAgainNow: false,
  response: null,
  isSendFailed: false,
  isSendSucsses: false
}

export default function useAutosave<T>({
  dataToSave,
  url,
  method = 'PUT',
  mapData,
  onSucceeded,
  onPartialSucceeded,
  onFailed,
  ignoreChanges,
  dataIsInValid,
  type,
  id,
  delayToSaveInMs
}: useAutosaveProps<T>): [boolean] {
  const didMountRef = useRef(false)
  const [
    {
      isWaiting,
      isPending,
      isSending,
      sendAgain,
      isSendSucsses,
      isSendFailed,
      sendAgainNow,
      response
    },
    dispatch
  ] = useReducer(reducer, {
    ...initialState
  })
  const {fetchWithUser} = useAuth()
  const {addProcess, endProcess, failedProcess, saveNow} = useAutosaveContext()

  const debounced = useDebouncedCallback(() => {
    send()
  }, delayToSaveInMs || DEBOUNCE_SAVE_DELAY_MS)

  const endSending = useCallback(() => {
    console.log(id + '--->EndSending, isProcessFinish: true')
    dispatch({type: ActionType.EndSending, isProcessFinish: true})
    endProcess?.(getId(type, id))
  }, [type, endProcess, id])

  const send = useCallback(() => {
    if (dataIsInValid) {
      // or if data is not valid
      console.log(id + ' if (dataIsInValid)')
      endSending()
      return
    }
    if (isSending) {
      console.log(id + ' if (isSending)')
      dispatch({type: ActionType.NeedToSendAgainNow})
      return
    }
    dispatch({type: ActionType.StartSending})
    console.log('setIsSending(true)')
    const data = mapData ? mapData(dataToSave) : dataToSave
    console.log('fetch' + id)
    fetchWithUser(url, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    })
      .then(response => {
        response.json().then(response => {
          dispatch({
            type: ActionType.GetResponse,
            isSucses: true,
            respone: response
          })
          console.log('response sucsses' + id)
        })
      })
      .catch(() => {
        console.log('response failed' + id)
        dispatch({type: ActionType.GetResponse, isSucses: false, respone: null})
      })
  }, [
    fetchWithUser,
    url,
    dataToSave,
    method,
    mapData,
    id,
    isSending,
    dataIsInValid,
    endSending
  ])

  const unloadCallback = useCallback(
    (event: BeforeUnloadEvent) => {
      if (debounced.isPending()) {
        debounced.flush()
      }
      console.log('auto save unloadCallback')
      event.returnValue = ''
      event.preventDefault()
      return ''
    },
    [debounced]
  )

  useEffect(() => {
    if (isSendSucsses && response) {
      if (isWaiting || sendAgainNow) {
        console.log(
          'onPartialSucceeded ' +
            id +
            'sendAgainNow ' +
            sendAgainNow +
            ' ActionType.EndSending'
        )
        onPartialSucceeded?.(response)
        dispatch({type: ActionType.EndSending, isProcessFinish: false})
      } else {
        console.log(
          'onSucceeded ' +
            id +
            ' ActionType.EndSending dataIsInValid:' +
            dataIsInValid
        )
        if (dataIsInValid) onPartialSucceeded?.(response)
        else onSucceeded?.(response)
        endSending()
      }
    }
  }, [
    response,
    endSending,
    id,
    onPartialSucceeded,
    onSucceeded,
    sendAgainNow,
    isWaiting,
    isSendSucsses,
    dataIsInValid
  ])

  useEffect(() => {
    if (isSendFailed) {
      if (isWaiting || sendAgainNow) {
        dispatch({type: ActionType.EndSending, isProcessFinish: false})
        console.log(
          'response failed ' +
            id +
            'sendAgainNow ' +
            sendAgainNow +
            ' ActionType.EndSending'
        )
      } else {
        console.log('response failed ' + id + ' ActionType.NeedToSendAgain')
        onFailed?.()
        failedProcess?.(getId(type, id))
        dispatch({type: ActionType.NeedToSendAgain})
      }
    }
  }, [isSendFailed, isWaiting, sendAgainNow, type, id, failedProcess, onFailed])

  //When the page goes to be unloaded,
  //we will fetch data if the data has changed.
  useEffect(() => {
    if (debounced.isPending() || isPending) {
      window.addEventListener('beforeunload', unloadCallback)
    }
    return () => {
      window.removeEventListener('beforeunload', unloadCallback)
    }
  }, [unloadCallback, debounced, isPending])

  //When the component goes to be unmounted ,
  //we will fetch data if the data has changed.
  useEffect(() => {
    return () => {
      debounced.flush()
    }
  }, [debounced])

  useEffect(() => {
    if (sendAgain && !isSending) {
      dispatch({type: ActionType.EndSendAgain})
      setTimeout(() => {
        console.log('sendAgain setTimeout')
        send()
      }, 2000)
    }
  }, [sendAgain, isSending, send])

  useEffect(() => {
    if (sendAgainNow && !isSending) {
      dispatch({type: ActionType.EndSendAgainNow})
      send()
    }
  }, [sendAgain, sendAgainNow, isSending, send])

  //When we froce save, we will fetch data if the data has changed.
  useEffect(() => {
    if (saveNow) {
      debounced.flush()
    }
  }, [saveNow, debounced])

  //support script mode in react 18
  useEffect(() => {
    return () => {
      didMountRef.current = false
    }
  }, [])

  useEffect(() => {
    // skip initial render
    if (!didMountRef.current) {
      didMountRef.current = true
    } else {
      if (!ignoreChanges) {
        dispatch({type: ActionType.StartEditing})
        debounced()
      }
    }
  }, [dataToSave, debounced, ignoreChanges])

  useEffect(() => {
    isPending && addProcess && addProcess(type, getId(type, id))
  }, [isPending, addProcess, type, id])

  return [isPending]
}
