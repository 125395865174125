import React from 'react'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import ListAltIcon from '@mui/icons-material/ListAlt'
import BusinessIcon from '@mui/icons-material/Business'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import {ReactComponent as MyTasksIcon} from 'src/images/items/MyTasks.svg'
import {ReactComponent as ProjectsIcon} from 'src/images/projects/Projects.svg'
import {ReactComponent as OCRTrackerIcon} from 'src/images/OCRTracker.svg'
import {ReactComponent as WhatsNew} from 'src/images/WhatsNew.svg'
import {ReactComponent as ItemListIcon} from 'src/images/ItemList.svg'
import {PageType} from 'src/entities/Page'
import {SvgIconTypeMap, Tooltip} from '@mui/material'

export type CuiMenuIcon = SvgIconTypeMap['props'] & {
  page: PageType
  addToolTip?: boolean
  customColor?: string
}

function PageIcon({page, customColor, ...prop}: CuiMenuIcon) {
  let props = {...prop} as {}
  if (customColor) {
    props = {...props, opacity: 1, fill: customColor}
  }
  switch (page) {
    case PageType.Scopes: {
      return <ListAltIcon {...props} htmlColor={customColor} />
    }
    case PageType.ItemList:
    case PageType.ItemListV2: {
      return <ItemListIcon {...props} />
    }
    case PageType.MyTasks: {
      return <MyTasksIcon {...props} />
    }
    case PageType.Workspace: {
      return <MyTasksIcon {...props} />
    }
    case PageType.Documents: {
      return <FileCopyIcon {...props} htmlColor={customColor} />
    }
    case PageType.OcrTracker: {
      return <OCRTrackerIcon {...props} />
    }
    case PageType.Client: {
      return <AccountCircleOutlinedIcon {...props} htmlColor={customColor} />
    }
    case PageType.ProjectList:
    case PageType.ProjectDashboard: {
      return <ProjectsIcon {...props} fill={customColor} />
    }
    case PageType.DDReport:
    case PageType.PropertyReport:
    case PageType.ManagementReports: {
      return <BusinessIcon {...props} htmlColor={customColor} />
    }
    case PageType.WhatsNew: {
      return <WhatsNew {...props} />
    }
    case PageType.adminIssue: {
      return <QuestionAnswerOutlinedIcon {...props} />
    }
    default: {
      return <ListAltIcon {...props} htmlColor={customColor} />
    }
  }
}

export default function MenueIcon({addToolTip, ...props}: CuiMenuIcon) {
  return addToolTip ? (
    <Tooltip title={PageType[props.page]}>
      <div>
        <PageIcon {...props} />
      </div>
    </Tooltip>
  ) : (
    <PageIcon {...props} />
  )
}
