import React from 'react'
import {ButtonProps, Box, Button, CircularProgress} from '@mui/material'

type CuiProgressButtonProps = ButtonProps & {
  loading?: boolean
  error?: boolean
}

export default function CuiProgressButton({
  loading,
  error,
  ...props
}: CuiProgressButtonProps) {
  return (
    <Box sx={{position: 'relative'}}>
      <Button disableElevation disabled={loading} {...props}></Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -1.5,
            marginLeft: -1.5
          }}
        />
      )}
    </Box>
  )
}
