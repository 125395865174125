import {GridPreProcessEditCellProps} from '@mui/x-data-grid-pro'

export const isSameValue = (
  params: GridPreProcessEditCellProps,
  fieldId: string,
  checkZeroValue: boolean = false
) =>
  params.props.value === params.row[fieldId] ||
  (checkZeroValue && !params.props.value && !params.row[fieldId])

export const customPreProcessEditCellProps = (
  fieldId: string,
  checkZeroValue: boolean = false
) => ({
  preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
    const sameValue = isSameValue(params, fieldId, checkZeroValue)
    return {...params.props, error: sameValue}
  }
})
