import React from 'react'
import {Grid} from '@mui/material'
import TimeTracking, {isBillable} from 'src/entities/TimeTracking'
import {TimerModalStatus} from 'src/components/item/timeTracking/ItemTimerTracking'
import ItemTimeModal from 'src/components/item/timeTracking/ItemTimerModal'
import CuiLoading from 'src/components/custom/CuiLoading'

interface ItemTimerStopModalProps {
  open: boolean
  setOpen: (open: TimerModalStatus) => void
  timeTrackingData: TimeTracking
  itemLpid?: string
}

export default function ItemTimerStopModal({
  open,
  setOpen,
  timeTrackingData,
  itemLpid
}: ItemTimerStopModalProps) {
  return (
    <ItemTimeModal
      open={open}
      setOpen={setOpen}
      isBillable={isBillable(timeTrackingData.itemId)}
      dialogTitle={`Stop Session ${itemLpid}`}
      disableBackdropClick={true}
    >
      <Grid item>
        <CuiLoading message="Saving data" />
      </Grid>
    </ItemTimeModal>
  )
}
