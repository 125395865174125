import {useState, useCallback, useEffect} from 'react'

const getFromLocalStorage = (key: string, initialValue: any) => {
  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  } catch (error) {
    return initialValue
  }
}

export function useLocalStorage(key: string, initialValue: any) {
  const [storedValue, setStoredValue] = useState(() =>
    getFromLocalStorage(key, initialValue)
  )
  const [initialValueState] = useState(initialValue)

  const setValue = useCallback(
    (value: any) => {
      try {
        setStoredValue((prevValue: any) => {
          const valueToStore =
            value instanceof Function ? value(prevValue) : value
          window.localStorage.setItem(key, JSON.stringify(valueToStore))
          return valueToStore
        })
      } catch (error) {}
    },
    [key]
  )

  useEffect(() => {
    setStoredValue(() => {
      return getFromLocalStorage(key, initialValueState)
    })
  }, [key, initialValueState])

  return [storedValue, setValue]
}
