import React, {useState, useEffect} from 'react'
import {Grid} from '@mui/material'
import {Autocomplete} from '@mui/material'
import TimeTracking, {
  isBillable,
  getTypeOptions,
  getTypeText,
  onDateValidation,
  TimeTrackingValidaion,
  onEndTimeValidation,
  onStartTimeValidation
} from 'src/entities/TimeTracking'
import {useAuth} from 'src/context/Auth'
import config from 'src/config'
import {TimerModalStatus} from 'src/components/item/timeTracking/ItemTimerTracking'
import CuiTextField from 'src/components/custom/CuiTextField'
import CuiDatePicker from 'src/components/custom/CuiDatePicker'
import ItemTimeModal from 'src/components/item/timeTracking/ItemTimerModal'
import {
  getLocalDateFromUtc,
  getDateTimeCombinedWithDate
} from 'src/utils/formatDate'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import User, {UserRole} from 'src/entities/User'
import {TimePicker} from '@mui/x-date-pickers-pro'

interface ItemTimerFormModalProps {
  open: TimerModalStatus
  setOpen: (open: TimerModalStatus) => void
  timeTrackingData: TimeTracking
  onSave: (timeTracking: TimeTracking) => void
  itemLpid: string
}

export default function ItemTimerFormModal({
  open,
  setOpen,
  timeTrackingData,
  onSave,
  itemLpid
}: ItemTimerFormModalProps) {
  const {fetchWithUser, roles} = useAuth()
  const [loading, setLoading] = useState(false)
  const [timeTracking, setTimeTracking] = useState({
    ...timeTrackingData,
    startTime:
      timeTrackingData.startTime &&
      getLocalDateFromUtc(timeTrackingData.startTime),
    endTime:
      timeTrackingData.endTime && getLocalDateFromUtc(timeTrackingData.endTime)
  })
  const [errorStartTime, setErrorStartTime] = useState('')
  const [errorEndTime, setErrorEndTime] = useState('')
  const [errorEntryDate, setErrorEntryDate] = useState('')
  const [errorType, setErrorType] = useState('')
  const [errorUser, setErrorUser] = useState('')
  const [disableUpdate, setDisableUpdate] = useState(false)
  const isNew = () => open === TimerModalStatus.Create
  const [entryDate, setEntryDate] = useState(
    isNew() ? new Date() : timeTracking.startTime
  )
  const [users, setUsers] = useState<User[]>([])

  const onSubmitValidation = () => {
    if (!timeTracking.startTime) {
      setErrorStartTime('Start time is required!')
      return false
    }
    if (!timeTracking.endTime) {
      setErrorEndTime('End time is required!')
      return false
    }
    if (!timeTracking.timeType) {
      setErrorType('Type is required!')
      return false
    }
    if (errorStartTime || errorEndTime || errorEntryDate) return false
    setErrorStartTime('')
    setErrorEndTime('')
    setErrorEntryDate('')
    setErrorType('')
    return true
  }

  const onSaveClick = () => {
    if (!onSubmitValidation()) return
    setLoading(true)

    const options = {
      method: 'PUT',
      body: JSON.stringify({
        ...timeTracking
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    fetchWithUser(config.apiUrl + '/timetracking', options)
      .then(response => response.json())
      .then((data: TimeTracking) => {
        onSave(data)
        setLoading(false)
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.')
        setLoading(false)
      })
  }

  const resetErrorMessages = () => {
    setErrorEntryDate('')
    setErrorStartTime('')
    setErrorEndTime('')
  }

  const onChangeUser = (newValue: any) => {
    resetErrorMessages()
    newValue &&
      setTimeTracking({
        ...timeTracking,
        user: newValue,
        userId: newValue.id
      })
    setErrorUser('')
    timeTracking.startTime &&
      timeTracking.endTime &&
      onOverlappingValidation(
        new Date(timeTracking.startTime),
        new Date(timeTracking.endTime),
        newValue.id
      )
  }

  const onChangeDate = (date: Date | null) => {
    date && setEntryDate(date)
    resetErrorMessages()

    if (date && !isNaN(date.getTime())) {
      const start =
        timeTracking.startTime &&
        getDateTimeCombinedWithDate(date, timeTracking.startTime)

      const end =
        timeTracking.endTime &&
        getDateTimeCombinedWithDate(date, timeTracking.endTime)

      const validation = onDateValidation(date, start, end)
      if (!validation.isValid) {
        setValidationErrors(validation)
      }
      if ((start || end) && validation.isValid) {
        onOverlappingValidation(start, end)
        setTimeTracking(prev => ({
          ...prev,
          startTime: start,
          endTime: end
        }))
      }
    } else {
      date
        ? setErrorEntryDate('Invalid date format!')
        : setErrorEntryDate('Date is required!')
    }
  }

  const setValidationErrors = (
    timeTrackingValidaion: TimeTrackingValidaion
  ) => {
    timeTrackingValidaion.entryDateErrorMessage &&
      setErrorEntryDate(timeTrackingValidaion.entryDateErrorMessage)
    timeTrackingValidaion.startTimeErrorMessage &&
      setErrorStartTime(timeTrackingValidaion.startTimeErrorMessage)
    timeTrackingValidaion.endTimeErrorMessage &&
      setErrorEndTime(timeTrackingValidaion.endTimeErrorMessage)
  }

  const onValidation = (startTime: Date | null, endTime: Date | null) => {
    let startValidation = onStartTimeValidation(startTime, endTime && endTime)
    let endValidation = onEndTimeValidation(startTime, endTime && endTime)
    if (!startValidation.isValid) {
      setValidationErrors(startValidation)
      return false
    }
    if (!endValidation.isValid) {
      setValidationErrors(endValidation)
      return false
    }
    return true
  }

  const onChangeStartTime = (dateValue: unknown) => {
    let date: Date | null = dateValue instanceof Date ? dateValue : null
    date &&
      setTimeTracking(prev => ({
        ...prev,
        startTime: date || new Date()
      }))
    resetErrorMessages()

    if (date && !isNaN(date.getTime())) {
      date = getDateTimeCombinedWithDate(entryDate, date)
      if (
        onValidation(
          date,
          timeTracking.endTime && new Date(timeTracking.endTime)
        )
      )
        onOverlappingValidation(
          date,
          timeTracking.endTime && new Date(timeTracking.endTime)
        )
    } else {
      date
        ? setErrorStartTime('Invalid time format!')
        : setErrorStartTime('Start time is required!')
    }
  }

  const onChangeEndTime = (dateValue: unknown) => {
    let date: Date | null = dateValue instanceof Date ? dateValue : null

    setTimeTracking(prev => ({
      ...prev,
      endTime: date
    }))
    resetErrorMessages()

    if (date && !isNaN(date.getTime())) {
      date = getDateTimeCombinedWithDate(entryDate, date)
      if (onValidation(new Date(timeTracking.startTime), date))
        onOverlappingValidation(new Date(timeTracking.startTime), date)
    } else {
      date
        ? setErrorEndTime('Invalid time format!')
        : setErrorEndTime('End time is required!')
    }
  }

  const onOverlappingValidation = (
    startTime: Date | null,
    endTime: Date | null,
    userId?: number
  ) => {
    if (!startTime || !endTime) return
    setDisableUpdate(true)
    const options = {
      method: 'POST',
      body: JSON.stringify({
        ...timeTracking,
        userId: userId || timeTracking.userId,
        startTime: startTime,
        endTime: endTime
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    fetchWithUser(config.apiUrl + '/timetracking/validate-overlaping', options)
      .then(response => response.json())
      .then((data: boolean) => {
        setDisableUpdate(false)
        if (!data) {
          setErrorEndTime('Overlapping Times – Please amend to continue')
          return false
        }
        return true
      })
      .catch(() => {
        setDisableUpdate(false)
        alert('Something went wrong. Please try again later.')
      })
  }

  useEffect(() => {
    fetchWithUser(
      `${config.apiUrl}/users/UserByItemsOfUserHistory/${timeTracking.itemId}`
    )
      .then(response => response.json())
      .then(data => setUsers(data))
  }, [fetchWithUser, timeTracking.itemId])

  return (
    <ItemTimeModal
      open={open === TimerModalStatus.Create || open === TimerModalStatus.Edit}
      setOpen={setOpen}
      isBillable={isBillable(timeTracking.itemId)}
      dialogTitle={`${isNew() ? 'Create' : 'Edit'} Session ${itemLpid}`}
      disableBackdropClick={loading}
    >
      {roles?.some(x => x === UserRole[UserRole.Admin]) && (
        <Grid item>
          <Autocomplete
            value={users.find(x => timeTracking.user?.id === x.id) || null}
            onChange={(event: any, newValue: any) => {
              onChangeUser(newValue)
            }}
            options={users}
            getOptionLabel={t => t.name}
            isOptionEqualToValue={option => option.id === timeTracking.user?.id}
            includeInputInList
            renderInput={params => (
              <CuiTextField
                {...params}
                label="User"
                required
                helperText={errorUser}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )}
      <Grid item>
        <CuiDatePicker
          autoFocus
          disabled={!isNew()}
          disableFuture
          label="Date"
          inputTextProps={{
            fullWidth: true,
            margin: 'normal',
            required: true,
            error: errorEntryDate !== '',
            helperText: errorEntryDate,
            variant: 'outlined'
          }}
          value={entryDate}
          onChange={onChangeDate}
        />
      </Grid>
      <Grid item>
        <TimePicker
          label="Start Time"
          value={timeTracking.startTime || null}
          onChange={onChangeStartTime}
          renderInput={params => (
            <CuiTextField
              {...params}
              fullWidth
              variant="outlined"
              helperText={errorStartTime}
              error={errorStartTime !== ''}
            />
          )}
        />
      </Grid>
      <Grid item>
        <TimePicker
          label="End Time"
          value={timeTracking.endTime || null}
          onChange={onChangeEndTime}
          renderInput={params => (
            <CuiTextField
              {...params}
              fullWidth
              variant="outlined"
              helperText={errorEndTime}
              error={errorEndTime !== ''}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          value={
            getTypeOptions(isBillable(timeTracking.itemId)).find(
              x => x === timeTracking.timeType
            ) || null
          }
          onChange={(event: any, newValue: any) => {
            newValue &&
              setTimeTracking({
                ...timeTracking,
                timeType: newValue
              })
            setErrorType('')
          }}
          options={getTypeOptions(isBillable(timeTracking.itemId))}
          getOptionLabel={t => getTypeText(t)}
          isOptionEqualToValue={option => option === timeTracking.timeType}
          includeInputInList
          renderInput={params => (
            <CuiTextField
              {...params}
              label="Type"
              required
              helperText={errorType}
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item>
        <CuiTextField
          fullWidth
          defaultValue={timeTracking.comment}
          label="Note"
          variant="outlined"
          multiline={true}
          rows={'3'}
          onChange={e =>
            setTimeTracking({
              ...timeTracking,
              comment: e.target.value
            })
          }
        />
      </Grid>
      <Grid item>
        <CuiProgressButton
          variant="contained"
          fullWidth
          onClick={onSaveClick}
          loading={loading}
          disabled={disableUpdate || loading}
        >
          {isNew() ? 'Create' : 'Update'}
        </CuiProgressButton>
      </Grid>
    </ItemTimeModal>
  )
}
