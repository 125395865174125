export function downloadFile(response: Response, constFileName?: string) {
  return response
    .blob()
    .then(data => {
      var filename = ''
      if (!constFileName) {
        var disposition = {} as any
        disposition = response.headers.get('Content-Disposition')
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        var matches = filenameRegex.exec(disposition)
        if (matches !== null && matches[1])
          filename = matches[1].replace(/['"]/g, '')
      } else filename = constFileName
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    })
}

function base64ToArrayBuffer(base64: string) {
  var binaryString = window.atob(base64)
  var binaryLen = binaryString.length
  var bytes = new Uint8Array(binaryLen)
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}

export function downloadFileByBase64(base64: string, fileName: string) {
  var sampleArr = base64ToArrayBuffer(base64)
  const url = window.URL.createObjectURL(
    new Blob([sampleArr], {type: 'application/pdf'})
  )
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}
