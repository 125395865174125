import React, {useState} from 'react'
import {
  ListItem,
  ListItemIcon,
  Tooltip,
  ListItemText,
  Box,
  Chip,
  IconButton,
  Collapse,
  List,
  listItemClasses
} from '@mui/material'
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {ReactComponent as NonBillableIcon} from 'src/images/items/NonBillable.svg'
import {ReactComponent as BillableIcon} from 'src/images/items/Billable.svg'
import TimeTracking, {
  isBillable,
  TimeType,
  isDisableEditing
} from 'src/entities/TimeTracking'
import {
  getFormattedDate,
  getTimeFormattedDate,
  getTimerFormat
} from 'src/utils/formatDate'
import {User} from '@microsoft/microsoft-graph-types'
import {TimerModalStatus} from 'src/components/item/timeTracking/ItemTimerTracking'
import ItemTimerDeleteModal from 'src/components/item/timeTracking/ItemTimerDeleteModal'
import ItemTimerFormModal from 'src/components/item/timeTracking/ItemTimerFormModal'
import {blue, grey} from '@mui/material/colors'
import {useAuth} from 'src/context/Auth'

const getTimeType = (timeType: TimeType) => {
  return TimeType[timeType]
}

interface ItemTimerViewLineProps {
  timeTracking: TimeTracking
  user: User | null
  hideTimer: boolean
  onUpdate: (timeTracking: TimeTracking) => void
  onDelete: (timeTracking: TimeTracking) => void
  activeTimerId?: number
  itemLpid: string
}

export default function ItemTimerViewLine({
  timeTracking,
  user,
  hideTimer,
  onUpdate,
  onDelete,
  activeTimerId,
  itemLpid
}: ItemTimerViewLineProps) {
  const [selected, setSelected] = useState(false)
  const [open, setOpen] = useState(TimerModalStatus.None)
  const {roles} = useAuth()

  return (
    <>
      <ListItem
        button
        key={timeTracking.id}
        selected={selected}
        sx={[
          {
            [`&.${listItemClasses.selected}, &.${listItemClasses.selected}:hover`]:
              {
                backgroundColor: grey[50]
              }
          }
        ]}
      >
        <ListItemIcon>
          <Tooltip title={timeTracking.user.name}>
            <AccountCircleSharpIcon
              htmlColor={
                timeTracking.user.username.toLocaleLowerCase() ===
                user?.userPrincipalName?.toLocaleLowerCase()
                  ? blue[900]
                  : blue[500]
              }
            />
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          secondary={getFormattedDate(timeTracking.startTime, true)}
        />
        <ListItemText
          secondary={`${getTimeFormattedDate(
            timeTracking.startTime,
            true
          )} - ${getTimeFormattedDate(timeTracking.endTime, true)}`}
        />
        <ListItemText
          secondary={
            timeTracking.endTime &&
            getTimerFormat(
              timeTracking.duration && timeTracking.duration > 0
                ? timeTracking.duration
                : 0
            )
          }
        />
        <Box width={250}>
          <Chip
            icon={
              isBillable(timeTracking.itemId) ? (
                <BillableIcon />
              ) : (
                <NonBillableIcon />
              )
            }
            label={getTimeType(timeTracking.timeType)}
            sx={{bgcolor: blue[50], color: blue[800], borderRadius: 1}}
          />
        </Box>
        <Tooltip title={'Edit'}>
          <IconButton
            disabled={isDisableEditing(
              activeTimerId,
              timeTracking,
              user?.userPrincipalName || '',
              roles,
              hideTimer
            )}
            onClick={() => {
              setOpen(TimerModalStatus.Edit)
            }}
            size="large"
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Delete'}>
          <IconButton
            disabled={isDisableEditing(
              activeTimerId,
              timeTracking,
              user?.userPrincipalName || '',
              roles,
              hideTimer
            )}
            onClick={() => {
              setOpen(TimerModalStatus.Delete)
            }}
            size="large"
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
        <Box width={30}>
          {timeTracking.comment &&
            (selected ? (
              <ExpandLess onClick={() => setSelected(false)} />
            ) : (
              <ExpandMore onClick={() => setSelected(true)} />
            ))}
        </Box>
      </ListItem>
      <Collapse in={selected} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {timeTracking.comment && selected && (
            <ListItem>
              <ListItemText
                secondary={
                  <Box component="div" whiteSpace="break-spaces">
                    {timeTracking.comment}
                  </Box>
                }
              ></ListItemText>
            </ListItem>
          )}
        </List>
      </Collapse>
      {open === TimerModalStatus.Edit && (
        <ItemTimerFormModal
          key={timeTracking.id}
          open={open}
          setOpen={setOpen}
          timeTrackingData={timeTracking}
          onSave={(data: TimeTracking) => {
            setOpen(TimerModalStatus.None)
            onUpdate(data)
          }}
          itemLpid={itemLpid}
        />
      )}
      {open === TimerModalStatus.Delete && (
        <ItemTimerDeleteModal
          open={open === TimerModalStatus.Delete}
          setOpen={setOpen}
          timeTracking={timeTracking}
          onDelete={() => {
            onDelete(timeTracking)
          }}
        />
      )}
    </>
  )
}
