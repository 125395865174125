import {BaseResource} from 'src/entities/BaseResource'
import File from 'src/entities/File'
import loading from './Loading'

export default interface InvoiceAttachment extends BaseResource {
  invoiceId: number
  file: File
}

export interface MultiInvoiceAttachments extends BaseResource {
  invoiceIds: number[]
  file: File
}

export enum AttachmentType {
  Invoice = 1,
  Payment
}

export interface LoadingFile extends loading {
  attachment?: InvoiceAttachment
}
