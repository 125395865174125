import NumberFormat, {NumberFormatProps} from 'react-number-format'
import React from 'react'
import CuiTextField, {
  CuiTextFieldProps
} from 'src/components/custom/CuiTextField'

export type CuiNumberFormatProps = NumberFormatProps<CuiTextFieldProps> & {
  onChange?: (event: {
    target: {name: string; value: number | undefined}
  }) => void
  addPrefix?: boolean
  addSuffix?: boolean
}

export default function CuiNumberFormat(props: CuiNumberFormatProps) {
  const {onChange, addPrefix, addSuffix, ...other} = props
  return (
    <NumberFormat<CuiTextFieldProps>
      customInput={CuiTextField}
      onPaste={e => {
        e.preventDefault?.()
        const data = e.clipboardData.getData('text').replace(/[,$%]/g, '')
        onChange?.({
          target: {
            name: props.name || '',
            value: Number(data) || undefined
          }
        })
      }}
      onValueChange={values => {
        other.value !== values.floatValue &&
          onChange &&
          onChange({
            target: {
              name: props.name || '',
              value: values.floatValue
            }
          })
      }}
      thousandSeparator
      decimalSeparator="."
      decimalScale={props.decimalScale || 2}
      fixedDecimalScale={
        props.fixedDecimalScale !== undefined ? props.fixedDecimalScale : true
      }
      isNumericString={true}
      allowNegative={false}
      prefix={addPrefix ? '$ ' : undefined}
      suffix={addSuffix ? ' %' : undefined}
      fullWidth
      {...other}
    />
  )
}
