import {BaseResource} from 'src/entities/BaseResource'
import {ItemType} from 'src/entities/Item'

export interface ProjectItemType extends BaseResource {
  type: ItemType | null
  typeId: number
  estimateItemsCount: number
  rate: number
  isHourly: boolean
  isDefault: boolean
  description: string
  order: number
}

export interface EditableProjectItemType extends ProjectItemType {
  isNew: boolean
  typeValidation: string | null
  descriptionValidation: string | null
  estimateItemsCountValidation?: string
  rateValidation?: string
}

export function getvalidateProjectItemType(p: EditableProjectItemType) {
  return {
    ...p,
    typeValidation: getProjectItemTypeKeyValidation('type', p),
    descriptionValidation: getProjectItemTypeKeyValidation('description', p),
    rateValidation: getProjectItemTypeKeyValidation('rate', p),
    estimateItemsCountValidation: getProjectItemTypeKeyValidation(
      'estimateItemsCount',
      p
    )
  } as EditableProjectItemType
}

export const getProjectItemTypeKeyValidation = (
  key: string,
  data: ProjectItemType
) => {
  const requiredText = 'Required'
  switch (key) {
    case 'type':
      return !data.type ? requiredText : undefined
    case 'description':
      return !data.description ? requiredText : undefined
    case 'estimateItemsCount':
      return data.isHourly === false &&
        data.type?.group.name !== 'RD' &&
        !data.estimateItemsCount
        ? requiredText
        : undefined
    default:
      return undefined
  }
}

export function getNextId<P extends EditableProjectItemType>(details: P[]) {
  return (
    (details?.length > 0
      ? details.reduce((max, b) => Math.max(max, b.id), details[0].id)
      : 0) + 1
  )
}

export function generateNextOrderNum<P extends EditableProjectItemType>(
  details: P[]
) {
  return (
    (details?.length > 0
      ? details.reduce((max, b) => Math.max(max, b.order), details[0].order)
      : 0) + 1
  )
}

export function validateProjectItemType(p: ProjectItemType) {
  return !!(
    p.type &&
    p.description &&
    (p.isHourly || p.type.group.name === 'RD' || p.estimateItemsCount) &&
    p.rate !== null &&
    p.rate !== undefined
  )
}
