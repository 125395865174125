import React, {useState} from 'react'
import {Badge, Tooltip, IconButton, Typography, Stack} from '@mui/material'
import {
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridFilterOperator,
  GridFilterItem
} from '@mui/x-data-grid-pro'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CuiTypography from 'src/components/custom/CuiTypography'
import CuiNumberFormat from 'src/components/custom/CuiNumberFormat'
import CuiDatePicker from 'src/components/custom/CuiDatePicker'
import {ReactComponent as PlusIcon} from 'src/images/Plus.svg'
import {SaveType, useAutosaveContext} from 'src/context/Autosave'
import useAutosave from 'src/hooks/useAutosave'
import Invoice from 'src/entities/Invoice'
import config from 'src/config'
import {getName} from 'src/utils/AzureStorage'
import {BillingInfo} from 'src/entities/BillingInfo'
import {formatNumber} from 'src/utils/numberHelper'
import {ProjectStatus, BaseProjectResource} from 'src/entities/Project'
import {useAuth} from 'src/context/Auth'
import CuiConfirmDialog from 'src/components/custom/CuiConfirmDialog'
import {SavingInvoice} from './InvoiceTable'
import {UserRole} from 'src/entities/User'

interface SavingGridRowProps {
  data: SavingInvoice
}

export const isDeletedFilter: GridFilterOperator = {
  value: 'is',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    return ({value}) => {
      return (
        filterItem.value || value.toString() === filterItem.value.toString()
      )
    }
  }
}

export const statusFilter: GridFilterOperator = {
  value: 'isAnyOf',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null
    }
    return ({value}) =>
      (filterItem.value as ProjectStatus[]).some(x => x === value)
  }
}

export const projectFilter: GridFilterOperator = {
  value: 'isAnyOf',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null
    }
    return ({value}) =>
      (filterItem.value as BaseProjectResource[]).some(x =>
        value.includes(x.code)
      )
  }
}

export const quickSearch: GridFilterOperator = {
  value: 'quickSearch',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null
    }
    return ({row}) => {
      const r = row as Invoice
      const search = (filterItem.value as string).toLowerCase()
      return r.invoiceNumber?.toLowerCase().includes(search)
    }
  }
}

export const StatusViewCell = (params: GridRenderCellParams) => {
  return ProjectStatus[Number((params.row as Invoice).project.status)] || ''
}

export const AttachmentViewCell = (
  params: GridRenderCellParams,
  allowAdd: boolean
) => {
  const attachment =
    (params.row as Invoice)?.attachments.length > 0 &&
    getName(
      (params.row as Invoice)?.attachments[0]?.file?.path || '',
      false
    ).substring(0, 20)

  return (
    <>
      <CuiTypography variant="body1">{attachment}</CuiTypography>
      {(params.row as Invoice).attachments.length > 1 && (
        <Tooltip
          title={(params.row as Invoice).attachments
            .map(x => getName(x?.file?.path || '', false))
            .join(', ')}
        >
          <Badge
            sx={{ml: 3}}
            color="primary"
            badgeContent={`+ ${(params.row as Invoice).attachments.length - 1}`}
          ></Badge>
        </Tooltip>
      )}
      {allowAdd && (
        <Tooltip title="Add Attachment" sx={{ml: 2}}>
          <IconButton>
            <PlusIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export const UpdateProjectStatus = (
  rows: Invoice[],
  params: GridRenderCellParams,
  projectStatus: ProjectStatus
) => {
  let currentInvoice = rows.find((invoice: Invoice) => invoice.id === params.id)

  const invoiceFiltered = rows.filter(
    (invoice: Invoice) => invoice.projectId === currentInvoice?.projectId
  )

  const projectIds: number[] = invoiceFiltered.map(
    (invoice: Invoice) => invoice.id
  )
  params.api.updateRows(
    projectIds.map(x => {
      return {
        id: x,
        projectStatus: projectStatus
      }
    })
  )
}

export const DeleteCell = (params: GridRenderCellParams) => {
  const {fetchWithUser, roles} = useAuth()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const onDelete = () => {
    setLoading(true)
    fetchWithUser(`${config.apiUrl}/invoice/${params.id}`, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    })
      .then(response => response.json())
      .then((data: Invoice) => {
        params.api.updateRows([
          {
            id: params.id,
            isDeleted: data.isDeleted,
            projectStatus: data.projectStatus
          }
        ])
        params.api.setSelectionModel([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <IconButton
        disabled={
          (params.row as Invoice).isDeleted ||
          roles?.some(x => x === UserRole[UserRole.SalesRep]) ||
          false
        }
        color={'primary'}
        size="small"
        onClick={() => setOpenDeleteModal(true)}
        disableFocusRipple
        disableRipple
      >
        <DeleteOutlineIcon />
      </IconButton>
      {openDeleteModal && (
        <CuiConfirmDialog
          title="Delete this Invoice?"
          okButtonText="DELETE"
          open={openDeleteModal}
          disableBackdropClick={loading}
          leaveOpen={true}
          close={() => setOpenDeleteModal(false)}
          onConfirm={() => onDelete()}
        />
      )}
    </>
  )
}

export const ViewInvoiceCell = () => {
  const {inProgress} = useAutosaveContext()

  return (
    <Tooltip
      title={
        inProgress?.find(process => process.type === SaveType.Invoice)
          ? 'Saving in progress...'
          : ''
      }
    >
      <IconButton
        color={'primary'}
        size="small"
        disableFocusRipple
        disableRipple
      >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}

export const BillingInfoCell = (params: GridRenderCellParams) => {
  return (
    <Stack spacing={2} direction="row">
      <Typography width={100}>
        {formatNumber((params.value as BillingInfo).discount)}
      </Typography>
      <Typography width={100}>
        {(params.value as BillingInfo).discountPercent + '%'}
      </Typography>
    </Stack>
  )
}

export const PaidAtEditCell = (params: GridRenderEditCellParams) => {
  return (
    <CuiDatePicker
      inputTextProps={{
        autoFocus: true,
        sx: {marginX: 1}
      }}
      value={params.value as Date}
      onChange={(date: Date | null) => {
        params.api.setEditCellValue({
          id: params.id,
          field: params.field,
          value: date
        })
      }}
    />
  )
}

export const BillingInfoEditCell = (params: GridRenderEditCellParams) => {
  return (
    <>
      <CuiNumberFormat
        fullWidth
        autoFocus
        variant="outlined"
        sx={{width: 150}}
        value={(params.value as BillingInfo).discount || ''}
        onChange={(e: any) => {
          const {id, api, field} = params
          const newValue = {
            ...params.row.billingInfo,
            discount: (e.target.value as number) || 0
          } as BillingInfo

          api.setEditCellValue({id, field, value: newValue})
        }}
        addPrefix
      />
      <CuiNumberFormat
        fullWidth
        variant="outlined"
        sx={{width: 150}}
        value={(params.value as BillingInfo).discountPercent || ''}
        onChange={(e: any) => {
          const {id, api, field} = params
          const newValue = {
            ...params.row.billingInfo,
            discountPercent: (e.target.value as number) || 0
          } as BillingInfo

          api.setEditCellValue({id, field, value: newValue})
        }}
        addSuffix
      />
    </>
  )
}

export const NumberEditCell = (params: GridRenderEditCellParams) => {
  return (
    <CuiNumberFormat
      fullWidth
      autoFocus
      variant="outlined"
      value={params.value as number}
      onChange={(e: any) => {
        const {id, api, field} = params
        api.setEditCellValue({id, field, value: e.target.value as number})
      }}
      addPrefix
    />
  )
}

export const DiscountPercentEditCell = (params: GridRenderEditCellParams) => {
  return (
    <CuiNumberFormat
      autoFocus
      fullWidth
      variant="outlined"
      fixedDecimalScale={false}
      sx={{width: 150}}
      value={params.value as number}
      onChange={(e: any) => {
        const {id, api, field} = params
        api.setEditCellValue({id, field, value: e.target.value as number})
      }}
      addSuffix
    />
  )
}

export const SavingGridRow = ({data}: SavingGridRowProps) => {
  useAutosave<Invoice>({
    dataToSave: data,
    id: data.id,
    ignoreChanges: data.ignoreChanges,
    delayToSaveInMs: 2000,
    url: config.apiUrl + `/invoice`,
    type: SaveType.Invoice
  })

  return <></>
}
