import React from 'react'
import {TableRow, TableCell} from '@mui/material'
import {Skeleton} from '@mui/material'
import {CuiColumn} from 'src/components/custom/table/CuiTable'

interface CuiTableSkeletonProps {
  columns: CuiColumn[]
  rowsPerPage: number
  component?: 'div'
}

export default function CuiTableSkeleton({
  columns,
  rowsPerPage,
  component
}: CuiTableSkeletonProps) {
  return (
    <>
      {[...Array(rowsPerPage > 0 ? rowsPerPage : 10)].map((e, i) => {
        return (
          <TableRow component={component || 'tr'} key={i}>
            {columns.map(col => {
              return (
                <TableCell component={component} key={col.id} align={col.align}>
                  <Skeleton
                    height="30px"
                    style={{marginTop: '6px', marginBottom: '6px'}}
                  />
                </TableCell>
              )
            })}
          </TableRow>
        )
      })}
    </>
  )
}
