import config from 'src/config'
import {format} from 'date-fns'

export const maxDate = new Date('01/01/2200')
export const minDate = new Date('01/01/1970')

export function getFormattedDate(
  date: Date | null,
  isConvertToLocalTimeZone: boolean = false
) {
  try {
    if (date) {
      const d = isConvertToLocalTimeZone
        ? getLocalDateFromUtc(date)
        : new Date(date)

      if (d instanceof Date && !isNaN(d.valueOf())) {
        const options = {
          month: '2-digit',
          year: 'numeric',
          day: '2-digit'
        } as Intl.DateTimeFormatOptions
        const dateFormatter = new Intl.DateTimeFormat('en-US', options)
        return dateFormatter.format(d)
      }
    }
    return ''
  } catch {
    return ''
  }
}

export function getFullFormattedDate(date: Date | null) {
  date = UTC2Date(date)

  if (date && date instanceof Date && !isNaN(date.valueOf())) {
    const options = {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit'
    } as Intl.DateTimeFormatOptions
    const dateFormatter = new Intl.DateTimeFormat('en-US', options)
    return dateFormatter.format(new Date(date))
  }
  return ''
}

export function getFileDateFormat(date: Date) {
  return date.toJSON().slice(0, 10)
}

export function getFormattedDateTime(
  date: string,
  isConvertToLocalTimeZone: boolean = true,
  addMS: boolean = true
) {
  if (date) {
    const baseOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }

    const options =
      !config.isProduction && addMS
        ? {...baseOptions, second: 'numeric'}
        : baseOptions
    date = isConvertToLocalTimeZone && !date.endsWith('Z') ? date + 'Z' : date
    const dateFormatter = new Intl.DateTimeFormat(
      'en-US',
      options as Intl.DateTimeFormatOptions
    )
    return dateFormatter.format(new Date(date))
  }
  return ''
}

export function getFormattedDateTimeFromDate(
  date: Date | null,
  isConvertToLocalTimeZone: boolean = true
) {
  var dateTimeZone = isConvertToLocalTimeZone
    ? date && getLocalDateFromUtc(date)
    : UTC2Date(date)
  if (
    dateTimeZone &&
    dateTimeZone instanceof Date &&
    !isNaN(dateTimeZone.valueOf())
  ) {
    const baseOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }

    const dateFormatter = new Intl.DateTimeFormat(
      'en-US',
      baseOptions as Intl.DateTimeFormatOptions
    )
    return dateFormatter.format(new Date(dateTimeZone))
  }
  return ''
}

export function getTimeFormattedDate(
  date: Date | null,
  hour12: boolean = true,
  isConvertToLocalTimeZone: boolean = true
) {
  var dateTimeZone = isConvertToLocalTimeZone
    ? date && getLocalDateFromUtc(date)
    : UTC2Date(date)
  if (
    dateTimeZone &&
    dateTimeZone instanceof Date &&
    !isNaN(dateTimeZone.valueOf())
  ) {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: hour12
    } as Intl.DateTimeFormatOptions
    const dateFormatter = new Intl.DateTimeFormat('en-US', options)
    return dateFormatter.format(new Date(dateTimeZone))
  }
  return ''
}

export function validateDate(date: string, checkMinMaxDate?: boolean) {
  date = date.replace(/ +(?= )/g, '').trim()
  const checkDate = new Date(date)

  if (isNaN(checkDate.getTime())) return false
  if (
    checkMinMaxDate &&
    (checkDate.getTime() < minDate.getTime() ||
      checkDate.getTime() > maxDate.getTime())
  )
    return false

  return (
    new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      year: '2-digit',
      day: '2-digit'
    }).format(checkDate) === date ||
    new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit'
    }).format(checkDate) === date ||
    new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      year: 'numeric',
      day: 'numeric'
    }).format(checkDate) === date ||
    new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      year: '2-digit',
      day: 'numeric'
    }).format(checkDate) === date ||
    new Intl.DateTimeFormat('en-US', {
      month: 'long',
      year: 'numeric',
      day: '2-digit'
    }).format(checkDate) === date ||
    new Intl.DateTimeFormat('en-US', {
      month: 'long',
      year: 'numeric',
      day: 'numeric'
    }).format(checkDate) === date ||
    new Intl.DateTimeFormat('en-US', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric'
    }).format(checkDate) === date ||
    new Intl.DateTimeFormat('en-US').format(checkDate) === date
  )
}
export function getYYYYMMDDFormat(date: string) {
  const checkDate = new Date(date)
  if (isNaN(checkDate.getTime())) return null
  const year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(
    checkDate
  )
  const month = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(
    checkDate
  )
  const day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(checkDate)

  return `${year}.${month}.${day}`
}

export function UTC2Date(utcDate: Date | null) {
  return utcDate && utcDate.toString() !== '0001-01-01T00:00:00'
    ? new Date(utcDate as Date)
    : null
}

export function getLocalDateFromString(date: string) {
  return new Date(date)
}

export function getTimerFormat(sec: number, withSeconds: boolean = true) {
  const getSeconds = `0${sec % 60}`.slice(-2)
  const minutes: any = `${Math.floor(sec / 60)}`
  const getMinutes = `0${minutes % 60}`.slice(-2)
  const hours = Math.floor(sec / 3600)
  const getHours = `0${hours}`.slice(hours.toString().length > 2 ? -3 : -2)

  return withSeconds
    ? `${getHours}:${getMinutes}:${getSeconds}`
    : `${getHours}:${getMinutes}`
}

function getOffsetTime(date: Date) {
  if (date.getTimezoneOffset() > 1)
    // gmt -
    return 0
  return date.getTimezoneOffset() * 60 * 1000 * -1
}

export function getUtcDateFromLocalDate(date: Date) {
  date = new Date(date)
  const utc = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  )
  utc.setTime(utc.getTime() + getOffsetTime(utc))
  return utc
}

export function getUtcDateStringFromLocalDate(value: Date) {
  const data = getUtcDateFromLocalDate(value)
  if (!isNaN(data.getTime())) {
    return getFileDateFormat(data)
  }
  return ''
}

export function getLocalDateFromUtc(date: Date) {
  return date.toString().endsWith('Z') ? new Date(date) : new Date(date + 'z')
}

// convert sting of date to utc date format
//('01/01/2022')-GMT=>Sat Jan 01 2022 00:00:00 GMT-0600
//('01/01/2020')+GMT=>Tue Jan 01 2019 02:00:00 GMT+0200
export function converStringDateToUtcDate(value: string) {
  if (!value) return null
  if (!validateDate(value)) return new Date('')
  const data = new Date(value)
  data.setTime(data.getTime() + getOffsetTime(data))
  return data
}

export function getDateTimeCombinedWithDate(date: Date, datetime: Date) {
  datetime.setFullYear(date.getFullYear())
  datetime.setMonth(date.getMonth())
  datetime.setDate(date.getDate())
  return datetime
}

export function fDate(date: Date, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy'

  return date ? format(new Date(date), fm) : ''
}
