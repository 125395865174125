import React from 'react'
import {InputAdornment, Box} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {useDebouncedCallback} from 'use-debounce'
import CuiTextField from 'src/components/custom/CuiTextField'

interface CuiTableSearchProps {
  value: string
  placeholder?: string
  onChangeValue: (debouncedText: string) => void
}

export default function CuiTableSearch({
  placeholder,
  onChangeValue,
  value
}: CuiTableSearchProps) {
  const debounced = useDebouncedCallback((debouncedText: string) => {
    if (debouncedText !== '') {
      debouncedText = debouncedText.toLowerCase()
    }
    onChangeValue(debouncedText)
  }, 0)

  return (
    <Box mb={2}>
      <CuiTextField
        value={value}
        fullWidth
        onChange={e => debounced(e.target.value)}
        placeholder={placeholder || 'Search'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </Box>
  )
}
