import React, {ReactNode} from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'

interface CuiConfirmDialogProps {
  title: string
  children?: ReactNode
  open: boolean
  loading?: boolean
  leaveOpen?: boolean
  okButtonText?: string
  disableBackdropClick?: boolean
  close: () => void
  onConfirm: () => void
  disableOkButton?: boolean
  stopPropagation?: boolean
}

export default function CuiConfirmDialog({
  title,
  children,
  open,
  loading,
  leaveOpen,
  okButtonText,
  disableBackdropClick,
  disableOkButton,
  stopPropagation,
  close,
  onConfirm
}: CuiConfirmDialogProps) {
  const onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!(reason === 'backdropClick' && disableBackdropClick)) {
      close()
    }
  }

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (stopPropagation) {
      event.stopPropagation()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} onClick={onClick}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{whiteSpace: 'pre-wrap'}}>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{m: 1}}
          color="neutral"
          disableElevation
          onClick={() => close()}
          disabled={loading}
        >
          CANCEL
        </Button>
        {!disableOkButton && (
          <CuiProgressButton
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => {
              !leaveOpen && close()
              onConfirm()
            }}
            loading={loading}
          >
            {okButtonText || 'OK'}
          </CuiProgressButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
