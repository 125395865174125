import React, {useState} from 'react'
import {styled} from '@mui/material/styles'
import MuiTextField, {TextFieldProps} from '@mui/material/TextField'
import {FilledInputProps, IconButton, InputAdornment} from '@mui/material'
import classNames from 'classnames'
import {ReactComponent as PasteIcon} from 'src/images/Paste.svg'
import ClearIcon from '@mui/icons-material/Clear'
import {CircularProgress} from '@mui/material'

const PREFIX = 'CuiTextField'

const classes = {
  input: `${PREFIX}-input`
}

const StyledTextField = styled(MuiTextField)(() => ({
  [`& .${classes.input}`]: {
    fontSize: 14
  }
}))

export type CuiTextProps = {
  fontSize?: 'small'
  addPasteIcon?: boolean
  addClearIcon?: boolean
  addLoadingIcon?: boolean
}

export type CuiTextFieldProps = TextFieldProps & CuiTextProps

const ClearAdornment = ({onClearIconClick}: {onClearIconClick: () => void}) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={onClearIconClick} title={'clear'} size="large">
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  )
}

const PasteAdornment = ({
  onPasteIconClick
}: {
  onPasteIconClick: (value: string) => void
}) => {
  const onPasteClick = () => {
    navigator.clipboard
      .readText()
      .then(text => {
        onPasteIconClick(text)
      })
      .catch()
  }

  return (
    <InputAdornment position="end">
      <IconButton onClick={onPasteClick} title="paste" size="large">
        <PasteIcon />
      </IconButton>
    </InputAdornment>
  )
}

export default function CuiTextField({
  InputProps,
  inputProps,
  onBlur,
  onFocus,
  onMouseLeave,
  onMouseOver,
  addPasteIcon,
  addClearIcon,
  addLoadingIcon,
  ...props
}: CuiTextFieldProps) {
  const {
    endAdornment,
    classes: classesProp,
    ...rsetInputProprs
  } = {
    ...InputProps
  } as Partial<FilledInputProps>

  const [isSelected, setIsSelected] = useState(false)

  const onChangeValue = (value: string) => {
    const changeData = {
      target: {
        name: props.name,
        type: props.type,
        value: value
      }
    }
    props.onChange?.(
      changeData as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    )
  }

  const onPasteClick = (value: string) => {
    if (props.onPaste) {
      const pasteData = {
        clipboardData: {
          getData: (format: string) => (format === 'text' ? value : '')
        }
      }
      props.onPaste(pasteData as React.ClipboardEvent<HTMLDivElement>)
    } else {
      onChangeValue(value)
    }
  }

  return (
    <StyledTextField
      inputProps={{
        spellCheck: true,
        ...inputProps
      }}
      InputProps={{
        endAdornment: (
          <>
            {!props.disabled && isSelected && (
              <PasteAdornment onPasteIconClick={onPasteClick}></PasteAdornment>
            )}
            {!props.disabled && addClearIcon && props.value && (
              <ClearAdornment
                onClearIconClick={() => {
                  onChangeValue('')
                }}
              ></ClearAdornment>
            )}
            {addLoadingIcon && <CircularProgress color="primary" size={20} />}
            {endAdornment}
          </>
        ),
        classes: {
          ...classesProp,
          input: classNames(
            classesProp?.input,
            props.fontSize === 'small' ? classes.input : undefined
          )
        },
        ...rsetInputProprs
      }}
      onMouseOver={e => {
        onMouseOver?.(e)
        addPasteIcon && setIsSelected(true)
      }}
      onMouseLeave={e => {
        onMouseLeave?.(e)
        addPasteIcon && setIsSelected(false)
      }}
      onFocus={e => {
        onFocus?.(e)
        addPasteIcon && setIsSelected(true)
      }}
      onBlur={e => {
        onBlur?.(e)
        addPasteIcon && setIsSelected(false)
      }}
      variant="standard"
      {...props}
    />
  )
}
