export enum PageType {
  Scopes = 1,
  MyTasks = 2,
  ItemList = 3,
  ItemListV2 = 16,
  Workspace = 4,
  ProjectList = 5,
  ProjectDashboard = 6,
  Client = 7,
  Documents = 8,
  ManagementReports = 9,
  PropertyReport = 10,
  DDReport = 10,
  OcrTracker = 12,
  WhatsNew = 13,
  adminIssue = 14,
  Invoices = 15,
  'My Tasks' = MyTasks,
  'Item List' = ItemList,
  'Item List V2' = ItemListV2,
  'Project List' = ProjectList,
  'Project Dashboard' = ProjectDashboard,
  'Management Reports' = ManagementReports,
  'DD Report' = DDReport,
  'Ocr Tracker' = OcrTracker,
  "What's New" = WhatsNew,
  'Issues' = adminIssue
}

export const getPageOption = Object.keys(PageType).reduce((prev, dpType) => {
  const resNumber = Number(dpType)
  if (!isNaN(resNumber) && resNumber) {
    prev.push({
      value: resNumber,
      label: PageType[resNumber]
    })
  }
  return prev
}, [] as {value: PageType; label: string}[])
