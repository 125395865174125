import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {useAuth} from 'src/context/Auth'
import {AppBar} from '@mui/material'
import LoginButton from 'src/components/login/LoginButton'
import {styled} from '@mui/material/styles'

const PREFIX = 'LoginPage'

const classes = {
  appBar: `${PREFIX}-appBar`,
  submit: `${PREFIX}-submit`
}

const StyledAppBar = styled(AppBar)(({theme}) => ({
  [`&.${classes.appBar}`]: {
    backgroundImage: "url('/welcom_background.png')",
    height: '30vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  [`& .${classes.submit}`]: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    padding: theme.spacing(1, 4),
    marginTop: theme.spacing(3)
  }
}))

export default function Login() {
  let location = useLocation()

  const {isAuth, loginMessage} = useAuth()

  const {from} = (location.state as any) || {from: {pathname: '/'}}

  // if we have a user, we shouldn't be at this url
  if (isAuth) return <Navigate replace to={from} />
  return (
    <StyledAppBar position="static" className={classes.appBar} elevation={0}>
      <Box textAlign="center">
        <Typography component="h1" variant="h2">
          Welcome to ReaLease
        </Typography>
        <LoginButton color="white" />
        <Typography variant="body2">{loginMessage}</Typography>
      </Box>
    </StyledAppBar>
  )
}
