import React, {useState, useCallback, useEffect} from 'react'
import {Box, Button, Popover} from '@mui/material'
import CuiDatePicker from 'src/components/custom/CuiDatePicker'
import {getFormattedDate} from 'src/utils/formatDate'
import CuiFilterButton from 'src/components/custom/CuiFilterButton'

export enum FilterDateEnum {
  Today = 1,
  Yesterday = 2,
  ThisMonth = 3,
  PastMonth = 4,
  'This Month' = ThisMonth,
  'Past Month' = PastMonth
}

export const getFilterDate = (dateOption: FilterDateEnum) => {
  let firstDay = new Date(new Date().setHours(0, 0, 0, 0)),
    lastDay: Date | null = null
  const fullYear = firstDay.getFullYear(),
    month = firstDay.getMonth()
  switch (dateOption) {
    case FilterDateEnum.Today:
      break
    case FilterDateEnum.Yesterday:
      firstDay = new Date(firstDay.setDate(firstDay.getDate() - 1))
      lastDay = new Date(firstDay)
      lastDay.setHours(23, 59, 59, 59)
      break
    case FilterDateEnum.ThisMonth:
      firstDay = new Date(fullYear, month, 1)
      lastDay = new Date(fullYear, month + 1, 0)
      break
    case FilterDateEnum.PastMonth:
      firstDay = new Date(fullYear, month - 1, 1)
      lastDay = new Date(fullYear, month, 0)
      break
  }
  return [firstDay, lastDay] as (Date | null)[]
}

interface CuiFilterDateProps {
  selectedValue: null | FilterDateEnum
  setSelectedValue?: (selectedValue: null | FilterDateEnum) => void
  dateFromFilter: Date | null
  setDateFromFilter?: (dateFromFilter: Date | null) => void
  dateToFilter: Date | null
  setDateToFilter?: (dateToFilter: Date | null) => void
  setDatesFilter?: (date: (Date | FilterDateEnum | null)[]) => void
  label?: string
}

export default function CuiFilterDate({
  selectedValue,
  setSelectedValue,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
  setDatesFilter,
  label = 'Date'
}: CuiFilterDateProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)

  const onCloseFilter = () => setAnchorEl(null)

  const onFilter = (dateOption: FilterDateEnum) => {
    setSelectedValue ? setSelectedValue(dateOption) : filter(dateOption)
  }

  const filter = useCallback(
    (dateOption: FilterDateEnum) => {
      const [firstDay, lastDay] = getFilterDate(dateOption)
      if (setDatesFilter) {
        setDatesFilter([firstDay, lastDay, dateOption])
      } else {
        setDateFromFilter?.(firstDay)
        setDateToFilter?.(lastDay)
      }
    },
    [setDateFromFilter, setDateToFilter, setDatesFilter]
  )

  useEffect(() => {
    setSelectedValue && selectedValue && filter(selectedValue)
  }, [selectedValue, filter, setSelectedValue])

  return (
    <>
      <CuiFilterButton
        value={dateFromFilter || dateToFilter}
        clearValue={() => {
          setSelectedValue?.(null)
          setDateFromFilter?.(null)
          setDateToFilter?.(null)
          setDatesFilter?.([null, null, null])
        }}
        isPopuop={Boolean(anchorEl)}
        setAnchorEl={setAnchorEl}
        text={
          label +
          ' ' +
          (selectedValue
            ? FilterDateEnum[selectedValue]
            : dateFromFilter
            ? `From ${getFormattedDate(dateFromFilter)} To ${getFormattedDate(
                dateToFilter
              )}`
            : '')
        }
      />
      {anchorEl && (
        <Popover
          sx={{
            maxHeight: 500,
            padding: 0
          }}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 50, horizontal: 'left'}}
          transformOrigin={{vertical: 'top', horizontal: 'left'}}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onCloseFilter}
        >
          <Box paddingLeft={3} paddingRight={3} paddingBottom={1}>
            <Box sx={{mb: 2, display: 'flex', justifyContent: 'space-around'}}>
              <CuiDatePicker
                inputTextProps={{margin: 'normal', sx: {marginTop: 0}}}
                label="From"
                value={dateFromFilter}
                onChange={(date: Date | null) => {
                  if (date && !isNaN(date.getTime())) {
                    setSelectedValue?.(null)
                    setDatesFilter?.([date, dateToFilter, null])
                    setDateFromFilter?.(date)
                  }
                }}
              />
              <Box p={1}></Box>
              <CuiDatePicker
                inputTextProps={{margin: 'normal', sx: {marginTop: 0}}}
                label="To"
                value={dateToFilter}
                onChange={(date: Date | null) => {
                  if (date && !isNaN(date.getTime())) {
                    setSelectedValue?.(null)
                    setDatesFilter?.([dateFromFilter, date, null])
                    setDateFromFilter?.(date)
                  }
                }}
              />
            </Box>
            <Box>
              {Object.keys(FilterDateEnum).map(key => {
                const enumKey = Number(key)
                return (
                  !isNaN(enumKey) && (
                    <Button
                      key={enumKey}
                      color="primary"
                      fullWidth
                      disableElevation
                      variant={selectedValue === enumKey ? 'contained' : 'text'}
                      onClick={() => {
                        onFilter(enumKey as FilterDateEnum)
                      }}
                      sx={{mb: 1}}
                    >
                      {FilterDateEnum[enumKey]}
                    </Button>
                  )
                )
              })}
            </Box>
          </Box>
        </Popover>
      )}
    </>
  )
}
