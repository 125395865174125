import React, {useState} from 'react'
import CuiConfirmDialog from 'src/components/custom/CuiConfirmDialog'
import config from 'src/config'
import {useAuth} from 'src/context/Auth'
import {TimerModalStatus} from 'src/components/item/timeTracking/ItemTimerTracking'
import TimeTracking from 'src/entities/TimeTracking'

interface ItemTimerDeleteModalProps {
  open: boolean
  setOpen: (open: TimerModalStatus) => void
  timeTracking: TimeTracking
  onDelete: () => void
}

export default function ItemTimerDeleteModal({
  open,
  setOpen,
  timeTracking,
  onDelete
}: ItemTimerDeleteModalProps) {
  const {fetchWithUser} = useAuth()
  const [loading, setLoading] = useState(false)

  const onDeleteClick = () => {
    setLoading(true)
    const options = {
      method: 'Delete',
      body: JSON.stringify({
        ...timeTracking
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    fetchWithUser(config.apiUrl + '/timetracking', options)
      .then(() => {
        onDelete()
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.')
      })
      .finally(() => {
        setLoading(false)
        setOpen(TimerModalStatus.None)
      })
  }

  return (
    <CuiConfirmDialog
      title="Delete this session?"
      okButtonText="DELETE"
      open={open}
      loading={loading}
      leaveOpen={true}
      disableBackdropClick={loading}
      close={() => setOpen(TimerModalStatus.None)}
      onConfirm={() => onDeleteClick()}
    />
  )
}
