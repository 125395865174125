import {useCallback} from 'react'
import config from 'src/config'
import {useAuth} from 'src/context/Auth'
import {SASExpired, SasProps} from 'src/utils/AzureStorage'

async function fetchSAS(fetcher: typeof fetch, containerName: string) {
  const response = await fetcher(`${config.fileApiUrl}/GenerateSAS`, {
    method: 'POST',
    body: JSON.stringify({name: containerName})
  })
  const sas = await response.text()
  if (!sas) return Promise.reject({code: 401, msg: 'Unauthorized'})
  const params = new URLSearchParams(sas)
  const sasExpiration = new Date(params.get('se') || '')
  return {
    sas,
    sasExpiration
  } as SasProps
}

export default function useAzureBlobSAS(containerName: string) {
  const {fetchWithUser} = useAuth()

  const getSAS = useCallback(async () => {
    const sas = JSON.parse(
      localStorage.getItem(`sas-${containerName}`) || '{}'
    ) as SasProps
    if (SASExpired(sas.sasExpiration)) {
      try {
        const sasProps = await fetchSAS(fetchWithUser, containerName)
        localStorage.setItem(`sas-${containerName}`, JSON.stringify(sasProps))
        return sasProps.sas
      } catch (error) {
        console.error(error)
      }
    }
    return sas.sas
  }, [fetchWithUser, containerName])

  return {getSAS}
}
