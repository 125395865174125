import React from 'react'
import {GridRow, GridRowProps} from '@mui/x-data-grid-pro'
import classNames from 'classnames'
import {styled} from '@mui/material'

const PREFIX = 'DisableGridRow'

export const classes = {
  disable: `${PREFIX}-disable`
}

const StyledDisableGridRow = styled(GridRow)(({theme}) => ({
  [`&.${classes.disable}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.action.disabled
  }
}))

export default function CuiCustomRow(
  props: React.HTMLAttributes<HTMLDivElement> & GridRowProps
) {
  return (
    <StyledDisableGridRow
      {...props}
      className={classNames(props.row.isDeleted && classes.disable)}
    ></StyledDisableGridRow>
  )
}
