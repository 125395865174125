import React, {
  useState,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect
} from 'react'
import CuiSnackbarMessage from 'src/components/custom/CuiSnackbarMessage'

export enum SaveType {
  Rent,
  NaturalBreakpoint,
  ArtificalBreakpoint,
  ItemDataPoint,
  Filters,
  Project,
  Item,
  AdminIssue,
  ProjectItemType,
  Invoice
}

interface Process {
  type: SaveType
  isFailed: boolean
  id: string
}

interface AutosaveContextValue {
  addProcess: (type: SaveType, id: string) => void
  endProcess: (id: string) => void
  failedProcess: (id: string) => void
  inProgress: Process[]
  setSaveNow: Dispatch<SetStateAction<boolean>>
  saveNow: boolean
}

const AutosaveContext = createContext({} as AutosaveContextValue)

function AutosaveContextProvider({children}: any) {
  const [saveNow, setSaveNow] = useState<boolean>(false)
  const [inProgress, setInProgress] = useState<Process[]>([])

  const addProcess = useCallback((type: SaveType, id: string) => {
    setInProgress(p => [...p, {type, isFailed: false, id}])
  }, [])

  const endProcess = useCallback((id: string) => {
    setInProgress(p => p.filter(value => value.id !== id))
  }, [])

  const failedProcess = useCallback((id: string) => {
    setInProgress(p => p.map(value => ({...value, isFailed: value.id === id})))
  }, [])

  const isOpenSnackbar = inProgress.some(x => x.isFailed)

  useEffect(() => {
    if (saveNow) {
      setSaveNow(false)
    }
  }, [saveNow])

  const value: AutosaveContextValue = {
    saveNow,
    setSaveNow,
    inProgress,
    endProcess,
    addProcess,
    failedProcess
  }
  return (
    <AutosaveContext.Provider value={value}>
      <CuiSnackbarMessage
        isOpenSnackbar={isOpenSnackbar}
        closeSnackbar={() => {}}
        message={`Failed to save data `}
        severity="error"
      />
      {children}
    </AutosaveContext.Provider>
  )
}

const useAutosaveContext = () => useContext(AutosaveContext)

export {AutosaveContextProvider, useAutosaveContext}
