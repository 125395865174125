import BaseWithNameResource from 'src/entities/BaseResource'

export default interface User extends BaseWithNameResource {
  id: number
  name: string
  username: string
  hasDuplicateItemPage: boolean
  userGroups: UserGroup[]
  isActive: boolean
}

export enum UserGroup {
  Abstractor = 1,
  PM,
  LWAdmin,
  Admin,
  Manager,
  SignedProject,
  SignedProjectIsreal,
  LPRDAdmin,
  Billing,
  LWAdminDD
}

export enum UserRole {
  Admin = 1,
  SalesRep,
  PM,
  SalesRepAdmin
}
