import React from 'react'
import {styled} from '@mui/material/styles'
import {CircularProgress, Typography, Grid, Box} from '@mui/material'
import classNames from 'classnames'

const PREFIX = 'CuiLoading'

const classesStyle = {
  root: `${PREFIX}-root`
}

const StyledGrid = styled(Grid)({
  [`&.${classesStyle.root}`]: {
    marginTop: '13%'
  }
})

interface CuiLoadingClasses {
  container?: string
}

interface CuiLoadingProps {
  message?: string | undefined
  disableMargin?: boolean
  color?: string
  size?: number
  classes?: CuiLoadingClasses
}

export default function CuiLoading({
  message,
  disableMargin,
  color,
  size,
  classes
}: CuiLoadingProps) {
  return (
    <StyledGrid
      container
      className={classNames(
        disableMargin ? undefined : classesStyle.root,
        classes?.container ? classes.container : undefined
      )}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box color={color ? color : 'primary'}>
        <CircularProgress
          color={color ? 'inherit' : 'primary'}
          size={size || 70}
        />
      </Box>

      <Box color={color ? color : 'inherit'}>
        <Typography color="inherit">{message}</Typography>
      </Box>
    </StyledGrid>
  )
}
